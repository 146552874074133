import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Scrollbars from 'react-custom-scrollbars';
import { Box, CircularProgress, IconButton, makeStyles, Theme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useRootStore } from '../../base/hooks/useRootStore';
import { isImageType } from '../../utils/fileUtils';
import { IChatValues, IFile, ISelectedFiles } from '../../modules/chat/types/ChatTypes';

interface IChatSelectedFilesProps {
  files: IChatValues['files'];
  onHandleClearFile: (e: React.MouseEvent<HTMLButtonElement>, fileName: string) => void;
}

const ChatSelectedFiles: React.FC<IChatSelectedFilesProps> = observer(({ files, onHandleClearFile }) => {
  const classes = useStyles();
  const { chatStore, fileStore } = useRootStore();

  const filesScrollBar = useRef<Scrollbars | null>(null);
  const filesIsLoaded = fileStore.progress < 100;

  const [selectedFiles, setSelectedFiles] = useState<ISelectedFiles>({
    files: [],
    images: [],
  });

  // Effects
  useEffect(() => {
    const filesObj: ISelectedFiles = {
      files: [],
      images: [],
    };

    if (files?.length) {
      files.forEach(file => {
        const fileName = file.file.name;

        if (!isImageType(file.file.name.split('.')?.pop() ?? '')) {
          filesObj.files.push({ id: file.id, name: fileName, url: '' });
        } else {
          const url = window.URL.createObjectURL(new Blob([file.file]));
          filesObj.images.push({ id: file.id, name: fileName, url });
        }
      });
    }

    setSelectedFiles({
      ...selectedFiles,
      ...filesObj,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (filesScrollBar.current) {
      chatStore.setFileHeight(filesScrollBar.current.getClientHeight());
    } else {
      chatStore.setFileHeight(0);
    }
  }, [chatStore, selectedFiles]);

  // Renders
  const renderFileItem = (files: IFile[]) => {
    return (
      <div className={classes.fileRow}>
        {files.map(({ id, name, url }) => (
          <div key={id} className={clsx(classes.fileItem, { file: url.length === 0, image: url.length !== 0 })}>
            <IconButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => onHandleClearFile(e, id)}
              className={classes.fileClearIcon}
              disabled={filesIsLoaded}
            >
              <ClearIcon />
            </IconButton>
            <div className={classes.fileImgWrap}>
              <>
                {filesIsLoaded && !fileStore.filesIdsArray.includes(id) && (
                  <div className={classes.progressWrap}>
                    <CircularProgress
                      color="secondary"
                      variant="determinate"
                      value={fileStore.progress}
                      className={classes.progress}
                    />
                  </div>
                )}
                {url.length !== 0 ? <img src={url} alt="" /> : <div className={classes.fileName}>{name}</div>}
              </>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Scrollbars ref={filesScrollBar} autoHide autoHeight autoHeightMax={275}>
      <Box pt={4}>
        {renderFileItem(selectedFiles.images)}
        {renderFileItem(selectedFiles.files)}
      </Box>
    </Scrollbars>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  fileRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  fileItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    marginBottom: 20,
    '&.image': {
      width: 64 + 20,
      height: 64,
    },
    '&.file': {
      width: 140 + 20,
      height: 64,
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  fileImgWrap: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: theme.palette.qsecondary[50],
    width: '100%',
    height: '100%',
  },
  fileClearIcon: {
    position: 'absolute',
    top: -8,
    right: 4,
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: theme.palette.extra.white,
    border: `1px solid ${theme.palette.extra.stroke}`,
    padding: 0,
    '&:hover': {
      background: theme.palette.extra.white,
    },
    '& svg': {
      width: 15,
      height: 15,
    },
  },
  fileName: {
    position: 'absolute',
    top: 4,
    left: 20,
    whiteSpace: 'nowrap',
    maxWidth: '65%',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progressWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.3)',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default ChatSelectedFiles;
