import React, { useState } from 'react';
import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

interface IResponsiveTooltipProps extends TooltipProps {}

const ResponsiveTooltip: React.FC<IResponsiveTooltipProps> = ({ children, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);

      if (isMobile) {
        const timeout = setTimeout(() => {
          setOpen(false);
          clearTimeout(timeout);
        }, 2000);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <Tooltip
          open={open}
          onClose={handleClose}
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...rest}
        >
          <span style={{ display: 'flex' }} onClick={handleToggle}>
            {children}
          </span>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return <Tooltip {...rest}>{children}</Tooltip>;
};

export default ResponsiveTooltip;
