import { makeStyles, Theme } from '@material-ui/core';

import ChatContent from './components/ChatContent';

interface ISupportScreenProps {}

const SupportScreen: React.FC<ISupportScreenProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <ChatContent />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    background: theme.palette.extra.white,
  },
}));

export default SupportScreen;
