import { useRootStore } from '../../../../../base/hooks/useRootStore';
import Loader from '../../../../../components/UI/Loader';
import QModal from '../../../../../components/UI/QModal';
import { Box, Button, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

interface IDeleteSelectedSlotsConfirmModalProps extends DialogProps {}

const DeleteSelectedSlotsConfirmModal: React.FC<IDeleteSelectedSlotsConfirmModalProps> = observer(props => {
  const { slotsStore } = useRootStore();

  const classes = useStyles();

  // Handlers
  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    slotsStore.setModalOpen('deleteSelectedConfirmModal', false);
  };

  const handleDelete = () => {
    slotsStore.deleteSlots(slotsStore.selectedCells);
  };

  // Renders
  return (
    <QModal onClose={handleClose} className={classes.confirmModalWrap} {...props}>
      {slotsStore.loading && <Loader isAbsolute color="secondary" />}
      <Box mb={3} maxWidth="80%">
        <Typography variant="h3">Вы действительно хотите удалить выбранные слоты?</Typography>
      </Box>

      <Box mb={1}>
        <Button onClick={handleDelete} fullWidth variant="contained" color="secondary">
          Да
        </Button>
      </Box>
      <div>
        <Button onClick={() => handleClose(null, null)} fullWidth variant="outlined" color="secondary">
          Отмена
        </Button>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  confirmModalWrap: {
    '& .MuiPaper-root': {
      minHeight: 0,
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
}));

export default DeleteSelectedSlotsConfirmModal;
