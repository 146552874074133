import { SMSCode } from './models/SMSCode';
import { AuthFactory } from './AuthFactory';
import AuthApiRepository from './AuthApiRepository';
import { ISMSValidateValues, SMSType } from './types/AuthTypes';

export default class AuthService {
  authApi: AuthApiRepository;
  authFactory: AuthFactory;

  constructor() {
    this.authApi = new AuthApiRepository();
    this.authFactory = new AuthFactory();
  }

  sendSMS = async (type: SMSType, phone: string): Promise<SMSCode> => {
    const { data } = await this.authApi.sendSMS(type, phone);
    return this.authFactory.create<SMSCode>(SMSCode, data.data);
  };

  validateSMSCode = async ({ token, code }: ISMSValidateValues): Promise<SMSCode> => {
    const { data } = await this.authApi.validateSMSCode({ token, code });
    return this.authFactory.create<SMSCode>(SMSCode, data.data);
  };

  logout = () => {
    return this.authApi.logout();
  };
}
