import { SPLITTING_SIZE } from './splittingArray';

export const getSubArray = (array: any[]) => {
  const subArray = [];
  const arrChunkLength = Math.ceil(array.length / SPLITTING_SIZE);

  for (let i = 0; i < arrChunkLength; i++) {
    subArray[i] = array.slice(i * SPLITTING_SIZE, i * SPLITTING_SIZE + SPLITTING_SIZE);
  }
  return subArray;
};
