import { makeAutoObservable } from 'mobx';

import MainService from './MainService';

export class AdminMainStore {
  loading = false;

  private mainService: MainService;

  selectedDate: Date = new Date();

  constructor() {
    makeAutoObservable(this);
    this.mainService = new MainService();
  }

  get formattedDate() {
    return this.mainService.getFormattedData(this.selectedDate);
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };
}
