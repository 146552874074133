import AbstractModel from '../../../../base/AbstractModel';
import { Offer } from '../../../offer/models/Offer';
import { RecordStatuses } from '../types/ClientsTypes';
import { Company } from './Company';

export class ClientRecord extends AbstractModel {
  id: number | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  employeeId: number | null = null;
  companyOfferId: number | null = null;
  source: string = 'web_app';
  companyOffer: Offer | null = null;
  addOffers: Offer[] | null = null;
  company: Company | null = null;
  companyEmployee: ICompanyEmployee | null = null;
  status: RecordStatuses | null = null;
  isPrePayPayment: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

interface ICompanyEmployee {
  id: number;
  name: string;
}
