import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';

export default class ClientsApiRepository extends AbstractApiRepository {
  getClients = (companyId: number, params: any) => {
    return this.get({ url: `/company/${companyId}/clients/list${params}` });
  };

  getClientRecords = (companyId: number, clientUuid: string) => {
    return this.get({ url: `/company/${companyId}/clients/${clientUuid}/records-list` });
  };
}
