import { declOfNum } from './declOfNum';
import { hoursDeclination, minutesDeclination } from '../data/declinationWords';

export const convertDuration = (duration: number | string) => {
  const hours = Number(duration) / 60;
  const readableHours = Math.floor(hours);
  const minutes = (hours - readableHours) * 60;
  const readableMinutes = Math.round(minutes);

  const declHours = readableHours > 0 ? `${readableHours} ${declOfNum(readableHours, hoursDeclination)}` : '';
  const declMinutes = readableMinutes > 0 ? `${readableMinutes} ${declOfNum(readableMinutes, minutesDeclination)}` : '';

  return `${declHours} ${declMinutes}`.trim();
};
