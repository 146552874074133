import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { authRotes } from '../routes';
import LoginCard from './components/LoginCard';
import QTextField from '../../components/UI/QTextField';
import TextButton from '../../components/UI/TextButton';
import { useCommonStyles } from '../../styles/commonStyles';
import { useRootStore } from '../../base/hooks/useRootStore';
import LoaderButton from '../../components/UI/LoaderButton';
import { RussianPhoneMaskInput } from '../../components/UI/RussianPhoneMaskInput';

interface ILoginScreenProps {}

const LoginScreen: React.FC<ILoginScreenProps> = observer(() => {
  const commonClasses = useCommonStyles();
  const { authStore } = useRootStore();
  const { loginStore } = authStore;
  const history = useHistory();

  const [values, setValues] = useState({
    password: '',
    phone: '',
    showPassword: false,
  });

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleToggleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    loginStore.login(values.phone, values.password);
  };

  const handleToResetScreen = () => {
    history.push(authRotes.ResetPasswordScreen.path);
  };

  // Renders
  return (
    <LoginCard title="Авторизация" subTitle="Введите телефон и пароль">
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <QTextField
            value={values.phone}
            name="phone"
            InputProps={{ inputComponent: RussianPhoneMaskInput }}
            label="Телефон"
            onChange={handleChange}
          />
        </Box>
        <Box mb={5}>
          <QTextField
            type={values.showPassword ? 'text' : 'password'}
            name="password"
            onChange={handleChange}
            value={values.password}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      className={commonClasses.blackLow}
                      onClick={handleToggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            label="Пароль"
          />
        </Box>
        <Box mb={2} width="100%">
          <LoaderButton loading={loginStore.loading} type="submit" fullWidth variant="contained" color="secondary">
            Войти
          </LoaderButton>
        </Box>
        <TextButton color="secondary" disabled={loginStore.loading} onClick={handleToResetScreen}>
          Забыли пароль?
        </TextButton>
      </form>
    </LoginCard>
  );
});

export default LoginScreen;
