import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';

import { useAllMQ } from '../../../hooks/useAllMQ';
import QTextField from '../../../components/UI/QTextField';
import BackButton from '../../../components/UI/BackButton';
import OfferChooseModal from './components/OfferChooseModal';
import { isSearchMatch } from '../../../utils/isSearchMatch';
import { useCommonStyles } from '../../../styles/commonStyles';
import LoaderButton from '../../../components/UI/LoaderButton';
import { useRootStore } from '../../../base/hooks/useRootStore';
import ServicesOption from '../../../components/UI/OffersOption';
import ReportSuccessModal from './components/ReportSuccessModal';
import QAutocomplete from '../../../components/UI/QAutocomplete';
import EmployeeChooseModal from './components/EmployeeChooseModal';
import EmployeeOption from '../../../components/UI/EmployeeOption';
import QRangePicker from '../../../components/UI/QRangeDatePicker';
import { settingIcon, smileIcon } from '../../../components/UI/Icons/SvgIcons';
import { IReportFormValues } from '../../../modules/user/modules/types/ReportTypes';

interface IReportScreenProps {}

const ReportScreen: React.FC<IReportScreenProps> = observer(() => {
  const { reportStore, offerStore, employeeStore } = useRootStore();

  const [employeeAutocomplete, setEmployeeAutocomplete] = useState(false);
  const [offersAutocomplete, setOffersAutocomplete] = useState(false);

  const [searchEmployeeText, setSearchEmployeeText] = useState('');
  const [searchOfferText, setSearchOfferText] = useState('');
  const [values, setValues] = useState<IReportFormValues>({
    employees: [],
    offers: [],
    date: { startDate: null, endDate: null },
  });
  const isValid = !!values.employees?.length && !!values.offers?.length && !!values.date?.startDate;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isXS, isMD } = useAllMQ();

  // Effects
  useEffect(() => {
    if (!employeeStore.employeeList.length) {
      employeeStore.getAll();
    }
  }, [employeeStore]);

  useEffect(() => {
    setValues({
      ...values,
      offers: [],
    });

    if (offerStore.employeeOfferList.length) {
      offerStore.clearEmployeeOfferList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.employees.length]);

  // Handlers
  const handleCloseModal = () => {
    reportStore.setModal(false);
  };

  const handleChangeAutoComplete = (e: React.ChangeEvent<{}>, value: any, name: keyof typeof values) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChooseAll = (name: keyof typeof values, resData: any) => {
    setValues({
      ...values,
      [name]: resData,
    });
  };

  const handleRangeDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setValues({
      ...values,
      date: { startDate: start, endDate: end },
    });
  };

  const handleSearchEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget.value;

    setSearchEmployeeText(value);
  };

  const handleSearchOffer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget.value;

    setSearchOfferText(value);
  };

  const handleToggleMasterAutocomplete = (e: React.FocusEvent<HTMLInputElement>) => {
    setEmployeeAutocomplete(!employeeAutocomplete);

    if (!employeeAutocomplete) {
      setSearchEmployeeText('');
    }
  };

  const handleToggleServicesAutocomplete = () => {
    setOffersAutocomplete(!offersAutocomplete);

    if (!offersAutocomplete) {
      setSearchOfferText('');
    }

    if (!offerStore.mappedEmployeeOfferList.length) {
      const companyEmployeeIds = values.employees.map((item: any) => item.id);

      offerStore.getEmployeeOfferList(companyEmployeeIds);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    reportStore.createReport(values);
  };

  // Renders
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.wrap}>
        {!isMD && <BackButton to="/" text="Вернуться на главную" />}
        <div className={classes.card}>
          {isMD && (
            <Box mb={3} width="100%">
              <BackButton to="/" text="Вернуться на главную" />
            </Box>
          )}
          <Box width="100%" maxWidth={384}>
            <Box mb={{ xxs: 4, md: 6 }}>
              <Typography align="center" variant="h1">
                Выгрузка отчёта
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                Выберите дату
              </Typography>
            </Box>
          </Box>
          <Box mb={{ xxs: 4, md: 6 }}>
            <QRangePicker
              small={isXS}
              startDate={values.date.startDate}
              endDate={values.date.endDate}
              onChange={handleRangeDateChange}
            />
          </Box>

          <Box width="100%" maxWidth={384}>
            <Box mb={3}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                Выберите сотрудника
              </Typography>
            </Box>
            <Box mb={{ xxs: 4, md: 6 }}>
              <QAutocomplete
                label="Сотрудник"
                open={employeeAutocomplete}
                multiple
                limitTags={1}
                disablePortal
                disablePopper
                value={values.employees}
                disabled={employeeStore.loading}
                options={employeeStore.employeeList}
                getOptionLabel={option => option.name}
                className={commonClasses.cursorPointerAutocomplete}
                ListboxProps={{
                  searchText: searchEmployeeText,
                  value: values.employees,
                  open: employeeAutocomplete,
                  onSearch: handleSearchEmployee,
                  onClose: handleToggleMasterAutocomplete,
                  onHandleChooseAllTags: () => handleChooseAll('employees', employeeStore.employeeList),
                }}
                loading={employeeStore.loading}
                onFocus={handleToggleMasterAutocomplete}
                classes={{ option: commonClasses.options, tag: commonClasses.tag }}
                ListboxComponent={EmployeeChooseModal as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                onChange={(e: React.ChangeEvent<{}>, value: any) => handleChangeAutoComplete(e, value, 'employees')}
                renderOption={(option, state) =>
                  isSearchMatch(option.name, searchEmployeeText) && <EmployeeOption option={option} state={state} />
                }
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Введите имя"
                      btnProps={{ startIcon: smileIcon, color: 'secondary' }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {employeeStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            </Box>
            <Box mb={3}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                Выберите вид услуги
              </Typography>
            </Box>
            <Box mb={{ xxs: 4, md: 6 }}>
              <QAutocomplete
                label="Виды услуг"
                open={offersAutocomplete}
                multiple
                limitTags={1}
                disablePortal
                disablePopper
                value={values.offers}
                disabled={!values.employees.length}
                options={offerStore.mappedEmployeeOfferList}
                getOptionLabel={option => `${option.title && option.title}, ${option.price && option.price}` as any}
                className={commonClasses.cursorPointerAutocomplete}
                ListboxProps={{
                  searchText: searchOfferText,
                  value: values.offers,
                  open: offersAutocomplete,
                  onClose: handleToggleServicesAutocomplete,
                  onSearch: handleSearchOffer,
                  onHandleChooseAllTags: () => handleChooseAll('offers', offerStore.mappedEmployeeOfferList),
                }}
                loading={offerStore.loading}
                onFocus={handleToggleServicesAutocomplete}
                classes={{ option: commonClasses.options, tag: commonClasses.tag }}
                ListboxComponent={OfferChooseModal as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                onChange={(e: React.ChangeEvent<{}>, value: any) => handleChangeAutoComplete(e, value, 'offers')}
                renderOption={(option, state) =>
                  isSearchMatch(option.title, searchOfferText) && <ServicesOption option={option} state={state} />
                }
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Введите название"
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      {...params}
                      disabled={!values.employees.length}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {offerStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            </Box>
            <LoaderButton
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid}
              loading={reportStore.loading}
            >
              Сформировать отчёт
            </LoaderButton>
            <ReportSuccessModal
              length={employeeStore.employeeList.length}
              values={values}
              open={reportStore.reportSuccessModal}
              onClose={handleCloseModal}
            />
          </Box>
        </div>
      </div>
    </form>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    background: theme.palette.extra.white,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginTop: -1,
    background: theme.palette.extra.bg,
    padding: theme.spacing(4, 7.5, 10),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 552,
      border: `1px solid ${theme.palette.extra.stroke}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 3, 6),
    },
  },
}));

export default ReportScreen;
