import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppBar, Avatar, Box, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import Loader from '../UI/Loader';
import TextButton from '../UI/TextButton';
import { useAllMQ } from '../../hooks/useAllMQ';
import NotificationsModal from './NotificationsModal';
import { useRootStore } from '../../base/hooks/useRootStore';
import { ReqType } from '../../modules/notfications/types/NotificationTypes';
import QMenu from './QMenu';

const Header: React.FC = observer(() => {
  const history = useHistory();
  const { headerStore, userStore, notificationsStore } = useRootStore();
  const { setNotificationAnchorEl } = notificationsStore;

  const [isShowHamburger, setIsShowHamburger] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles();
  const { isXS, isMD } = useAllMQ();

  useEffect(() => {
    setIsShowHamburger(history.location.pathname === '/');

    history.listen(location => {
      setIsShowHamburger(location.pathname === '/');
    });
  }, [history]);

  // Handlers
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenNotification = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);

    if (notificationsStore.notificationsList.length && userStore.unreadNotificationsCount) {
      notificationsStore.getNotificationsList(ReqType.TOP, false, userStore.unreadNotificationsCount);
    }
  };

  const handleToggleSidebar = () => {
    headerStore.setIsShowSidebar();
  };

  // Renders
  if (userStore.loading) {
    return <Loader isAbsolute color="secondary" />;
  }

  if (userStore.userInfo) {
    const { name, photo } = userStore.userInfo;

    return (
      <AppBar className={classes.appBar} position="static">
        <Box height={{ xxs: 42, lg: 40 }} display="flex" alignItems="center" justifyContent="space-between">
          {isMD && isShowHamburger && (
            <IconButton onClick={handleToggleSidebar} className={classes.hamburger} color="secondary">
              <MenuRoundedIcon />
            </IconButton>
          )}
          <Box ml="auto" display="flex" alignItems="center" justifyContent="flex-end">
            <Box mr={2}>
              <IconButton onClick={handleOpenNotification} className={classes.notificationBtn}>
                {!!userStore.unreadNotificationsCount && (
                  <span className={classes.notificationCount}>{userStore.unreadNotificationsCount}</span>
                )}
                <NotificationsOutlinedIcon />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {!isXS && (
                <Box mr={2}>
                  <Typography color="secondary" variant="body1">
                    {name}
                  </Typography>
                </Box>
              )}
              <TextButton onClick={handleOpenMenu} className={classes.btn} endIcon={<KeyboardArrowDownRoundedIcon />}>
                <Avatar src={photo?.fullUrl} alt="" />
              </TextButton>
            </Box>
          </Box>
        </Box>

        <QMenu menuAnchorEl={menuAnchorEl} onHandleCloseMenu={handleCloseMenu} />
        <NotificationsModal />
      </AppBar>
    );
  }

  return <></>;
});

const useStyles = makeStyles((theme: Theme) => ({
  hamburger: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    padding: 6,
    marginLeft: -6,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  appBar: {
    padding: '19px 30px',
    background: theme.palette.extra.bg,
    border: `1px solid ${theme.palette.extra.stroke}`,
    boxShadow: 'none',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      padding: '13px 16px',
    },
  },
  btn: {
    padding: 0,
    '& .MuiButton-endIcon': {
      width: 30,
      height: 30,
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  notificationBtn: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    width: 30,
    height: 30,
    color: theme.palette.black.medium,
  },
  notificationCount: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.extra.white,
    position: 'absolute',
    top: -4,
    right: -3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: 15,
    height: 15,
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
}));

export default Header;
