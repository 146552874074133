import { ISerializedSchedule } from './types/ScheduleTypes';
import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';

export default class ScheduleApiRepository extends AbstractApiRepository {
  getScheduleList = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/schedule/list` });
  };

  setSchedule = (companyId: number, data: { schedule: ISerializedSchedule[] }) => {
    return this.post({ url: `/company/${companyId}/schedule/set`, data });
  };
}
