import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { isIOS } from 'react-device-detect';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';

import Header from './components/Header/Header';
import Footer from './components/Footer';
import Loader from './components/UI/Loader';
import { useAllMQ } from './hooks/useAllMQ';
import { authRotes, routes } from './screens/routes';
import Routes from './base/routes/components/Routes';
import { useRootStore } from './base/hooks/useRootStore';

const App: React.FC = observer(() => {
  const { isMD } = useAllMQ();
  const {
    authStore,
    userStore,
    pushStore,
    slotsStore,
    recordsStore,
    adminMainStore,
    masterMainStore,
    notificationsStore,
  } = useRootStore();

  const showNotification = useCallback(() => {
    if (pushStore.notification.data) {
      const { title, body } = pushStore.notification.data;

      return new Notification(title, { body, renotify: false, vibrate: [200, 100, 200], requireInteraction: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscribeSWMessages = useCallback(event => {
    if (event.data?.type === 'SW_BG_MSG' && event.data?.payload) {
      notificationsStore.updateNotificationsList();
      slotsStore.updateSlotModal(slotsStore.modalState.slot.currentId);
      slotsStore.updateSlotModal(slotsStore.modalState.time.currentId);
      recordsStore.updateRecordModal(slotsStore.modalState.record.currentId);
      recordsStore.updateAdminTable(adminMainStore.formattedDate, userStore.userInfo?.role ?? null);
      recordsStore.updateMasterTable(masterMainStore.formattedDate, userStore.userInfo?.role ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effects
  useEffect(() => {
    if (!isIOS) {
      if (Notification.permission === 'granted') {
        showNotification()?.close();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushStore.notification]);

  useEffect(() => {
    if (!isIOS) {
      navigator.serviceWorker.addEventListener('message', subscribeSWMessages);
    }

    return () => {
      if (!isIOS) {
        navigator.serviceWorker.removeEventListener('message', subscribeSWMessages);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authStore.checkAuth();
  }, [authStore]);

  useEffect(() => {
    if (!userStore.userGetted && authStore.isAuth) {
      userStore.getUserInfo();
      // for request count optimize
      userStore.setUserGetted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.isAuth]);

  useEffect(() => {
    if (!authStore.isAuth) {
      return;
    }

    if (!pushStore.currentToken) {
      pushStore.register();
    }

    pushStore.subscribe(async () => {
      slotsStore.updateSlotModal(slotsStore.modalState.slot.currentId);
      slotsStore.updateSlotModal(slotsStore.modalState.time.currentId);
      recordsStore.updateRecordModal(slotsStore.modalState.record.currentId);
      recordsStore.updateAdminTable(adminMainStore.formattedDate, userStore.userInfo?.role ?? null);
      recordsStore.updateMasterTable(masterMainStore.formattedDate, userStore.userInfo?.role ?? null);
    });

    return () => {
      pushStore.unsubscribe();
      recordsStore.clearAdminTable();
      recordsStore.clearMasterTable();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminMainStore.formattedDate, masterMainStore.formattedDate, pushStore.currentToken, userStore.userInfo]);

  useEffect(() => {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (userStore.userInfo?.timezone && userStore.userInfo.timezone !== localTimezone) {
      userStore.setTimezone(localTimezone);
    }
  }, [userStore, userStore.userInfo]);

  useEffect(() => {
    return () => {
      notificationsStore.clearStore();
    };
  }, [notificationsStore]);

  // Renders
  const renderIsAuthStack = () => {
    if (userStore.loading) {
      return <Loader isAbsolute color="secondary" />;
    }

    if (userStore.userInfo?.role) {
      return (
        <>
          {<Header />}
          <Routes role={userStore.userInfo.role} routes={routes} />
          {!isMD && <Footer />}
          <ReactNotification />
        </>
      );
    }

    return <> </>;
  };

  const renderIsNotAuthStack = () => {
    return (
      <>
        <Routes redirectProps={{ to: authRotes.LoginScreen.path }} routes={authRotes} />

        <ReactNotification />
      </>
    );
  };

  if (!authStore.completeCheckAuth && !userStore.userGetted) {
    return <Loader minHeight="100vh" color="secondary" />;
  }

  return authStore.isAuth ? renderIsAuthStack() : renderIsNotAuthStack();
});

export default App;
