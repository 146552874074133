import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';

import { useAllMQ } from '../../../hooks/useAllMQ';
import BackButton from '../../../components/UI/BackButton';
import QTextField from '../../../components/UI/QTextField';
import { Offer } from '../../../modules/offer/models/Offer';
import OfferChooseModal from './components/OfferChooseModal';
import { isSearchMatch } from '../../../utils/isSearchMatch';
import { useCommonStyles } from '../../../styles/commonStyles';
import LoaderButton from '../../../components/UI/LoaderButton';
import { useRootStore } from '../../../base/hooks/useRootStore';
import QAutocomplete from '../../../components/UI/QAutocomplete';
import ReportSuccessModal from './components/ReportSuccessModal';
import ServicesOption from '../../../components/UI/OffersOption';
import QRangePicker from '../../../components/UI/QRangeDatePicker';
import { settingIcon } from '../../../components/UI/Icons/SvgIcons';

interface IValues {
  offers: Offer[];
  date: { startDate: Date | null; endDate: Date | null };
}

interface IMasterReportScreenProps {}

const MasterReportScreen: React.FC<IMasterReportScreenProps> = observer(() => {
  const { userStore, reportStore, offerStore } = useRootStore();

  const [searchOfferText, setSearchOfferText] = useState('');
  const [offersAutocomplete, setOffersAutocomplete] = useState(false);

  const [values, setValues] = useState<IValues>({
    offers: [],
    date: { startDate: null, endDate: null },
  });
  const isValid = !!values.offers.length && !!values.date?.startDate;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isXS, isMD } = useAllMQ();

  // Handlers
  const handleCloseModal = () => {
    reportStore.setModal(false);
  };

  const handleSearchOffer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget.value;

    setSearchOfferText(value);
  };

  const handleToggleServicesAutocomplete = () => {
    setOffersAutocomplete(!offersAutocomplete);

    if (!offersAutocomplete) {
      setSearchOfferText('');
    }

    if (!offerStore.mappedEmployeeOfferList.length) {
      if (userStore.userInfo && userStore.userInfo?.companyEmployeeId) {
        offerStore.getEmployeeOfferList([userStore.userInfo.companyEmployeeId]);
      }
    }
  };

  const handleChangeAutoComplete = (e: React.ChangeEvent<{}>, value: any, name: keyof typeof values) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChooseAll = (name: keyof typeof values, resData: any) => {
    setValues({
      ...values,
      [name]: resData,
    });
  };

  const handleRangeDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setValues({
      ...values,
      date: { startDate: start, endDate: end },
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (userStore.userInfo && userStore.userInfo.companyEmployeeId) {
      reportStore.createMasterReport({ ...values, employees: [userStore.userInfo.companyEmployeeId] });
    }
  };

  // Renders
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.wrap}>
        {!isMD && <BackButton to="/" text="Вернуться на главную" />}
        <div className={classes.card}>
          {isMD && (
            <Box mb={3} width="100%">
              <BackButton to="/" text="Вернуться на главную" />
            </Box>
          )}
          <Box width="100%" maxWidth={384}>
            <Box mb={{ xxs: 4, md: 6 }}>
              <Typography align="center" variant="h1">
                Выгрузка отчёта
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                Выберите дату
              </Typography>
            </Box>
          </Box>
          <Box mb={{ xxs: 4, md: 6 }}>
            <QRangePicker
              small={isXS}
              startDate={values.date.startDate}
              endDate={values.date.endDate}
              onChange={handleRangeDateChange}
            />
          </Box>

          <Box width="100%" maxWidth={384}>
            <Box mb={3}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                Выберите вид услуги
              </Typography>
            </Box>
            <Box mb={{ xxs: 4, md: 6 }}>
              <QAutocomplete
                label="Вид услуги"
                open={offersAutocomplete}
                multiple
                limitTags={1}
                disablePortal
                disablePopper
                value={values.offers}
                options={offerStore.mappedEmployeeOfferList}
                getOptionLabel={option => `${option.title && option.title}, ${option.price && option.price}` as any}
                className={commonClasses.cursorPointerAutocomplete}
                ListboxProps={{
                  searchText: searchOfferText,
                  value: values.offers,
                  open: offersAutocomplete,
                  onClose: handleToggleServicesAutocomplete,
                  onSearch: handleSearchOffer,
                  onHandleChooseAllTags: () => handleChooseAll('offers', offerStore.mappedEmployeeOfferList),
                }}
                loading={offerStore.loading}
                onFocus={handleToggleServicesAutocomplete}
                classes={{ option: commonClasses.options, tag: commonClasses.tag }}
                ListboxComponent={OfferChooseModal as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                onChange={(e: React.ChangeEvent<{}>, value: any) => handleChangeAutoComplete(e, value, 'offers')}
                renderOption={(option, state) =>
                  isSearchMatch(option.title, searchOfferText) && <ServicesOption option={option} state={state} />
                }
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Введите название"
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {offerStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            </Box>
            <LoaderButton
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid}
              loading={reportStore.loading}
            >
              Сформировать отчёт
            </LoaderButton>
            <ReportSuccessModal values={values} open={reportStore.reportSuccessModal} onClose={handleCloseModal} />
          </Box>
        </div>
      </div>
    </form>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    background: theme.palette.extra.white,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginTop: -1,
    background: theme.palette.extra.bg,
    padding: theme.spacing(4, 7.5, 10),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 552,
      border: `1px solid ${theme.palette.extra.stroke}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 3, 6),
    },
  },
}));

export default MasterReportScreen;
