import AbstractModel from '../../../../base/AbstractModel';

export class CompanyDetail extends AbstractModel {
  legalName: string | null = null;
  inn: string | null = null;
  kpp: string | null = null;
  ogrn: string | null = null;
  legalAddress: string | null = null;
  actualAddress: string | null = null;
  director: string | null = null;
  bik: string | null = null;
  bankName: string | null = null;
  correspondentAccount: string | null = null;
  settlementAccount: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
