import { OfferCategory } from '../models/OfferCategory';

export interface ICompanyInfo {
  title: string;
  offerCategory: OfferCategory | null;
  address: string;
  description: string;
  contactPerson: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
  phone: string;
  email: string;
  timezone: string | null;
}

export interface ICompanyDetail {
  legalName: string | null;
  inn: string | null;
  kpp: string | null;
  ogrn: string | null;
  legalAddress: string | null;
  actualAddress: string | null;
  director: string | null;
  bik: string | null;
  bankName: string | null;
  correspondentAccount: string | null;
  settlementAccount: string | null;
}

export const initialInfoModalValues: ICompanyInfo = {
  title: '',
  offerCategory: null,
  address: '',
  description: '',
  contactPerson: '',
  contactPersonPhone: '',
  contactPersonEmail: '',
  phone: '',
  email: '',
  timezone: null,
};

export const initialDetailModalValues: ICompanyDetail = {
  legalName: '',
  inn: '',
  kpp: '',
  ogrn: '',
  legalAddress: '',
  actualAddress: '',
  director: '',
  bik: '',
  bankName: '',
  correspondentAccount: '',
  settlementAccount: '',
};
