import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

import { routes } from '../routes';
import LoginCard from './components/LoginCard';
import QTextField from '../../components/UI/QTextField';
import LoaderButton from '../../components/UI/LoaderButton';
import { useRootStore } from '../../base/hooks/useRootStore';
import { RussianPhoneMaskInput } from '../../components/UI/RussianPhoneMaskInput';

interface IChangePasswordScreenProps {}

const ChangePasswordScreen: React.FC<IChangePasswordScreenProps> = () => {
  const history = useHistory();
  const { authStore } = useRootStore();
  const [values, setValues] = useState({
    phone: '',
  });

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    authStore.setPhone(values.phone);
    authStore.sendSMS('change_password', routes.ConfirmCodeScreen.path);
  };

  const handleBack = () => {
    history.push(routes.MainScreen.path);
  };

  // Renders
  return (
    <LoginCard
      link={{ to: routes.MainScreen.path, text: 'Вернуться на главную' }}
      title="Сменить пароль"
      subTitle="Введите телефон, на него мы отправим код для восстановления"
    >
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <QTextField
            value={values.phone}
            name="phone"
            InputProps={{ inputComponent: RussianPhoneMaskInput }}
            label="Телефон"
            onChange={handleChange}
          />
        </Box>
        <Box mb={2} width="100%">
          <LoaderButton loading={authStore.loading} type="submit" fullWidth variant="contained" color="secondary">
            Продолжить
          </LoaderButton>
        </Box>
        <Button fullWidth color="secondary" variant="outlined" disabled={authStore.loading} onClick={handleBack}>
          Назад
        </Button>
      </form>
    </LoginCard>
  );
};

export default ChangePasswordScreen;
