import { RoleKeys } from './types/RouteTypes';

export const roles = {
  administrator: {
    role: 'administrator',
    label: 'Администратор',
  },
  master: {
    role: 'master',
    label: 'Сотрудник',
  },
};

export const EVERYBODY = Object.keys(roles).map(key => roles[key as RoleKeys]);

export default roles;
