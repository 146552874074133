import { differenceInMinutes, getMinutes } from 'date-fns';

import { timeStringToDate } from './timeStringToDate';

export const getButtonDynamicStyles = (startTime: string, endTime: string) => {
  const difference = differenceInMinutes(timeStringToDate(endTime), timeStringToDate(startTime));
  const startMinute = getMinutes(timeStringToDate(startTime));

  const diffInHour = difference / 60;
  const statusHeightPercent = diffInHour * 100;
  const statusTopPos = (startMinute / 60) * 100;
  const slotHeightDiff = Math.ceil(diffInHour) * 1;
  const slotHeightIndent = slotHeightDiff === 0 ? 0 : slotHeightDiff - 1;

  return {
    statusTopPos,
    slotHeightIndent,
    statusHeightPercent,
  };
};
