import React, { Fragment } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Avatar, Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import ChatFiles from './ChatFiles';
import { Message } from '../../modules/chat/models/Message';
import { useCommonStyles } from '../../styles/commonStyles';
import { useRootStore } from '../../base/hooks/useRootStore';
import { useCustomTypography } from '../../styles/customTypography';
import { stringToDate } from '../../utils/stringToDate';

interface IChatMessagesProps {
  items: Message[];
}

const ChatMessages: React.FC<IChatMessagesProps> = observer(({ items }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();
  const { userStore } = useRootStore();

  const renderDay = (date: string) => {
    return (
      <Box key={date} pt={1} pb={{ lg: 1 }} mt={2}>
        <Box mb={2}>
          <Divider />
        </Box>
        <Typography align="center" className={clsx(typography.text, commonClasses.blackLow)}>
          {date}
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      {items.map((message: Message) => {
        const currentUser = message.member?.info.id === userStore.userInfo?.userId;
        const otherUser = message.member?.info.id !== userStore.userInfo?.userId;
        const isSystemMessage = message.type === 'systemMessage';

        return (
          <Fragment key={message.id}>
            {message.date && renderDay(message.date)}
            <Box display="flex" alignItems="flex-end">
              <Box mr={1}>
                <Avatar src={message.member?.info?.photo?.fullUrl} alt="" />
              </Box>
              <div
                className={clsx(classes.item, {
                  currentUser,
                  otherUser: !isSystemMessage && otherUser,
                  systemMessage: isSystemMessage,
                })}
              >
                {message.body && (
                  <Box mb={1}>
                    <Typography variant="body2">{message.body}</Typography>
                  </Box>
                )}
                <Box mb={1}>
                  <ChatFiles message={message} />
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center" className={commonClasses.blackLow}>
                  {message.createdAt && (
                    <Typography variant="caption">{format(stringToDate(message.createdAt), 'HH:mm')}</Typography>
                  )}
                  {currentUser && (
                    <div className={clsx(classes.doneIcon, { active: message.read.byCompanion })}>
                      <DoneAllIcon />
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          </Fragment>
        );
      })}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderRadius: 8,
    width: 'fit-content',
    minWidth: 136,
    maxWidth: 504,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
    '&.currentUser': {
      backgroundColor: theme.palette.extra.fill,
    },
    '&.otherUser': {
      backgroundColor: theme.palette.qprimary[50],
    },
    '&.systemMessage': {
      backgroundColor: theme.palette.extra.fill,
    },
  },
  doneIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    color: theme.palette.black.low,
    width: 20,
    height: 20,
    transition: transitions.create('color'),
    '& > svg': {
      width: '100%',
      height: '100%',
    },
    '&.active': {
      color: '#4fc3f7',
    },
  },
}));

export default ChatMessages;
