import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import CreateReportdDto from './forms/CreateReportDto';

export default class ReportApiRepository extends AbstractApiRepository {
  getReportById = (reportId: number) => {
    return this.post({ url: '/company/user/get-report', data: { reportId } });
  };

  createReport = (data: CreateReportdDto) => {
    return this.post({ url: '/company/user/create-report', data });
  };
}
