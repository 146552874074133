import { makeAutoObservable, runInAction } from 'mobx';

import { Employee } from './models/Employee';
import EmployeeService from './EmployeeService';
import { rootStore } from '../../../base/RootStore';
import { ActionType } from '../../../base/types/BaseTypes';
import { IEmployeeValues } from './types/EmployeeTypes';
import { EmployeeInfo } from './models/EmployeeInfo';
import roles from '../../../base/routes/roles';

export const initialValues: IEmployeeValues = {
  roles: roles.administrator,
  name: '',
  phone: '',
  email: '',
  password: '',
  showPassword: false,
  uploadPhoto: null,
  photo: null,
  specialization: null,
  offers: [],
};

export class EmployeeStore {
  loading = false;
  deleteLoading = false;
  employeeModalLoading = false;

  deleteModal = false;
  employeeModal = false;

  errorMessages: any = {};

  employeeList: Employee[] = [];
  fullEmployeeList: Employee[] = [];
  employeeInfo: EmployeeInfo | null = null;
  currentEmployeeId: null | number = null;
  values: IEmployeeValues = initialValues;
  action: ActionType = null;

  private employeeService: EmployeeService;

  constructor() {
    makeAutoObservable(this);
    this.employeeService = new EmployeeService();
  }

  getAll = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.employeeService
      .getAll(companyId)
      .then(employeeList => {
        runInAction(() => {
          this.employeeList = employeeList;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getFullEmployeeList = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.employeeService
      .getFullEmployeeList(companyId)
      .then(fullEmployeeList => {
        runInAction(() => {
          this.fullEmployeeList = fullEmployeeList;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getEmployeeInfo = (id: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setEmployeeModalLoading(true);

    this.employeeService
      .getEmployeeInfo(companyId, id)
      .then(employeeInfo => {
        runInAction(() => {
          this.employeeInfo = employeeInfo;
        });
      })
      .catch(() => {})
      .finally(() => this.setEmployeeModalLoading(false));
  };

  addEmployee = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setEmployeeModalLoading(true);

    this.employeeService
      .addEmployee(companyId, this.values)
      .then(() => {
        this.getFullEmployeeList();
        this.clearStore();
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setEmployeeModalLoading(false));
  };

  updateEmployee = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setEmployeeModalLoading(true);

    this.employeeService
      .updateEmployee(companyId, this.currentEmployeeId as number, this.values)
      .then(() => {
        this.getFullEmployeeList();
        this.clearStore();
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setEmployeeModalLoading(false));
  };

  deleteEmployee = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setDeleteLoading(true);

    this.employeeService
      .deleteEmployee(companyId, this.currentEmployeeId as number)
      .then(() => {
        this.setDeleteModal(false);
        this.setAction(null);
        this.getFullEmployeeList();
      })
      .catch(() => {
        this.setDeleteModal(false);
        this.setAction(null);
      })
      .finally(() => this.setDeleteLoading(false));
  };

  setEmployeeModal = (value: boolean) => {
    this.employeeModal = value;
  };

  setAction = (action: ActionType) => {
    this.action = action;
  };

  setDeleteModal = (value: boolean) => {
    this.deleteModal = value;
  };

  setCurrentEmployeeId = (id: number | null) => {
    this.currentEmployeeId = id;
  };

  setValues = (values: IEmployeeValues) => {
    this.values = values;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setDeleteLoading = (value: boolean) => {
    this.deleteLoading = value;
  };

  setEmployeeModalLoading = (value: boolean) => {
    this.employeeModalLoading = value;
  };

  setErrors = (errors: Object) => {
    this.errorMessages = errors;
  };

  clearEmployeeList = () => {
    this.employeeList = [];
  };

  clearStore = () => {
    this.employeeInfo = null;
    this.fullEmployeeList = [];

    this.setValues(initialValues);
    this.setAction(null);
    this.setDeleteModal(false);
    this.setEmployeeModal(false);
    this.setCurrentEmployeeId(null);
    this.setErrors({});
  };
}
