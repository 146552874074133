export const downloadFile = (url?: string | null, fileName?: string | null, target?: boolean) => {
  if (url?.length) {
    const link = document.createElement('a');

    if (fileName?.length) {
      link.setAttribute('download', fileName ? fileName : '');
    }

    if (target) {
      link.setAttribute('target', '_blank');
    }

    link.href = url;
    link.click();
  }
  return;
};
