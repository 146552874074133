import CompanyService from './CompanyService';
import { CompanyDetail } from './models/CompanyDetail';
import { CompanyInfo } from './models/CompanyInfo';
import { OfferCategory } from './models/OfferCategory';
import { Timezone } from './models/Timezone';
import { ICompanyDetail, ICompanyInfo, initialDetailModalValues, initialInfoModalValues } from './types/CompanyTypes';
import { makeAutoObservable, runInAction } from 'mobx';

export class CompanyStore {
  infoCardLoading = false;
  detailCardLoading = false;

  infoCard: CompanyInfo | null = null;
  detailCard: CompanyDetail | null = null;

  isShowInfoModal = false;
  infoModalLoading = false;
  offerCategoriesLoading = false;
  timezonesLoading = false;
  infoModalError: any = {};
  infoModalValues: ICompanyInfo = initialInfoModalValues;

  offerCategories: OfferCategory[] = [];
  timezoneList: Timezone[] | null = null;

  isShowDetailModal = false;
  detailModalLoading = false;
  detailModalValues: ICompanyDetail = initialDetailModalValues;
  detailModalError: any = {};

  private companyService: CompanyService;

  constructor() {
    makeAutoObservable(this);
    this.companyService = new CompanyService();
  }

  getInfo = (companyId?: number | null) => {
    if (!companyId) {
      return;
    }

    this.setInfoCardLoading(true);

    this.companyService
      .getInfo(companyId)
      .then(companyInfo => {
        runInAction(() => {
          this.infoCard = companyInfo;
        });
      })
      .catch(() => {})
      .finally(() => this.setInfoCardLoading(false));
  };

  getDetail = (companyId?: number | null) => {
    if (!companyId) {
      return;
    }
    this.setDetailCardLoading(true);

    this.companyService
      .getDetail(companyId)
      .then(companyDetail => {
        runInAction(() => {
          this.detailCard = companyDetail;
        });
      })
      .catch(() => {})
      .finally(() => this.setDetailCardLoading(false));
  };

  getInfoFromCard = () => {
    if (this.infoCard) {
      const infos = this.companyService.getInfoModalFromCard(this.infoCard);
      this.setInfoModalValues(infos);
    }
  };

  getDetailFromCard = () => {
    if (this.detailCard) {
      const details = this.companyService.getDetailModalFromCard(this.detailCard);
      this.setDetailModalValues(details);
    }
  };

  getOfferCategories = () => {
    this.setOfferCategoriesLoading(true);
    this.companyService
      .getOfferCategories()
      .then(categories => {
        runInAction(() => {
          this.offerCategories = categories;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setOfferCategoriesLoading(false);
      });
  };

  getTimezones = () => {
    this.setTimezonesLoading(true);
    this.companyService
      .getTimezones()
      .then(timezones => {
        runInAction(() => {
          this.timezoneList = timezones;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setTimezonesLoading(false);
      });
  };

  updateCompanyInfo = (companyId?: number | null) => {
    if (!companyId) {
      return;
    }
    this.setInfoModalLoading(true);

    this.companyService
      .updateInfo(companyId, this.infoModalValues)
      .then(companyInfo => {
        this.setInfoModalLoading(false);
        this.setInfoModalError({});
        this.setIsShowInfoModal(false);
        this.setInfo(companyInfo);
      })
      .catch(error => {
        runInAction(() => {
          const errors = error?.response?.data?.errors;
          if (errors) {
            this.setInfoModalError(errors);
          } else {
            this.setInfoModalError({});
          }
          if (error?.response?.data?.debug?.file?.toLowerCase().indexOf('geo') !== -1) {
            this.infoModalError.address = ['Введите корректный адрес'];
          }
        });
      })
      .finally(() => this.setInfoModalLoading(false));
  };

  updateCompanyDetail = (companyId?: number | null) => {
    if (!companyId) {
      return;
    }
    this.setDetailModalLoading(true);

    this.companyService
      .updateDetail(companyId, this.detailModalValues)
      .then(companyDetail => {
        this.setDetailModalLoading(false);
        this.setDetailModalError({});
        this.setIsShowDetailModal(false);
        this.setDetail(companyDetail);
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;
        if (errors) {
          this.setDetailModalError(errors);
        } else {
          this.setDetailModalError({});
        }
      })
      .finally(() => this.setInfoModalLoading(false));
  };

  findCategoryById(value: string | null): OfferCategory | null {
    return this.offerCategories?.find(el => el.id === value) || null;
  }

  findTimezoneById(value: string | null): Timezone | null {
    return this.timezoneList?.find(el => el.id === value) || null;
  }

  setInfoCardLoading = (value: boolean) => {
    this.infoCardLoading = value;
  };

  setDetailCardLoading = (value: boolean) => {
    this.detailCardLoading = value;
  };

  setIsShowInfoModal = (value: boolean) => {
    this.isShowInfoModal = value;
  };

  setIsShowDetailModal = (value: boolean) => {
    this.isShowDetailModal = value;
  };

  setInfoModalLoading = (value: boolean) => {
    this.infoModalLoading = value;
  };

  setOfferCategoriesLoading = (value: boolean) => {
    this.offerCategoriesLoading = value;
  };

  setTimezonesLoading = (value: boolean) => {
    this.timezonesLoading = value;
  };

  setInfoModalValues = (values: ICompanyInfo) => {
    this.infoModalValues = values;
  };

  setDetailModalValues = (values: ICompanyDetail) => {
    this.detailModalValues = values;
  };

  setDetailModalLoading = (value: boolean) => {
    this.detailModalLoading = value;
  };

  setInfoModalError = (value: Object) => {
    this.infoModalError = value;
  };

  setDetailModalError = (value: Object) => {
    this.detailModalError = value;
  };

  setInfo = (companyInfo: CompanyInfo) => {
    this.infoCard = companyInfo;
  };

  setDetail = (companyDetail: CompanyDetail) => {
    this.detailCard = companyDetail;
  };
}
