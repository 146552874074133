import TokenLocalRepository from './TokenLocalRepository';

export default class TokenService {
  tokenLocal: TokenLocalRepository;

  constructor() {
    this.tokenLocal = new TokenLocalRepository();
  }

  saveToken = (token: string) => {
    this.tokenLocal.set(token);
  };

  deleteToken = () => this.tokenLocal.removeAll();

  getToken = () => {
    const token = this.tokenLocal.get();
    return token ? token : '';
  };
}
