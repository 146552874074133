import AbstractModel from '../../../../base/AbstractModel';

export class OfferCategory extends AbstractModel {
  id: string | null = null;
  title: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
