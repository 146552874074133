import { useRootStore } from '../../../base/hooks/useRootStore';
import BackButton from '../../../components/UI/BackButton';
import QIcon from '../../../components/UI/Icons/QIcon';
import { searchIcon } from '../../../components/UI/Icons/SvgIcons';
import Loader from '../../../components/UI/Loader';
import QTextField from '../../../components/UI/QTextField';
import { ScreenHelper } from '../../../helpers/ScreenHelper';
import { Client } from '../../../modules/role-admin/clients/models/Client';
import { ClientRecord } from '../../../modules/role-admin/clients/models/ClientRecord';
import { useCommonStyles } from '../../../styles/commonStyles';
import { routes } from '../../routes';
import ClientItem from './components/ClientItem';
import RecordDetailModal from './components/RecordDetailModal';
import { Box, Container, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

interface IClientsScreenProps {}

const ClientsScreen: React.FC<IClientsScreenProps> = observer(() => {
  const { clientsStore, userStore, chatStore } = useRootStore();

  const [expanded, setExpanded] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<ClientRecord | null>(null);

  const searchClients = useCallback(
    debounce(() => {
      clientsStore.setCurrentOffset(0);
      clientsStore.getClients(userStore.userInfo?.companyId);
    }, 400),
    [],
  );

  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const theme = useTheme();

  // Effects
  useEffect(() => {
    clientsStore.setCurrentOffset(0);
    clientsStore.changeSearchValue('');
    clientsStore.getClients(userStore.userInfo?.companyId);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleUpdate);

    return () => {
      document.removeEventListener('scroll', handleUpdate);
    };
  }, []);

  //Handlers
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    clientsStore.changeSearchValue(value);
    searchClients();
  };

  const handleGetClientRecords = (client: Client, expanded: boolean) => {
    if (expanded) {
      setExpanded(client.uuid);
    } else {
      setExpanded(null);
    }

    clientsStore.setCurrentClientInfo({ name: client.name, phone: client.phone });
    clientsStore.getClientRecords(userStore.userInfo?.companyId, client.uuid);
  };

  const handleOpenModal = (record: ClientRecord) => {
    setCurrentRecord(record);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleGoToCurrentChat = (record: ClientRecord | null) => {
    if (record?.id) {
      chatStore.getChatWithClientByRecordId(record.id);
    }
  };

  const handleUpdate = () => {
    const isEndOfPage = ScreenHelper.isEndOfPage();

    if (isEndOfPage && clientsStore.haveMoreClients && !clientsStore.loading) {
      clientsStore.getClients(userStore.userInfo?.companyId);
    }
  };

  //Renders
  const renderClients = () => {
    return clientsStore.clients.map((client, index) => (
      <ClientItem
        key={index}
        client={client}
        clientRecords={clientsStore.clientRecords}
        onExpand={handleGetClientRecords}
        recordsLoading={clientsStore.recordsLoading}
        expanded={expanded === client.uuid}
        onOpenModal={handleOpenModal}
      />
    ));
  };

  const renderSearchTextField = () => {
    return (
      <Box width="100%" maxWidth={280}>
        <QTextField
          placeholder="Фамилия, имя или телефон"
          fullWidth
          value={clientsStore.searchValue}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <Box display="flex" alignItems="center" mr={1}>
                <QIcon src={searchIcon} color={theme.palette.qsecondary['200']} width={16} height={16} />
              </Box>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Box mb={{ xs: 5 }} pt={{ xs: 3 }} pl={{ xs: 2 }} className={classes.wrap} width="100%">
        <BackButton to={routes.MainScreen.path} text="Вернуться на главную" />
      </Box>
      <Container maxWidth="xl">
        <Box width="100%" pb={{ xs: 10, md: 20 }}>
          <Box
            mb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box mb={{ xs: 2, md: 0 }}>
              <Typography variant="h1">Клиенты</Typography>
            </Box>
            {renderSearchTextField()}
          </Box>

          {!!clientsStore.clients.length && (
            <div className={classes.clientWrapper}>
              <Box p={2} ml={8} display="grid" gridTemplateColumns="1fr 1fr">
                <Typography className={commonClasses.blackLow} variant="body1">
                  Фамилия Имя
                </Typography>
                <Box textAlign="right">
                  <Typography className={commonClasses.blackLow} variant="body1">
                    Номер телефона
                  </Typography>
                </Box>
              </Box>

              {renderClients()}
            </div>
          )}

          {!clientsStore.loading && !clientsStore.clients.length && (
            <Box width="100%" display="flex" justifyContent="center" mt={5}>
              <Typography variant="h2" className={classes.emptyText}>
                Нет записей
              </Typography>
            </Box>
          )}

          {clientsStore.loading && (
            <Box mt={3}>
              <Loader size={30} />
            </Box>
          )}

          <RecordDetailModal
            open={modalOpen}
            onClose={handleCloseModal}
            record={currentRecord}
            onGoToCurrentChat={handleGoToCurrentChat}
            currentClient={clientsStore.currentClient}
          />
        </Box>
      </Container>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
  },
  clientWrapper: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.extra.stroke}`,
  },
  emptyText: {
    color: theme.palette.qsecondary[100],
  },
}));

export default ClientsScreen;
