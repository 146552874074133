import QIcon from '../../../../components/UI/Icons/QIcon';
import {
  checkmarkCircleIcon,
  documentCheckmarkIcon,
  fiMessageSquare,
  settingIcon,
  smileIcon,
  timeIcon,
} from '../../../../components/UI/Icons/SvgIcons';
import QModal from '../../../../components/UI/QModal';
import QTextField from '../../../../components/UI/QTextField';
import { ClientRecord } from '../../../../modules/role-admin/clients/models/ClientRecord';
import { ICurrentClientInfo, RecordStatuses } from '../../../../modules/role-admin/clients/types/ClientsTypes';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { formatRu } from '../../../../utils/formatRu';
import moneyFormat from '../../../../utils/moneyFormat';
import {Box, DialogProps, FormLabel, IconButton, makeStyles, Theme, Typography, useTheme} from '@material-ui/core';
import React from 'react';

interface IRecordDetailModalProps extends DialogProps {
  record: ClientRecord | null;
  currentClient: ICurrentClientInfo;
  onGoToCurrentChat: (record: ClientRecord | null) => void;
}

const RecordDetailModal: React.FC<IRecordDetailModalProps> = props => {
  const { record, onClose, onGoToCurrentChat, currentClient, ...rest } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const theme = useTheme();

  const showChatIcon = record?.status === RecordStatuses.ACTIVE || record?.status === RecordStatuses.IN_PROGRESS;

  // Handlers
  const handleClose = (e: any, r: any) => {
    if (onClose) {
      onClose(e, r);
    }
  };

  // Renders
  return (
    <QModal onClose={handleClose} {...rest}>
      <Box mr={5} display="flex" justifyContent="space-between" mb={1.5}>
        <Typography variant="h1" className={commonClasses.fontWeight400}>
          Данные записи
        </Typography>
        {showChatIcon && (
          <IconButton className={classes.chatIcon} onClick={() => onGoToCurrentChat(record)}>
            <QIcon src={fiMessageSquare} width={24} height={24} fill="none" />
          </IconButton>
        )}
      </Box>
      <Box mb={2} display="flex">
        {record?.isPrePayPayment && (
          <Box mr={2} color={theme.palette.extra.green} display="flex" alignItems="center">
            <Box mr={0.5} display="flex" alignItems="center">
              <QIcon src={checkmarkCircleIcon} />
            </Box>
            <Typography variant="subtitle1" className={commonClasses.fontWeight400}>
              Оплачено
            </Typography>
          </Box>
        )}
        {record?.status === RecordStatuses.COMPLETED && (
          <Box color={theme.palette.black.medium} display="flex" alignItems="center">
            <Box mr={0.5} display="flex" alignItems="center">
              <QIcon src={documentCheckmarkIcon} />
            </Box>
            <Typography variant="subtitle1" className={commonClasses.fontWeight400}>
              Запись завершена
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={3}>
        <QTextField
          label="Услуга"
          value={`${record?.companyOffer?.title}, ${moneyFormat(record?.companyOffer?.price)} ₽`}
          btnProps={{ startIcon: settingIcon, color: 'secondary' }}
          InputProps={{ readOnly: true }}
          labelProps={{ className: commonClasses.labelSmall }}
        />
      </Box>
      {!!record?.addOffers?.length && (
          <Box mb={3}>
            <Box mb={0.5}>
              <FormLabel>Дополнительные услуги</FormLabel>
            </Box>

            {record?.addOffers.map((item, index) => (
                <Box key={`${item.id}-${index}`} mb={1}>
                  <QTextField
                      value={`${item.title}, ${moneyFormat(item.price)} ₽`}
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      InputProps={{ readOnly: true }}
                  />
                </Box>
            ))}
          </Box>
      )}
      <Box mb={3}>
        <QTextField
          label="Дата и время"
          value={record?.startDate ? formatRu(record.startDate, 'd MMMM, HH:mm') : ''}
          btnProps={{ startIcon: timeIcon, color: 'secondary' }}
          InputProps={{ readOnly: true }}
          labelProps={{ className: commonClasses.labelSmall }}
        />
      </Box>
      <Box mb={3}>
        <QTextField
          label="Сотрудник"
          value={record?.companyEmployee?.name}
          btnProps={{ startIcon: smileIcon, color: 'secondary' }}
          InputProps={{ readOnly: true }}
          labelProps={{ className: commonClasses.labelSmall }}
        />
      </Box>
      <Box mb={2}>
        <div className={classes.line} />
      </Box>
      <Box mb={3}>
        <QTextField
          labelProps={{ className: commonClasses.labelSmall }}
          InputProps={{ readOnly: true }}
          label="Имя клиента"
          value={currentClient.name || ''}
        />
      </Box>
      <Box>
        <QTextField
          labelProps={{ className: commonClasses.labelSmall }}
          InputProps={{ readOnly: true }}
          label="Номер телефона"
          value={currentClient.phone || ''}
        />
      </Box>
    </QModal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  chatCount: {
    position: 'absolute',
    right: -10,
    bottom: -10,
    fontSize: 11,
    width: 16,
    height: 16,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStartIconWrapper: {
    display: 'flex',
    width: 34,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '50%',
    marginRight: 16,
  },
  line: {
    height: 1,
    width: '100%',
    maxWidth: 328,
    margin: '0 auto',
    backgroundColor: theme.palette.extra.stroke,
  },
  chatIcon: {
    padding: 5,
  },
}));

export default RecordDetailModal;
