import clsx from 'clsx';
import {
  Box,
  FormLabel,
  FormLabelProps,
  IconButton,
  IconButtonProps,
  makeStyles,
  StandardTextFieldProps,
  TextField,
  Theme,
} from '@material-ui/core';

import QIcon from './Icons/QIcon';

interface IIconBtnProps extends IconButtonProps {
  startIcon?: string;
}

interface IQTextFieldProps extends StandardTextFieldProps {
  btnProps?: IIconBtnProps;
  label?: string;
  labelProps?: FormLabelProps;
}

const QTextField: React.FC<IQTextFieldProps> = props => {
  const { btnProps, label, labelProps, InputProps, className, helperText, ...rest } = props;
  const classes = useStyles();

  const renderIconButton = () => {
    if (btnProps?.startIcon?.length) {
      const { startIcon, ...btnRest } = btnProps;

      return (
        <IconButton disabled className={clsx(classes.iconBtn, { active: !InputProps?.readOnly })} {...btnRest}>
          <QIcon src={btnProps?.startIcon} width={28} height={28} />
        </IconButton>
      );
    }
  };

  return (
    <Box textAlign="left">
      {label && <FormLabel {...labelProps}>{label}</FormLabel>}
      <Box position="relative" mb={helperText !== undefined ? 5.5 : 0}>
        {renderIconButton()}
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          className={clsx(className, {
            [classes.textField]: !!btnProps,
            [classes.readOnlyTextField]: InputProps?.readOnly,
          })}
          InputProps={{ ...InputProps }}
          helperText={helperText}
          {...rest}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    position: 'absolute',
    left: 12,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'auto',
    height: 'auto',
    zIndex: 1,
    padding: 0,
    '&:disabled': {
      color: theme.palette.secondary.main,
    },
    '&.active': {
      color: theme.palette.black.low,
    },
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      paddingLeft: 56,
    },
    '& .MuiFormHelperText-root': {
      height: 0,
    },
  },
  readOnlyTextField: {
    '& .MuiOutlinedInput-root': {
      boxShadow: 'none',
      color: theme.palette.black.high,
      background: theme.palette.extra.fill,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
    },
  },
}));

export default QTextField;
