import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

import { stringToDate } from './stringToDate';

export function formatRu(dateOrString: string | Date, formatStr: string): string {
  if (typeof dateOrString === 'string') {
    const date = stringToDate(dateOrString);
    return format(date, formatStr, { locale: ruLocale });
  }

  return format(dateOrString, formatStr, { locale: ruLocale });
}
