import clsx from 'clsx';
import { Box, Icon, Typography } from '@material-ui/core';
import { AutocompleteRenderOptionState } from '@material-ui/lab';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { useCommonStyles } from '../../styles/commonStyles';
import { useCustomTypography } from '../../styles/customTypography';

interface IOffersOptionProps {
  option: any;
  state: AutocompleteRenderOptionState;
}

const OffersOption: React.FC<IOffersOptionProps> = ({ option, state }) => {
  const { selected } = state;
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <div>
        <Box mb={0.5}>
          <Typography className={clsx(commonClasses.blackHigh, typography.text2)}>{option.title}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography className={commonClasses.blackMedium} variant="caption">
              {option.price} ₽
            </Typography>
          </Box>
          <Typography className={commonClasses.blackMedium} variant="caption">
            {option?.duration}
          </Typography>
        </Box>
      </div>
      {selected && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Icon color="secondary">
            <CheckRoundedIcon />
          </Icon>
        </Box>
      )}
    </Box>
  );
};

export default OffersOption;
