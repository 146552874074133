import AbstractDto from '../../../../base/AbstractDto';

export default class DeleteSlotsDto extends AbstractDto {
  list: number[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
