import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import transitions from '@material-ui/core/styles/transitions';
import ruLocale from 'date-fns/locale/ru';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import QTextField from './QTextField';

interface IQDatePickerProps extends ReactDatePickerProps {
  small?: boolean;
}

const QDatePicker: React.FC<IQDatePickerProps> = ({ small, calendarClassName, ...rest }) => {
  const classes = useStyles();

  return (
    <DatePicker
      locale={ruLocale}
      dateFormat="dd.MM.yyyy"
      dateFormatCalendar="LLLL yyyy"
      calendarClassName={clsx({ small: small }, classes.calendar, calendarClassName)}
      {...rest}
      renderDayContents={(day: number) => <div>{day}</div>}
      wrapperClassName={classes.wrapper}
      popperClassName={classes.popper}
      nextMonthButtonLabel={<KeyboardArrowRightRoundedIcon />}
      previousMonthButtonLabel={<KeyboardArrowLeftRoundedIcon />}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  labelInput: {
    padding: '31.32px 24px 11.32px',
  },
  popper: {
    zIndex: 5,
    '& .react-datepicker__triangle': {
      borderBottomColor: `transparent !important`,
      '&:before': {
        borderBottomColor: `transparent !important`,
      },
    },
  },
  calendar: {
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    '& .react-datepicker': {
      '&__navigation': {
        top: 0,
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        color: theme.palette.black.high,
        border: 0,
        transition: transitions.create('color'),
        '&--previous': {
          left: 24,
        },
        '&--next': {
          right: 24,
        },
        '&:hover': {
          color: theme.palette.black.medium,
        },
        '& > svg': {
          width: '100%',
          height: '100%',
        },
      },
      '&__header': {
        padding: '6px 0 25px',
        borderBottom: 0,
        backgroundColor: 'transparent',
      },
      '&__current-month': {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: theme.palette.black.high,
        marginBottom: 24,
        textTransform: 'capitalize',
      },
      '&__day-name': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '20px',
        margin: theme.spacing(0, 1.5),
        color: theme.palette.black.high,
        width: 40,
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      '&__month': {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        // In selecting range: reset selected styles
        '&:not(&--selecting-range)': {
          '& .react-datepicker__day': {
            '&:hover': {
              '&:not(&--selected), &:not(&--disabled)': {
                '& div': {
                  borderColor: theme.palette.qsecondary[100],
                },
              },
            },
          },
        },
      },
      '&__week': {
        display: 'flex',
        margin: '0',
        borderRadius: 0,
        overflow: 'hidden',
        '&:not(:last-child)': {
          marginBottom: 15,
        },
      },
      '&__day': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '20px',
        textAlign: 'center',
        margin: theme.spacing(0, 1.5),
        color: theme.palette.black.high,
        padding: 0,
        borderRadius: '50%',
        backgroundColor: theme.palette.extra.bg,
        outline: 'none',
        zIndex: 1,
        '&:hover': {
          '&:not(.react-datepicker__day--disabled)': {
            '& > div': {
              borderColor: theme.palette.qsecondary[100],
            },
          },
        },
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
        // day inner base
        '&--today': {
          backgroundColor: theme.palette.qsecondary[50],
        },
        '& div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          border: `1px solid  transparent`,
          transition: transitions.create('border-color'),
        },
        '&--disabled': {
          color: theme.palette.black.medium,
        },
        '&--selected': {
          color: theme.palette.extra.white,
          '& div': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        '&--outside-month:not(.&--selected)': {
          color: theme.palette.black.medium,
        },
      },
    },
    '&.small': {
      '& .react-datepicker': {
        '&__header': {
          padding: '0 0 22px',
        },
        '&__day-name': {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px',
          margin: '0 3.83px',
          width: 32,
        },
        '&__day': {
          width: 32,
          height: 32,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px',
          margin: '0 3.83px',
        },
        '&__week': {
          '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
  },
}));

export default QDatePicker;
