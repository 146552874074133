import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles, Theme } from '@material-ui/core';
import { ReactDatePickerProps } from 'react-datepicker';
import transitions from '@material-ui/core/styles/transitions';

import QDatePicker from './QDatePicker';
import QTextField from './QTextField';

interface IQRangePickerProps extends ReactDatePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (date: [Date, Date]) => void;
  small?: boolean;
}

const QRangePicker: React.FC<IQRangePickerProps> = props => {
  const { startDate, small, endDate, onChange, ...rest } = props;
  const classes = useStyles();

  return (
    <>
      <QDatePicker
        inline
        small={small}
        selectsRange
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        calendarClassName={clsx(classes.calendar, 'start-calendar')}
        customInput={<QTextField />}
        {...rest}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    '& .react-datepicker': {
      '&__day': {
        zIndex: 1,
        '&::before': {
          content: `''`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 64,
          height: 24,
          zIndex: -1,
          transform: 'translate(calc(-50% - 12px), -50%)',
          pointerEvents: 'none',
          backgroundColor: theme.palette.qsecondary[50],
          transition: transitions.create('opacity'),
          opacity: 0,
          [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 20,
            transform: 'translate(calc(-50% - 3px), -50%)',
          },
        },
        '&:hover': {
          '&:not(.react-datepicker__day--in-range)': {
            '&::before': {
              borderTopRightRadius: 24,
              borderBottomRightRadius: 24,
              left: -24,
              transform: 'translate(0, -50%)',
              [theme.breakpoints.down('xs')]: {
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
              },
            },
          },
          '&:not(.react-datepicker__day--disabled)': {
            '& > div': {
              borderColor: theme.palette.qsecondary[100],
            },
          },
        },
        '&--today': {
          '&.react-datepicker__day': {},
        },
        '&--selected, &--selecting-range-start': {
          '&:hover': {
            '&::before': {
              borderTopLeftRadius: '24px !important',
              borderBottomLeftRadius: '24px !important',
              borderTopRightRadius: '0 !important',
              borderBottomRightRadius: '0 !important',
              left: '3px !important',
              transform: 'translate(0, -50%)',
            },
          },
        },
        '&--range-start': {
          '&::before': {
            borderTopLeftRadius: 24,
            borderBottomLeftRadius: 24,
            left: 3,
            transform: 'translate(0, -50%)',
            [theme.breakpoints.down('xs')]: {
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              left: 24,
            },
          },
        },
        '&--range-end': {
          color: theme.palette.extra.white,
          '& > div': {
            backgroundColor: theme.palette.secondary.main,
          },
          '&::before': {
            borderTopRightRadius: 24,
            borderBottomRightRadius: 24,
            left: -24,
            transform: 'translate(0, -50%)',
            [theme.breakpoints.down('xs')]: {
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
              left: 15,
            },
          },
        },
        '&.react-datepicker__day--range-start': {
          '&.react-datepicker__day--range-end': {
            '&::before': {
              opacity: 0,
            },
          },
        },
        '&--selecting-range': {
          '&-start': {
            '&::before': {
              borderTopLeftRadius: 24,
              borderBottomLeftRadius: 24,
              left: 3,
              transform: 'translate(0, -50%)',
            },
          },
          '&-end': {
            '&::before': {
              borderTopRightRadius: 24,
              borderBottomRightRadius: 24,
              left: -24,
              transform: 'translate(0, -50%)',
            },
          },
        },
        '&--in-range, &--in-selecting-range': {
          '&::before': {
            opacity: 1,
          },
        },
      },
    },
  },
}));

export default QRangePicker;
