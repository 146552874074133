import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Box, Grid, Typography, Container } from '@material-ui/core';
import { observer } from 'mobx-react';

import Loader from '../../../components/UI/Loader';
import { useCommonStyles } from '../../../styles/commonStyles';
import { routes } from '../../routes';
import BackButton from '../../../components/UI/BackButton';
import GalleryItem from './components/GalleryItem';
import AddPhoto from './components/AddPhoto';
import { useRootStore } from '../../../base/hooks/useRootStore';

interface IGalleryScreenProps {}

const GalleryScreen: React.FC<IGalleryScreenProps> = observer(() => {
  const commonClasses = useCommonStyles();
  const { galleryStore, userStore } = useRootStore();

  //Effects
  useEffect(() => {
    galleryStore.getList(userStore.userInfo?.companyId);
  }, []);

  //Handlers
  const handleSetMainPhoto = (photos: FileList | null) => {
    galleryStore.setMainPhoto(photos, userStore.userInfo?.companyId);
  };

  const handleAddPhoto = (photos: FileList | null) => {
    galleryStore.addPhotos(photos, userStore.userInfo?.companyId);
  };

  const handleDeletePhoto = (uuid: string) => () => {
    galleryStore.deletePhoto(uuid, userStore.userInfo?.companyId);
  };

  const handleDeleteMainPhoto = (uuid: string) => () => {
    galleryStore.changeMainPhoto(null);
    galleryStore.deletePhoto(uuid, userStore.userInfo?.companyId);
  };

  //Renders
  const renderImgList = () => {
    return galleryStore.photos.map(item => (
      item.main? null: <Grid key={item.id} item>
        <GalleryItem
          onDelete={handleDeletePhoto(item.file?.uuid || '')}
          img={item.file?.fullUrl || ''}
          loading={item.file?.uuid === galleryStore.deleteLoading}
        />
      </Grid>
    ));
  };

  return (
    <Box pb={13.5} position="relative" pt={{ xs: 4, lg: 10 }}>
      <Box mb={{ xs: 4, lg: 2 }} width="100%" px={2}>
        <BackButton to={routes.MainScreen.path} text="Вернуться на главную" />
      </Box>
      <Container maxWidth="xl">
        <Box>
          <Box mb={1}>
            <Typography variant="h1">Галерея</Typography>
          </Box>
          <Box mb={4}>
            <Typography className={clsx(commonClasses.blackMedium, commonClasses.body3)}>
              Загрузите изображение профиля (главное фото) и фотографии для галереи, которые будут отображаться в мобильном приложении. <br />
              Убедитесь, что выбранные вами фотографии соответствуют вашему бренду и легко идентифицируются с вашим бизнесом. <br />
              В качестве главного фото рекомендуем использовать логотип компании. <br />

            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={clsx(commonClasses.blackMedium, commonClasses.subtitle3)}>Главное фото</Typography>
          </Box>
          <Box mb={3}>
            {galleryStore.mainPhoto?.file?.uuid ? (
              <GalleryItem  onDelete={handleDeleteMainPhoto(galleryStore.mainPhoto.file?.uuid || '')} img={galleryStore.mainPhoto.file.fullUrl || ''} />
            ) : (
              <Box width={280} height={180} display="flex" alignItems="center" justifyContent="center">
                {galleryStore.addMainLoading && <Loader size={30} />}
                {!galleryStore.addMainLoading && <AddPhoto onChange={handleSetMainPhoto} isMainPhoto />}
              </Box>
            )}
          </Box>
          <Box mb={2}>
            <Typography className={clsx(commonClasses.blackMedium, commonClasses.subtitle3)}>
              Фотографии для галереи
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {renderImgList()}

            <Grid item>
              <Box width={280} height={180} display="flex" alignItems="center" justifyContent="center">
                {galleryStore.addLoading ? <Loader size={30} /> : <AddPhoto onChange={handleAddPhoto} />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
});

export default GalleryScreen;
