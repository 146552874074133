import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Box, Button, Divider, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import QModal from '../../../../components/UI/QModal';
import QTextField from '../../../../components/UI/QTextField';
import TextButton from '../../../../components/UI/TextButton';
import { Offer } from '../../../../modules/offer/models/Offer';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { useCustomTypography } from '../../../../styles/customTypography';

interface IOfferChooseModalProps {
  open: boolean;
  searchText: string;
  value: Offer[];
  onClose: () => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleChooseAllTags?: () => void;
}

const OfferChooseModal = React.forwardRef<HTMLDivElement>(function OfferChooseModal(props, ref) {
  const { open, value, searchText, onSearch, onClose, onHandleChooseAllTags } = props as IOfferChooseModalProps;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <div ref={ref}>
      <QModal keepMounted open={open} onClose={onClose}>
        <Box mb={4}>
          <Typography className={commonClasses.blackHigh} variant="h3">
            Выбор услуги
          </Typography>
        </Box>
        <Box mb={3} position="relative">
          <QTextField onChange={onSearch} value={searchText} placeholder="Найти услугу" />
          <IconButton classes={{ label: classes.label }} className={classes.icon}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box mb={3}>
          <Divider />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography>Вид услуги</Typography>
          <TextButton onClick={onHandleChooseAllTags} className={typography.captionMedium}>
            Выбрать всех
          </TextButton>
        </Box>
        <Box mb={6}>
          <Scrollbars autoHide autoHeight autoHeightMin="384px">
            {props.children}
          </Scrollbars>
        </Box>
        <Button disabled={!value?.length} onClick={onClose} fullWidth variant="contained" color="secondary">
          Выбрать
        </Button>
      </QModal>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: 10,
    width: 40,
    height: 40,
    color: theme.palette.qsecondary[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(-50%)',
    '& svg': {
      width: 23,
      height: 23,
    },
  },
}));

export default OfferChooseModal;
