import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import QIcon from './Icons/QIcon';

interface IRecordTagProps {
  title: string;
  icon: string;
  color?: string;
}

const RecordStatusTag: React.FC<IRecordTagProps> = ({ title, icon, color }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" color={color}>
      <div className={classes.icon}>
        <QIcon src={icon} />
      </div>
      <Typography variant="h4">{title}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    padding: 2,
    marginRight: theme.spacing(0.5),
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

export default RecordStatusTag;
