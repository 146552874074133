import SlotsApiRepository from './SlotsApiRepository';
import { SlotsFactory } from './SlotsFactory';
import CreateSlotDto from './forms/CreateSlotDto';
import DeleteSlotsDto from './forms/DeleteSlotsDto';
import EditSlotDto from './forms/EditSlotDto';
import { Slot } from './models/Slot';
import { IDateString, ISlotFormValues, ISlotTime } from './types/SlotTypes';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { eachMinuteOfInterval, format, hoursToMinutes, intervalToDuration } from 'date-fns';
import { uniqueId } from 'lodash';

export default class SlotsService {
  slotsApi: SlotsApiRepository;
  slotsFactory: SlotsFactory;

  constructor() {
    this.slotsApi = new SlotsApiRepository();
    this.slotsFactory = new SlotsFactory();
  }

  createSlots = async (companyId: number, slotValues: ISlotFormValues): Promise<Slot[]> => {
    const periods = slotValues.dates.map(date => ({
      startDate: date.startDate && format(date.startDate, 'yyyy-MM-dd'),
      endDate: this.getCurrentEndDate(date.startDate, date.endDate),
      times: slotValues.slotTimes.map(item => ({
        startTime: item.startTime && format(item.startTime, 'HH:mm'),
        endTime: item.endTime && format(item.endTime, 'HH:mm'),
      })),
    }));

    const slotDto = CreateSlotDto.populate({ companyEmployeeId: slotValues.employee?.id, periods }) as CreateSlotDto;

    const { data } = await this.slotsApi.createSlots(companyId, slotDto);
    return this.slotsFactory.createSlotList(data.data as any[]);
  };

  getSlot = async (companyId: number, slotId: number): Promise<Slot> => {
    const { data } = await this.slotsApi.getSlot(companyId, slotId);
    return this.slotsFactory.createSlot(data.data);
  };

  setBreak = async (companyId: number, slotId: number): Promise<Slot> => {
    const { data } = await this.slotsApi.setBreak(companyId, slotId);
    return this.slotsFactory.createSlot(data.data);
  };

  deleteSlot = async (companyId: number, slotId: number) => {
    return this.slotsApi.deleteSlot(companyId, slotId);
  };

  deleteSlots = async (companyId: number, selectedCells: Set<number>) => {
    const slotIds: number[] = [];

    selectedCells.forEach(value => slotIds.push(value));

    const deleteSlotsDto = DeleteSlotsDto.populate({ list: slotIds }) as DeleteSlotsDto;

    return this.slotsApi.deleteSlots(companyId, deleteSlotsDto);
  };

  editSlot = async (companyId: number, slotId: number, slotData: IDateString): Promise<Slot> => {
    const editSlotDto = EditSlotDto.populate(slotData) as EditSlotDto;

    const { data } = await this.slotsApi.editSlot(companyId, slotId, editSlotDto);
    return this.slotsFactory.createSlot(data.data);
  };

  createSlotTimes = (start: Date, end: Date, step: number): ISlotTime[] => {
    const timeIntervalDuration = intervalToDuration({ start, end });
    const intervalArray = eachMinuteOfInterval({ start, end }, { step });

    const minutes = timeIntervalDuration.minutes ?? 0;
    const hoursInMinutes = hoursToMinutes(timeIntervalDuration?.hours ?? 0);
    const timeIntervalInMinutes = hoursInMinutes + minutes;
    const restMinutes = timeIntervalInMinutes % step;

    const mappedSlotTimes = intervalArray.reduce<ISlotTime[]>((acc, item, i, self): ISlotTime[] => {
      const nextItem = self[i + 1];

      if (self[i + 1] !== undefined) {
        acc.push(this.createNewSlotTimeObject(item, nextItem));
      } else {
        if (restMinutes !== 0) {
          acc.push(this.createNewSlotTimeObject(item, end));
        }
      }

      return acc;
    }, []);

    return mappedSlotTimes;
  };

  createNewSlotTimeObject = (startTime: MaterialUiPickersDate, endTime: MaterialUiPickersDate): ISlotTime => {
    return { id: uniqueId('timeId-'), startTime, endTime };
  };

  getCurrentEndDate = (startDate: Date | null, endDate: Date | null) => {
    if (!endDate && startDate) {
      return format(startDate, 'yyyy-MM-dd');
    }

    if (endDate) {
      return format(endDate, 'yyyy-MM-dd');
    }

    return '';
  };
}
