import { AuthStore } from '../modules/auth/AuthStore';

type Store = AuthStore;

export default class ResendCodeTimer {
  timerId: number | null = null;
  store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  start = () => {
    if (!this.timerId) {
      this.timerId = window.setInterval(() => {
        this.store.setResendCodeTime(this.store.resendCodeTime - 1);

        if (this.store.resendCodeTime <= 0) {
          this.stop();
        }
      }, 1000);
    }
  };

  stop = () => {
    if (this.timerId) {
      this.store.setResendCodeTime(0);
      window.clearInterval(this.timerId);
      this.timerId = null;
    }
  };
}
