import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { Box, Divider, Fade, IconButton, makeStyles, Menu, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import envelopeIcon from '../../assets/icons/envelope.svg';
import Loader from '../UI/Loader';
import { formatRu } from '../../utils/formatRu';
import { useAllMQ } from '../../hooks/useAllMQ';
import { useRootStore } from '../../base/hooks/useRootStore';
import { useCommonStyles } from '../../styles/commonStyles';
import { useCustomTypography } from '../../styles/customTypography';
import { ReqType } from '../../modules/notfications/types/NotificationTypes';

interface INotificationsModalProps {}

const NotificationsModal: React.FC<INotificationsModalProps> = observer(() => {
  const { notificationsStore } = useRootStore();
  const { notificationAnchorEl, setNotificationAnchorEl } = notificationsStore;

  const openNotif = Boolean(notificationAnchorEl);

  const { isSM } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    if (openNotif && !notificationsStore.notificationsList.length) {
      notificationsStore.getNotificationsList(ReqType.INIT);
    }
  }, [notificationsStore, openNotif]);

  // Handlers
  const handleUpdate = ({ clientHeight, scrollHeight, scrollTop }: positionValues) => {
    const isLoadMore = scrollHeight !== 0 && scrollHeight <= scrollTop + clientHeight;

    if (isLoadMore && !notificationsStore.hotLoading) {
      notificationsStore.getNotificationsList(ReqType.BOTTOM, true);
    }
  };

  const handleCloseNotification = () => {
    setNotificationAnchorEl(null);
  };

  // Renders
  const renderDay = (date: string) => {
    return (
      <Box key={date} mb={4}>
        <Box mb={2}>
          <Divider />
        </Box>
        <Typography align="center" className={clsx(typography.text, commonClasses.blackLow)}>
          {date}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Menu
        PopoverClasses={{ paper: classes.notificationPaper }}
        classes={{ list: classes.list }}
        keepMounted
        open={openNotif}
        anchorEl={notificationAnchorEl}
        onClose={handleCloseNotification}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isSM ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isSM ? 'left' : 'right',
        }}
      >
        {notificationsStore.loading && <Loader isAbsolute color="secondary" />}
        <Box mb={{ xxs: 3, md: 4.5 }} pr={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Уведомления</Typography>
          <IconButton onClick={handleCloseNotification} className={classes.closeBtn}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!!notificationsStore.notificationsList.length ? (
          <Scrollbars onUpdate={handleUpdate} autoHide autoHeight autoHeightMax={364}>
            <div className={clsx(classes.wrap, 'not-empty')}>
              {notificationsStore.notificationsWithDate.map(item => (
                <Fragment key={item.id}>
                  {item.date && renderDay(item.date)}
                  <Box mb={{ xxs: 2, md: 4 }} display="flex" alignItems="flex-start" justifyContent="space-between">
                    <Box mr={2}>
                      {item.title && (
                        <Typography
                          variant="body1"
                          className={classes.notificationItem}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}
                      {item.message && (
                        <Typography
                          variant="body1"
                          className={classes.notificationItem}
                          dangerouslySetInnerHTML={{ __html: item.message }}
                        />
                      )}
                    </Box>
                    {item.createdAt && (
                      <Typography variant="body2" className={commonClasses.blackLow}>
                        {formatRu(item.createdAt, 'HH:mm')}
                      </Typography>
                    )}
                  </Box>
                </Fragment>
              ))}
              {notificationsStore.hotLoading && <Loader minHeight={50} color="secondary" />}
            </div>
          </Scrollbars>
        ) : (
          <div className={clsx(classes.wrap, 'empty')}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="100%"
              height="100%"
            >
              <div className={classes.iconWrap}>
                <img src={envelopeIcon} alt="" />
              </div>
              <Typography variant="h2" className={classes.emptyText}>
                Нет уведомлений
              </Typography>
            </Box>
          </div>
        )}
      </Menu>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  notificationPaper: {
    display: 'flex',
    marginTop: 52,
    maxWidth: 557,
    minHeight: 464,
    width: '100%',
    padding: theme.spacing(2, 0, 2, 2),
    borderRadius: 8,
    background: theme.palette.extra.bg,
    border: `1px solid ${theme.palette.extra.stroke}`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    overflow: 'visible',
    [theme.breakpoints.up('md')]: {
      marginLeft: 12,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 440,
      minHeight: 380,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  closeBtn: {
    padding: 4,
  },
  list: {
    padding: 0,
    width: '100%',
  },
  notificationItem: {
    color: theme.palette.black.medium,
    '& b': {
      color: theme.palette.black.high,
      fontWeight: 600,
    },
  },
  wrap: {
    '&.not-empty': {
      paddingRight: 37,
      paddingBottom: 16,
      '& >:last-child': {
        marginBottom: '0 !important',
      },
    },
    '&.empty': {
      height: 'calc(100% - 68px)',
    },
  },
  iconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 29,
    maxWidth: 140,
    width: '100%',
    height: 100,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      height: 80,
    },
  },
  emptyText: {
    color: theme.palette.qsecondary[100],
  },
}));

export default NotificationsModal;
