import { DayCode } from '../types/ScheduleTypes';
import AbstractModel from '../../../../base/AbstractModel';

export class Schedule extends AbstractModel {
  id: number | null = null;
  dayCode: DayCode | null = null;
  dayNumber: number | null = null;
  dayName: string | null = null;
  shortDayName: string | null = null;
  timeStart: string | null = null;
  timeEnd: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
