import { Fragment, useCallback } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';

import QIcon from '../UI/Icons/QIcon';
import { fileIcon } from '../UI/Icons/SvgIcons';
import { splitingArray } from '../../utils/splittingArray';
import { Message } from '../../modules/chat/models/Message';
import { useRootStore } from '../../base/hooks/useRootStore';

interface IChatFilesProps {
  message: Message;
}

const ChatFiles: React.FC<IChatFilesProps> = observer(({ message }) => {
  const { chatStore } = useRootStore();
  const classes = useStyles();

  const images = splitingArray(message.attachFiles).images;
  const files = splitingArray(message.attachFiles).files;

  // Handlers
  const handleOpenImageViewer = useCallback(id => {
    const index = chatStore.messagesImages.findIndex(item => item.id === id);

    chatStore.setCurrentImageIndex(index);
    chatStore.setImageViewerOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (files.length || images.length) {
    return (
      <Fragment key={message.id}>
        <div className={classes.gridContainer}>
          {images.map((group, index) => {
            return (
              <Box
                key={index}
                display="flex"
                justifyContent="flex-start"
                marginRight={group.length === 1 ? 'auto' : '0'}
              >
                {group.length === 3
                  ? group.map(item => (
                      <Box key={item.id} p={0.25}>
                        <Button onClick={() => handleOpenImageViewer(item.id)}>
                          <div key={item.id} className={clsx(classes.smallImgWrap, classes.img)}>
                            <img src={item.file?.fullUrl} alt="" />
                          </div>
                        </Button>
                      </Box>
                    ))
                  : group.map(item => (
                      <Box key={item.id} p={0.25}>
                        <Button onClick={() => handleOpenImageViewer(item.id)}>
                          <div className={clsx(classes.bigImgWrap, classes.img)} key={item.id}>
                            <img src={item.file?.fullUrl} alt="" />
                          </div>
                        </Button>
                      </Box>
                    ))}
              </Box>
            );
          })}
        </div>
        {files.map(item => (
          <a key={item.id} href={item.file?.fullUrl} className="router-link" rel="noreferrer" target="_blank">
            <Box display="flex" alignItems="center" color="#2A58A5" mb={1}>
              <Box display="flex" justifyContent="center" alignItems="center" mr={1.5}>
                <QIcon src={fileIcon} width={20} height={20} />
              </Box>
              {item.file?.fileName}
            </Box>
          </a>
        ))}
      </Fragment>
    );
  }

  return <></>;
});

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  bigImgWrap: {
    width: 96,
    height: 96,
  },
  smallImgWrap: {
    width: 64,
    height: 64,
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    width: 'calc(100% + 8px)',
    margin: -2,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export default ChatFiles;
