import { useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import ScheduleItem from './components/ScheduleItem';
import Loader from '../../../components/UI/Loader';
import { stringToDate } from '../../../utils/stringToDate';
import { useRootStore } from '../../../base/hooks/useRootStore';
import ContentContainer from '../../../components/ContentContainer';
import { Schedule } from '../../../modules/role-admin/schedule/models/Schedule';
import { DateKey } from '../../../modules/role-admin/schedule/types/ScheduleTypes';

interface IScheduleScreenProps {}

const ScheduleScreen: React.FC<IScheduleScreenProps> = observer(() => {
  const { scheduleStore } = useRootStore();
  const [values, setValues] = useState<Schedule[]>([]);

  const hasInvalidTime = useMemo(() => {
    const arr: boolean[] = [];
    const today = format(new Date(), 'yyyy-MM-dd');

    values.forEach(item => {
      const timeStart = item.timeStart ? stringToDate(`${today} ${item.timeStart}`) : null;
      const timeEnd = item.timeEnd ? stringToDate(`${today} ${item.timeEnd}`) : null;

      if (timeStart) {
        arr.push(isValid(timeStart));
      }

      if (timeEnd) {
        arr.push(isValid(timeEnd));
      }
    });

    return arr.every(item => item);
  }, [values]);

  // Effects
  useEffect(() => {
    scheduleStore.getScheduleList();

    return () => {
      scheduleStore.clearScheduleList();
    };
  }, [scheduleStore]);

  useEffect(() => {
    if (scheduleStore.scheduleList.length) {
      setValues(scheduleStore.scheduleList);
    }
  }, [scheduleStore.scheduleList]);

  // Handlers
  const handleChangeTime = (date: MaterialUiPickersDate, id: number, key: DateKey) => {
    const timeStart = date && isValid(date) ? format(date, 'HH:mm') : date;
    const timeEnd = date && isValid(date) ? format(date, 'HH:mm') : date;

    setValues(prev => {
      return prev.map(item => {
        if (item.dayNumber === id) {
          switch (key) {
            case 'timeStart':
              return new Schedule({ ...item, [key]: timeStart });
            case 'timeEnd':
              return new Schedule({ ...item, [key]: timeEnd });
            default:
              return item;
          }
        }

        return item;
      });
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    scheduleStore.setSchedule(values);
  };

  // Renders
  if (scheduleStore.loading) {
    return <Loader color="secondary" isAbsolute />;
  }

  return (
    <ContentContainer title="Расписание">
      {scheduleStore.scheduleLoading && <Loader color="secondary" isAbsolute />}
      <form onSubmit={handleSubmit}>
        <Box mb={5}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {values.map(schedule => (
              <ScheduleItem key={schedule.id} schedule={schedule} onHandleChangeTime={handleChangeTime} />
            ))}
          </MuiPickersUtilsProvider>
        </Box>
        <Box>
          <Button fullWidth type="submit" color="secondary" variant="contained" disabled={!hasInvalidTime}>
            Сохранить
          </Button>
        </Box>
      </form>
    </ContentContainer>
  );
});

export default ScheduleScreen;
