import { createMuiTheme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1208 + 48,
    },
  },
  palette: {
    primary: { main: '#E85748' },
    secondary: {
      main: '#373E4D',
    },
    extra: {
      white: '#fff',
      stroke: '#E7E8EA',
      fill: '#F8F8F8',
      bg: '#FDFDFD',
      green: '#0DC268',
    },
    black: {
      high: '#212121',
      medium: '#676767',
      low: '#A8A8A8',
    },
    qprimary: {
      50: '#FCEBE9',
      500: '#E85748',
    },
    qsecondary: {
      50: '#E7E8EA',
      100: '#C3C5CA',
      200: '#9B9FA6',
      300: '#737882',
      400: '#555B68',
      500: '#373E4D',
      600: '#313846',
      900: '#161B25',
    },
    error: { main: 'rgba(232, 87, 72, 0.9)' },
  },
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
    h1: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: '130%',
      fontFamily: "'IBM Plex Serif', serif",
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      fontFamily: "'IBM Plex Serif', serif",
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '28px',
      fontFamily: "'IBM Plex Serif', serif",
    },
    h4: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '21px',
      fontFamily: "'IBM Plex Serif', serif",
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },
    button: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: '0.2px',
      textTransform: 'initial',
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '22px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
    },
    overline: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '14px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          minWidth: 375,
          backgroundColor: '#FDFDFD',
        },
        main: {
          flex: '1 0 auto',
        },
        '#root': {
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        '.router-link': {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        padding: '16px 16px',
        borderRadius: 8,
        color: '#fff',
        boxShadow: 'none !important',
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#313846',
          color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#C3C5CA',
          color: '#fff',
        },
      },
      outlinedSecondary: {
        padding: '15px 16px',
        color: '#373E4D',
        borderColor: '#373E4D',
        '&:hover': {
          color: '#313846',
          borderColor: '#313846',
          backgroundColor: 'transparent',
        },
        '&:disabled': {
          color: '#C3C5CA',
          borderColor: '#C3C5CA',
          backgroundColor: 'transparent',
        },
      },
      text: {
        padding: '2px 0',
        color: '#373E4D',
        backgroundColor: 'transparent',
        transition: transitions.create('color'),
        '&:hover': {
          color: '#313846',
          backgroundColor: 'transparent',
        },
        '&:disabled': {
          color: '#C3C5CA',
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        padding: '2px 0',
        color: '#373E4D',
        backgroundColor: 'transparent',
        transition: transitions.create('color'),
        '&:hover': {
          color: '#313846',
          backgroundColor: 'transparent',
        },
        '&:disabled': {
          color: '#C3C5CA',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormLabel: {
      root: {
        display: 'block',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#676767',
        padding: 4,
        '&$disabled': {
          color: '#C3C5CA',
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '15.7px 16px',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        background: '#FFFFFF',
        borderRadius: 8,
        color: '#212121',
        transition: transitions.create('color'),
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        '& $notchedOutline': {
          border: '0.5px solid #E7E8EA',
          borderWidth: '0.5px !important',
          transition: transitions.create('border-color'),
        },
        '&:hover:not($disabled) $notchedOutline': {
          borderColor: '#212121',
        },
        '&:focus': {
          outline: 'none',
        },
        '&$disabled': {
          color: '#C3C5CA',
          boxShadow: 'none',
          '& $notchedOutline': {
            borderColor: '#C3C5CA',
          },
        },
      },
      input: {
        padding: '15.7px 16px',
      },
      notchedOutline: {
        borderColor: '#E7E8EA',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#EFEFEF',
      },
    },
    MuiTooltip: {
      popper: {
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
      },
      tooltip: {
        padding: 10,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #E7E8EA',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '22px',
        color: '#000000',
        margin: '0 !important',
      },
    },
  },
});
