import AbstractApiRepository from '../../../../base/repositories/AbstractApiRepository';
import { ILoginData, IResetPasswordData } from './types/LoginTypes';

export default class LoginApiRepository extends AbstractApiRepository {
  login = (data: ILoginData) => {
    return this.post({ url: `/company/auth/login`, data });
  };

  resetPassword = (data: IResetPasswordData) => {
    return this.post({ url: `/company/auth/reset-password`, data });
  };
}
