import AddOfferDto from './forms/AddOfferDto';
import UpdateOfferDto from './forms/UpdateOfferDto';
import { IOfferReqBody, IOffersListReqBody } from './types/OfferTypes';
import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { IPaginationParams, IPaginationRes } from '../../base/modules/pagination/PaginationTypes';

export default class OfferApiRepository extends AbstractApiRepository {
  getEmployeeOfferList = (companyId: number, data: IOfferReqBody) => {
    return this.post({ url: `/company/${companyId}/employee-offers`, data });
  };

  getOffersList = (params: IPaginationParams, data?: IOffersListReqBody) => {
    return this.post<IPaginationRes>({ url: `/offers/list?offset=${params.offset}&limit=${params.limit}`, data });
  };

  getCompanyOffersList = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/offers/list` });
  };

  getCompanyOffer = (companyId: number, offerId: number) => {
    return this.get({ url: `/company/${companyId}/offers/view/${offerId}` });
  };

  addOffer = (companyId: number, data: AddOfferDto) => {
    return this.post({ url: `/company/${companyId}/offers/create`, data });
  };

  updateOffer = (companyId: number, offerId: number, data: UpdateOfferDto) => {
    return this.post({ url: `/company/${companyId}/offers/update/${offerId}`, data });
  };

  deleteOffer = (companyId: number, offerId: number) => {
    return this.delete({ url: `/company/${companyId}/offers/delete/${offerId}` });
  };
}
