import AbstractDto from '../../../base/AbstractDto';

export default class CreateRecordDto extends AbstractDto {
  companyEmployeeSlotId: number | null = null;
  companyOfferId: number | null = null;
  companyEmployeeId: number | null = null;
  clientName: string | null = null;
  clientPhone: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
