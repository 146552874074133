import { observer } from 'mobx-react';
import { Box, Button, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core';

import QModal from '../../../../../components/UI/QModal';
import Loader from '../../../../../components/UI/Loader';
import { useRootStore } from '../../../../../base/hooks/useRootStore';
import { useCustomTypography } from '../../../../../styles/customTypography';

interface ICompleteModalProps extends DialogProps {}

const CompleteModal: React.FC<ICompleteModalProps> = observer(props => {
  const { slotsStore, recordsStore } = useRootStore();
  const classes = useStyles();
  const typography = useCustomTypography();

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    slotsStore.setModalOpen('completeModal', false);
  };

  const handleComplete = () => {
    if (slotsStore.modalState.record.currentId) {
      recordsStore.completeRecordById(slotsStore.modalState.record.currentId);
    }
  };

  return (
    <QModal onClose={handleClose} className={classes.confirmModalWrap} {...props}>
      {recordsStore.completeLoading && <Loader isAbsolute color="secondary" />}
      <Box mb={3} maxWidth="80%">
        <Typography variant="h3">Подтверждение оказания услуги</Typography>
      </Box>
      <Box mb={4.75}>
        <Typography className={typography.text}>Вы подтверждаете, что услуга оказана?</Typography>
      </Box>
      <Box mb={1}>
        <Button fullWidth color="secondary" variant="contained" onClick={handleComplete}>
          Подтверждаю
        </Button>
      </Box>
      <div>
        <Button onClick={() => handleClose(null, null)} fullWidth variant="outlined" color="secondary">
          Отмена
        </Button>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  confirmModalWrap: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
}));

export default CompleteModal;
