import AbstractModel from '../../../base/AbstractModel';
import { Category } from './Category';

export class Offer extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  offerTitle: string | null = null;
  subtitle: string | null = null;
  price: string | null = null;
  duration: number | string | null = null;
  category: Category | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
