import { EmployeeUser } from './EmployeeUser';
import AbstractModel from '../../../../base/AbstractModel';
import { RoleKeys } from '../../../../base/routes/types/RouteTypes';

export class EmployeeInfo extends AbstractModel {
  id: number = 0;
  role: RoleKeys | null = null;
  employeeOffers: number[] = [];
  specialization: string | null = null;
  status: 'active' | 'blocked' = 'active';
  user: EmployeeUser | null = null;

  constructor(props: any) {
    super();
    this.load(props);

    this.user = new EmployeeUser(props.user);
  }
}
