import { makeAutoObservable, runInAction } from 'mobx';

import ReportService from './ReportService';
import { IMasterReportFormValues, IReportFormValues } from './types/ReportTypes';
import { downloadFile } from '../../../utils/downloadFile';

export class ReportStore {
  loading = false;
  reportLoading = false;

  private reportService: ReportService;

  reportId: number | null = null;
  reportSuccessModal = false;

  constructor() {
    makeAutoObservable(this);
    this.reportService = new ReportService();
  }

  getReportById = (reportId: number) => {
    this.setReportLoading(true);
    this.reportService
      .getReportById(reportId)
      .then(report => {
        runInAction(() => {
          downloadFile(report.fullUrl, report.fileName);
          this.setModal(false);
        });
      })
      .catch(() => {})
      .finally(() => this.setReportLoading(false));
  };

  createReport = (values: IReportFormValues) => {
    this.setLoading(true);
    this.reportService
      .createReport(values)
      .then(data => {
        runInAction(() => {
          this.reportId = data.reportId;
          this.setModal(true);
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  createMasterReport = (values: IMasterReportFormValues) => {
    this.setLoading(true);
    this.reportService
      .createMasterReport(values)
      .then(data => {
        runInAction(() => {
          this.reportId = data.reportId;
          this.setModal(true);
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setReportLoading = (value: boolean) => {
    this.reportLoading = value;
  };

  setModal = (value: boolean) => {
    this.reportSuccessModal = value;
  };
}
