import AbstractModel from '../../../base/AbstractModel';
import { IImgData } from '../../../base/types/BaseTypes';
import { INotification } from '../../push/types/PushTypes';
import { RoleKeys } from '../../../base/routes/types/RouteTypes';

export class User extends AbstractModel {
  companyEmployeeId: number | null = null;
  companyName: string | null = null;
  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  photo: IImgData | null = null;
  companyId: number | null = null;
  role: RoleKeys | null = null;
  notifications: INotification[] = [];
  userId: number | null = null;
  timezone: string | null = null;
  unreadNotificationsCount: number = 0;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
