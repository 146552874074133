import { useRootStore } from '../../../../base/hooks/useRootStore';
import AlertMessage from '../../../../components/UI/AlertMessage';
import Loader from '../../../../components/UI/Loader';
import QAlert from '../../../../components/UI/QAlert';
import TextButton from '../../../../components/UI/TextButton';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import { IRecord, ISlot } from '../../../../modules/records/types/RecordTypes';
import { ModalTypes } from '../../../../modules/role-admin/slots/types/SlotTypes';
import { getButtonDynamicStyles } from '../../../../utils/getButtonDynamicStyles';
import CancelModal from './modals/CancelModal';
import CompleteModal from './modals/CompleteModal';
import DeleteConfirmModal from './modals/DeleteConfirmModal';
import DeleteSelectedSlotsConfirmModal from './modals/DeleteSelectedSlotsConfirmModal';
import RecordModal from './modals/RecordModal';
import SlotModal from './modals/SlotModal';
import SlotTimeEditModal from './modals/SlotTimeEditModal';
import { Paper, Theme, makeStyles, IconButton } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import clsx from 'clsx';
import { min, subDays } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import LongPressable from 'react-longpressable';

interface ITableScrollPos {
  scrollLeft: number;
  scrollTop: number;
}

interface ISlotTableProps {}

const SlotTable: React.FC<ISlotTableProps> = observer(() => {
  const { slotsStore, recordsStore, adminMainStore } = useRootStore();
  const { modalState, selectedCells, setModalState, toggleCellsSelect } = slotsStore;

  const minDate = min([subDays(new Date(), 1)]);
  const isNotLessCurrentDay = adminMainStore.selectedDate <= minDate;

  const [tableScrollPos, setTableScrollPos] = useState<ITableScrollPos>({
    scrollLeft: 0,
    scrollTop: 0,
  });

  const classes = useStyles();
  const { isMD } = useAllMQ();

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      if (slotsStore.message.length) {
        slotsStore.setMessage('');
      }
    }, 5000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsStore.message]);

  useEffect(() => {
    recordsStore.getWorkTime(adminMainStore.formattedDate);

    return () => {
      recordsStore.clearWorkTime();
    };
  }, [adminMainStore.formattedDate, recordsStore]);

  useEffect(() => {
    recordsStore.getAdminSchedule();

    return () => {
      recordsStore.clearAdminSchedule();
    };
  }, [adminMainStore.formattedDate, recordsStore]);

  //Handlers
  const handleOpenRecordModal = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e?.preventDefault();

    setModalState({
      ...modalState,
      record: { modal: true, currentId: id },
    });
  };

  const handleOpenSlotModal = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    e?.preventDefault();

    if (e.ctrlKey || e.metaKey) {
      toggleCellsSelect(id);
      return;
    }

    setModalState({
      ...modalState,
      slot: { modal: true, currentId: id },
    });
  };

  const handleToggleCellSelect = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    toggleCellsSelect(id);
  };

  const handleOpenTimeModal = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e?.stopPropagation();

    setModalState({
      ...modalState,
      time: { modal: true, currentId: id },
    });
  };

  const handleCloseModal = (modalId: ModalTypes) => {
    switch (modalId) {
      case 'record':
        setModalState({
          ...modalState,
          record: { modal: false, currentId: null },
        });
        recordsStore.setExtraServices([]);
        break;
      case 'slot':
        setModalState({
          ...modalState,
          slot: { modal: false, currentId: null },
        });
        break;
      case 'time':
        setModalState({
          ...modalState,
          time: { modal: false, currentId: null },
        });
        break;
    }
  };

  const handleCloseMessage = () => {
    slotsStore.setMessage('');
  };

  // Renders
  const renderRecordButton = (record: IRecord) => {
    const { statusTopPos, slotHeightIndent, statusHeightPercent } = getButtonDynamicStyles(
      record.startTime,
      record.endTime,
    );

    return (
      <TextButton
        key={record.id}
        style={{
          top: `${statusTopPos}%`,
          minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
        }}
        className={clsx(classes.statusBtn, 'full')}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOpenRecordModal(e, record.id)}
      >
        {!!record.unreadMessagesCount && <span className={classes.notification}>{record.unreadMessagesCount}</span>}
        {record.startTime} Запись
      </TextButton>
    );
  };

  const renderSlotButton = (slot: ISlot) => {
    const { statusTopPos, slotHeightIndent, statusHeightPercent } = getButtonDynamicStyles(
      slot.startTime,
      slot.endTime,
    );

    const isSelected = selectedCells.has(slot.id);

    switch (slot.status) {
      case 'free':
        return isNotLessCurrentDay ? (
          <div
            key={slot.id}
            style={{
              border: `1px solid #676767`,
              top: `${statusTopPos}%`,
              minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
            }}
            className={clsx(classes.statusBtn, { [slot.status]: true })}
          >
            Свободно
          </div>
        ) : (
          <LongPressable
            onShortPress={(e: React.MouseEvent<HTMLDivElement>) => handleOpenSlotModal(e, slot.id)}
            onLongPress={(e: React.MouseEvent<HTMLDivElement>) => handleToggleCellSelect(e, slot.id)}
          >
            <TextButton
              key={slot.id}
              style={{
                opacity: isSelected ? '50%' : '100%',
                border: isSelected ? `1px dotted #3366ff` : `1px solid #676767`,
                top: `${statusTopPos}%`,
                minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
              }}
              className={clsx(classes.statusBtn, { [slot.status]: true })}
            >
              <IconButton
                classes={{ label: classes.editIconLabel }}
                className={clsx(classes.editIcon, { center: statusHeightPercent <= 50 })}
                onPointerUp={(e: React.MouseEvent<HTMLButtonElement>) => handleOpenTimeModal(e, slot.id)}
                onPointerDown={(e: React.MouseEvent<HTMLButtonElement>) => e?.stopPropagation()} // Запрещаем long press
              >
                <EditRoundedIcon />
              </IconButton>
              Свободно
            </TextButton>
          </LongPressable>
        );

      case 'break':
        return (
          <LongPressable
            onShortPress={(e: React.MouseEvent<HTMLDivElement>) => handleOpenSlotModal(e, slot.id)}
            onLongPress={(e: React.MouseEvent<HTMLDivElement>) => handleToggleCellSelect(e, slot.id)}
          >
            <TextButton
              key={slot.id}
              style={{
                opacity: isSelected ? '50%' : '100%',
                border: isSelected ? `1px dotted #3366ff` : `1px solid #676767`,
                top: `${statusTopPos}%`,
                minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
              }}
              className={clsx(classes.statusBtn, { [slot.status]: true })}
            >
              {slot.startTime} Перерыв
            </TextButton>
          </LongPressable>
        );
    }
  };

  const renderCell = (records: IRecord[], slots: ISlot[]) => {
    return recordsStore.workTime?.workTime.map((item, index) => {
      const filteredRecords: IRecord[] = records.filter(
        record => record.startTime.split(':')[0] === item.split(':')[0],
      );
      const filteredSlots: ISlot[] = slots.filter(slot => slot.startTime.split(':')[0] === item.split(':')[0]);

      return (
        <div key={index} className={clsx(classes.tableTd, classes.slotTd)}>
          {filteredRecords.length !== 0 && filteredRecords.map(record => renderRecordButton(record))}
          {filteredSlots.length !== 0 && filteredSlots.map(slot => renderSlotButton(slot))}
        </div>
      );
    });
  };

  return (
    <Paper className={classes.table}>
      {recordsStore.loading && recordsStore.workTimeLoading && <Loader isAbsolute color="secondary" />}
      <Scrollbars
        autoHide
        autoHeight
        autoHeightMin={isMD ? 'calc(100vh - 134px)' : 'calc(100vh - 152px)'}
        onUpdate={values => {
          if (values.scrollLeft !== tableScrollPos.scrollLeft || values.scrollTop !== tableScrollPos.scrollTop) {
            setTableScrollPos({
              ...tableScrollPos,
              scrollLeft: values.scrollLeft,
              scrollTop: values.scrollTop,
            });
          }
        }}
      >
        <div className={classes.tableRow}>
          <div
            className={clsx(classes.col, classes.timeCol)}
            style={{ transform: `translateX(${tableScrollPos.scrollLeft}px)` }}
          >
            <div
              className={clsx(classes.tableTd, classes.timeTh)}
              style={{ transform: `translateY(${tableScrollPos.scrollTop}px)` }}
            />
            {recordsStore.workTime?.workTime.map(item => (
              <div key={item} className={clsx(classes.tableTd, classes.timeTd)}>
                {item}
              </div>
            ))}
          </div>
          {recordsStore.adminSchedule.map(({ name, records, slots }, idx) => (
            <div key={idx} className={clsx(classes.col, { [classes.firstCol]: idx === 0 })}>
              <div
                className={clsx(classes.tableTd, classes.slotTh)}
                key={idx}
                style={{ transform: `translateY(${tableScrollPos.scrollTop}px)` }}
              >
                {name}
              </div>
              {renderCell(records as IRecord[], slots as ISlot[])}
            </div>
          ))}
        </div>
      </Scrollbars>

      <RecordModal open={modalState.record.modal} onClose={() => handleCloseModal('record')} />
      <SlotModal open={modalState.slot.modal} onClose={() => handleCloseModal('slot')} />
      <SlotTimeEditModal open={modalState.time.modal} onClose={() => handleCloseModal('time')} />

      <CompleteModal open={slotsStore.modalOpen.completeModal} />
      <CancelModal open={slotsStore.modalOpen.confirmModal} />
      <DeleteConfirmModal open={slotsStore.modalOpen.deleteConfirmModal} />
      <DeleteSelectedSlotsConfirmModal open={slotsStore.modalOpen.deleteSelectedConfirmModal} />

      {slotsStore.message.length !== 0 && (
        <QAlert type="success" onClose={handleCloseMessage}>
          <AlertMessage staticPos messages={slotsStore.message} />
        </QAlert>
      )}
    </Paper>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    boxShadow: 'none',
    width: 'calc(100vw - 312px)',
    height: 'calc(100vh - 152px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 142px)',
      width: '100vw',
    },
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  col: {
    '&:not(:last-child)': {
      marginRight: '-1px',
    },
    '&:not(:first-child)': {
      position: 'relative',
      paddingTop: 56,
    },
  },
  firstCol: {
    marginLeft: 77,
  },
  timeCol: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    paddingTop: 56,
  },
  tableTd: {
    ...theme.typography.body2,
    color: theme.palette.black.high,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    border: `1px solid ${theme.palette.extra.stroke}`,
    background: theme.palette.extra.white,
    '&:not(:last-child)': {
      marginBottom: '-1px',
    },
  },
  timeTd: {
    width: 78,
    height: 68,
    minWidth: 78,
    minHeight: 68,
  },
  timeTh: {
    width: 78,
    height: 56,
    minWidth: 78,
    minHeight: 56,
    marginBottom: '0 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 4,
  },
  slotTh: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 128,
    height: 56,
    minWidth: 128,
    minHeight: 56,
    marginBottom: '0 !important',
    zIndex: 2,
  },
  slotTd: {
    width: 128,
    height: 68,
    minWidth: 128,
    minHeight: 68,
    padding: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  statusBtn: {
    position: 'absolute',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 2px)',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '0',
    borderRadius: 8,
    transform: 'translateX(-50%)',
    marginTop: 1,
    zIndex: 1,
    transition: theme.transitions.create('transform', { duration: 100 }),
    '&.full': {
      color: theme.palette.extra.white,
      background: theme.palette.primary.main,
    },
    '&.break': {
      color: theme.palette.black.medium,
      background: theme.palette.qsecondary[50],
    },
    '&.free': {
      color: theme.palette.black.high,
      borderRadius: 8,
      border: `1px solid ${theme.palette.black.medium}`,
      background: '#fff',
    },
    [theme.breakpoints.down('md')]: {
      '&:active': {
        transform: 'translateX(-50%) scale(.99)',
      },
    },
  },
  notification: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 2,
    top: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: 15,
    height: 15,
    background: theme.palette.extra.white,
    borderRadius: '50%',
  },
  editIcon: {
    position: 'absolute',
    right: 1,
    top: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 27,
    padding: 5,
    zIndex: 3,
    color: theme.palette.black.medium,
    userSelect: 'none',
    '&.center': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  editIconLabel: {
    height: '100%',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

export default SlotTable;
