import AbstractModel from '../../../AbstractModel';
import { IChatMeta } from '../PaginationTypes';

export default class ChatPagination extends AbstractModel {
  meta: IChatMeta = { offsetFromId: null, totalCount: null };

  constructor(props: any) {
    super();
    this.load(props);
  }
}
