import { makeStyles, Theme, Button, Box, FormLabel, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';

import QIcon from './Icons/QIcon';

interface IImageUploaderButton {
  label?: string;
  startIcon?: string;
  endIcon?: string;
  fileName?: string;
  helperText?: string;
  error?: boolean;
  onHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageUploaderButton: React.FC<IImageUploaderButton> = props => {
  const { label, onHandleChange, startIcon, endIcon, fileName, error, helperText, ...rest } = props;
  const classes = useStyles();

  return (
    <Box>
      {label && <FormLabel>{label}</FormLabel>}
      <div className={classes.buttonWrapper}>
        <input onChange={onHandleChange} type="file" name="file" id="fileInput" className={classes.fileInput} />
        <Button
          fullWidth
          component="label"
          htmlFor="fileInput"
          variant="contained"
          className={clsx(classes.fileBtn, { [classes.error]: error })}
          startIcon={startIcon ? <QIcon src={startIcon} width={28} height={28} /> : null}
          endIcon={endIcon ? <QIcon src={endIcon} /> : null}
          classes={{ startIcon: classes.startIcon, endIcon: classes.endIcon }}
          {...rest}
        >
          {fileName ? <span className={classes.fileName}>{fileName}</span> : 'Добавить фото'}
        </Button>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {},
  fileInput: {
    display: 'none',
  },
  fileBtn: {
    justifyContent: 'stretch',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'initial',
    color: theme.palette.black.low,
    borderRadius: 8,
    background: theme.palette.extra.white,
    border: `0.5px solid ${theme.palette.extra.stroke}`,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04) !important',
    padding: '9px 20px 9px 12px',
    '&:hover': {
      background: 'transparent',
      borderColor: theme.palette.black.high,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.black.low,
  },
  endIcon: {
    width: 16,
    height: 16,
    color: theme.palette.black.low,
    marginLeft: 'auto',
    marginRight: 0,
  },
  fileName: {
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.black.high,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}));

export default ImageUploaderButton;
