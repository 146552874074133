import { STATUSES } from '../../../../data/statuses';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import { ClientRecord } from '../../../../modules/role-admin/clients/models/ClientRecord';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { formatRu } from '../../../../utils/formatRu';
import moneyFormat from '../../../../utils/moneyFormat';
import {Box, Typography, makeStyles, Theme, FormLabel} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import QTextField from "../../../../components/UI/QTextField";
import {settingIcon} from "../../../../components/UI/Icons/SvgIcons";

interface IClientRecordItemProps {
  record: ClientRecord;
  onOpenModal: (record: ClientRecord) => void;
  gridFr: string;
  textAlign: 'left' | 'right';
}

const ClientRecordItem: React.FC<IClientRecordItemProps> = props => {
  const { record, onOpenModal, textAlign } = props;
  const classes = useStyles(props);
  const commonClasses = useCommonStyles();
  const { isSM } = useAllMQ();

  let addOffers =  record.addOffers? record.addOffers.map(item => (
      <Typography variant="body2" className={clsx(commonClasses.blackHigh)}>
        {item?.title},  {moneyFormat(item?.price)} ₽
      </Typography>
  )) : null;
  // Renders
  return (
    <div onClick={() => onOpenModal(record)} className={classes.recordWrapper}>
      <Box px={1}>
        <Typography variant="body1" className={clsx(commonClasses.fontWeight400, commonClasses.blackHigh)}>
          {record.companyOffer?.title}, {moneyFormat(record.companyOffer?.price)} ₽
        </Typography>
         {addOffers}
      </Box>
      {!isSM && (
        <Box px={1}>
          <Typography variant="body1" className={clsx(commonClasses.fontWeight400, commonClasses.blackHigh)}>
            {record.companyEmployee?.name}
          </Typography>
        </Box>
      )}
      <Box px={1}>
        {record?.status && (
          <Typography
            variant="body1"
            className={clsx(commonClasses.fontWeight400, commonClasses.blackHigh)}
            align={textAlign}
          >
            {STATUSES[record.status]}
          </Typography>
        )}
      </Box>
      {!isSM && (
        <Box px={1}>
          {record.startDate && (
            <Typography
              variant="body1"
              className={clsx(commonClasses.fontWeight400, commonClasses.blackHigh)}
              align="right"
            >
              {formatRu(record.startDate, 'd MMMM yyyy, HH:mm')}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, IClientRecordItemProps>(theme => ({
  recordWrapper: ({ gridFr }) => ({
    padding: theme.spacing(2, 1),
    width: '100%',
    display: 'grid',
    gridTemplateColumns: gridFr ? gridFr : undefined,
    cursor: 'pointer',
    transition: '.2s all linear',
    '&:hover': {
      backgroundColor: theme.palette.extra.fill,
    },
  }),
}));

export default ClientRecordItem;
