import { Box, Grid, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import QTimePicker from '../../../../components/UI/QTimePicker';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { timeStringToDate } from '../../../../utils/timeStringToDate';
import ResponsiveTooltip from '../../../../components/UI/ResponsiveTooltip';
import { Schedule } from '../../../../modules/role-admin/schedule/models/Schedule';
import { DateKey } from '../../../../modules/role-admin/schedule/types/ScheduleTypes';

interface IScheduleItemProps {
  schedule: Schedule;
  onHandleChangeTime: (date: MaterialUiPickersDate, id: number, key: DateKey) => void;
}

const ScheduleItem: React.FC<IScheduleItemProps> = props => {
  const { schedule, onHandleChangeTime } = props;
  const commonClasses = useCommonStyles();

  const timeStart = schedule.timeStart ? timeStringToDate(schedule.timeStart) : null;
  const timeEnd = schedule.timeEnd ? timeStringToDate(schedule.timeEnd) : null;

  return (
    <Box mb={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
        <Typography variant="body1" className={commonClasses.blackHigh}>
          {schedule.dayName}
        </Typography>
        {schedule.dayCode === 'monday' && (
          <ResponsiveTooltip title="Для установки выходного дня оставьте поля незаполненными" placement="left-end">
            <Box
              width={20}
              height={20}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={commonClasses.blackMedium}
            >
              <ErrorOutlineIcon />
            </Box>
          </ResponsiveTooltip>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QTimePicker
            fullWidth
            label="Открытие"
            value={timeStart}
            onChange={date => onHandleChangeTime(date, schedule.dayNumber as number, 'timeStart')}
          />
        </Grid>
        <Grid item xs={6}>
          <QTimePicker
            fullWidth
            label="Закрытие"
            value={timeEnd}
            onChange={date => onHandleChangeTime(date, schedule.dayNumber as number, 'timeEnd')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScheduleItem;
