import clsx from 'clsx';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import { useCustomTypography } from '../../styles/customTypography';

interface IAlertMessageProps {
  messages: string[] | string;
  staticPos?: boolean;
}

const AlertMessage: React.FC<IAlertMessageProps> = ({ messages, staticPos }) => {
  const classes = useStyles();
  const typography = useCustomTypography();

  if (messages?.length) {
    return (
      <div className={clsx({ [classes.wrap]: !staticPos })}>
        {typeof messages === 'string' ? (
          <div className={classes.item}>
            <Typography className={typography.text2}>{messages}</Typography>
          </div>
        ) : (
          messages.map((item, index) => (
            <div key={index} className={classes.item}>
              <Typography className={typography.text2}>{item}</Typography>
            </div>
          ))
        )}
      </div>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translateY(100%)',
  },
  item: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

export default AlertMessage;
