import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Box, Button, DialogProps, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

import Loader from '../../../../../components/UI/Loader';
import QModal from '../../../../../components/UI/QModal';
import { formatRu } from '../../../../../utils/formatRu';
import QSwitch from '../../../../../components/UI/QSwitch';
import { stringToDate } from '../../../../../utils/stringToDate';
import QTextField from '../../../../../components/UI/QTextField';
import { useCommonStyles } from '../../../../../styles/commonStyles';
import { useRootStore } from '../../../../../base/hooks/useRootStore';
import QAutocomplete from '../../../../../components/UI/QAutocomplete';
import { useCustomTypography } from '../../../../../styles/customTypography';
import { RussianPhoneMaskInput } from '../../../../../components/UI/RussianPhoneMaskInput';
import { ISlotModalValues } from '../../../../../modules/role-admin/slots/types/SlotTypes';
import { settingIcon, smileIcon, timeIcon } from '../../../../../components/UI/Icons/SvgIcons';
import {Offer} from "../../../../../modules/offer/models/Offer";

interface ISlotModalProps extends DialogProps {}

const SlotModal: React.FC<ISlotModalProps> = observer(props => {
  const { slotsStore, recordsStore } = useRootStore();
  const [recordBreak, setRecordBreak] = useState(false);

  const [values, setValues] = useState<ISlotModalValues>({
    offer: null,
    clientName: '',
    clientPhone: '',
  });
  const isValid = !!values.offer && !!values.clientName.length && !!values.clientPhone.length;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    if (slotsStore.modalState.slot.currentId && !slotsStore.slot) {
      slotsStore.getSlot(slotsStore.modalState.slot.currentId);
    }

    return () => {
      slotsStore.clearSlot();
      setValues({
        offer: null,
        clientName: '',
        clientPhone: '',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsStore.modalState.slot]);

  useEffect(() => {
    if (slotsStore.slot) {
      setRecordBreak(slotsStore.slot.type === 'break');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsStore.slot]);

  // Handlers
  const handleToggleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name) {
      if (slotsStore.modalState.slot.currentId) {
        setRecordBreak(checked);

        slotsStore.setBreak(slotsStore.modalState.slot.currentId);
      }
    }
  };

  const handleChangeAutoComplete = (e: React.ChangeEvent<{}>, value: any, name: keyof typeof values) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (slotsStore.slot) {
      recordsStore.createRecord(values, slotsStore.slot);
    }
  };

  // Renders
  const renderLoading = () => {
    if (slotsStore.breakLoading || recordsStore.createRecordLoading) {
      return <Loader isAbsolute color="secondary" />;
    }
  };

  const renderContent = () => {
    if (slotsStore.slot) {
      const { startDate, companyEmployee } = slotsStore.slot;

      const date = startDate ? stringToDate(startDate) : null;
      const fullDate = date && formatRu(date, 'd MMMM, HH:mm');
      return (
        <form onSubmit={handleSubmit}>
          {renderLoading()}
          <Box mb={3.5} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h3" className={commonClasses.blackHigh}>
              Запись
            </Typography>
          </Box>
          <Box mb={3.5}>
            <QSwitch name="break" checked={recordBreak} onChange={handleToggleChecked} label="Установить перерыв" />
          </Box>
          <div className={clsx(classes.content, { disabled: recordBreak })}>
            <Box mb={3}>
              <QAutocomplete
                value={values.offer}
                onChange={(e: React.ChangeEvent<{}>, value: any) => handleChangeAutoComplete(e, value, 'offer')}
                options={slotsStore.mappedOffers}
                groupBy={(option) => option?.offerTitle }
                getOptionLabel={option => `${option?.title && option?.title}, ${option?.price && option.price} ₽`}
                getOptionSelected={option => option?.id === values.offer?.id}
                disablePortal
                noOptionsText="Нет подходящих вариантов"
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Выберите вид услуги"
                      name="offer"
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      {...params}
                    />
                  );
                }}
                renderOption={option => (
                  <Box>
                    <Box mb={0.5}>
                      <Typography className={clsx(typography.text2, commonClasses.blackHigh)}>
                        {option?.title}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={commonClasses.blackMedium}>
                      <Box mr={2}>
                        <Typography variant="caption">{option?.price} ₽</Typography>
                      </Box>
                      <div>
                        <Typography variant="caption">{option?.duration}</Typography>
                      </div>
                    </Box>
                  </Box>
                )}
              />
            </Box>
            <Box mb={3}>
              <QTextField
                label="Дата и время"
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: timeIcon, color: 'secondary' }}
                value={fullDate}
              />
            </Box>
            <Box mb={3}>
              <QTextField
                label="Сотрудник"
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: smileIcon, color: 'secondary' }}
                value={companyEmployee?.name}
              />
            </Box>
            <Box mb={3} maxWidth={328} mx="auto">
              <Divider />
            </Box>
            <Box mb={3}>
              <QTextField label="Имя клиента" value={values.clientName} name="clientName" onChange={handleChange} />
            </Box>
            <Box mb={4}>
              <QTextField
                value={values.clientPhone}
                label="Номер телефона"
                name="clientPhone"
                InputProps={{ inputComponent: RussianPhoneMaskInput }}
                onChange={handleChange}
              />
            </Box>
            <Button disabled={!isValid} type="submit" variant="contained" color="secondary" fullWidth>
              Сохранить запись
            </Button>
          </div>
        </form>
      );
    }
  };

  return (
    <>
      <QModal {...props}>{slotsStore.loading ? <Loader minHeight={666} color="secondary" /> : renderContent()}</QModal>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  editIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    marginRight: 48,
    marginTop: -4,
    padding: 0,
    color: theme.palette.black.medium,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  label: {
    width: '100%',
    height: '100%',
  },
  content: {
    transition: transitions.create('opacity'),
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
}));

export default SlotModal;
