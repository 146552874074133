import AbstractDto from '../../../../base/AbstractDto';

export default class EditSlotDto extends AbstractDto {
  startDate: string = '';
  endDate: string = '';

  constructor(props: any) {
    super();
    this.load(props);
  }
}
