import { isSameDay } from 'date-fns';

import { Chat } from './models/Chat';
import { Message } from './models/Message';
import { ChatFactory } from './ChatFactory';
import { formatRu } from '../../utils/formatRu';
import { IChatReqBody } from './types/ChatTypes';
import ChatApiRepository from './ChatApiRepository';
import { isImageType } from '../../utils/fileUtils';
import { getSubArray } from '../../utils/getSubArray';
import { stringToDate } from '../../utils/stringToDate';
import { ChatWithCreatorUser } from './models/ChatWithCreatorUser';
import Pagination from '../../base/modules/pagination/models/Pagination';
import ChatPagination from '../../base/modules/pagination/models/ChatPagination';
import { IChatPaginationReq, IPaginationReq } from '../../base/modules/pagination/PaginationTypes';

export default class ChatService {
  chatApi: ChatApiRepository;
  chatFactory: ChatFactory;

  constructor() {
    this.chatApi = new ChatApiRepository();
    this.chatFactory = new ChatFactory();
  }

  writeSupport = async (values: IChatReqBody): Promise<Chat> => {
    const { data } = await this.chatApi.writeSupport(values);
    return this.chatFactory.create<Chat>(Chat, data.data);
  };

  getSupportChat = async (): Promise<Chat> => {
    const { data } = await this.chatApi.getSupportChat();
    return this.chatFactory.create<Chat>(Chat, data.data);
  };

  getChatDetails = async (chatId: number): Promise<ChatWithCreatorUser> => {
    const { data } = await this.chatApi.getChatDetails(chatId);
    return this.chatFactory.create<ChatWithCreatorUser>(ChatWithCreatorUser, data.data);
  };

  addMessage = async (chatId: number, values: any): Promise<Message> => {
    const { data } = await this.chatApi.addMessage(chatId, values);
    return this.chatFactory.create<Message>(Message, data.data);
  };

  getChatMessageHistory = async (
    chatId: number,
    paginationReq: IChatPaginationReq,
  ): Promise<{ messages: Message[]; pagination: ChatPagination }> => {
    const { data } = await this.chatApi.getChatMessageHistory(chatId, paginationReq);
    const messages = this.chatFactory.createList<Message>(Message, data.data.messages);
    const pagination = this.chatFactory.create<ChatPagination>(ChatPagination, data.data.pagination);

    return { messages, pagination };
  };

  getChatList = async (
    paginationReq: IPaginationReq,
  ): Promise<{ items: ChatWithCreatorUser[]; pagination: Pagination }> => {
    const { data } = await this.chatApi.getChatList(paginationReq);
    const items = this.chatFactory.createList<ChatWithCreatorUser>(ChatWithCreatorUser, data.data.items);
    const pagination = this.chatFactory.create<Pagination>(Pagination, data.data.pagination);

    return { items, pagination };
  };

  getChatWithClientByRecordId = async (recordId: number): Promise<Chat> => {
    const { data } = await this.chatApi.getChatWithClientByRecordId(recordId);
    return this.chatFactory.create<Chat>(Chat, data.data);
  };

  getMessagesWithDate = (messagesList: Message[], totalCount: number | null): Message[] => {
    return messagesList.map((message, index, arr) => {
      // first element (array left)
      if (totalCount && index === 0 && totalCount === messagesList.length && message?.createdAt) {
        return this.chatFactory.create<Message>(Message, {
          ...message,
          date: formatRu(message.createdAt, 'd MMMM'),
        });
      }

      const prevElement = arr[index - 1]?.createdAt?.length ? arr[index - 1]?.createdAt : null;

      if (prevElement && message?.createdAt?.length) {
        const prevDate = stringToDate(prevElement);
        const currentDate = stringToDate(message.createdAt);

        return !isSameDay(prevDate, currentDate)
          ? this.chatFactory.create<Message>(Message, { ...message, date: formatRu(message.createdAt, 'd MMMM') })
          : message;
      }

      return message;
    });
  };

  getMessagesImages = (messages: Message[]) => {
    return messages.flatMap(item => {
      const attachFiles = item.attachFiles
        .filter(files => isImageType(files.file?.extension ?? ''))
        .map(item => ({
          id: item.id,
          image: item.file?.fullUrl,
        }));

      return [...getSubArray(attachFiles)].reverse().flat();
    });
  };

  getFilteredItems = (currentArray: any[], responseArray: any[]) => {
    const responseMessageIds = currentArray.map(item => String(item.id));
    const filteredResponseMessages = responseArray.filter(item => {
      return !responseMessageIds.includes(String(item.id));
    });

    return [...currentArray, ...filteredResponseMessages];
  };

  getUniqItems = (array: ChatWithCreatorUser[]) => {
    return array.filter((chatListItem, index, self) => self.findIndex(item => item.id === chatListItem.id) === index);
  };
}
