import format from 'date-fns/format';

import { MainFactory } from './MainFactory';
import MainApiRepository from './MainApiRepository';

export default class MainService {
  mainApi: MainApiRepository;
  mainFactory: MainFactory;

  constructor() {
    this.mainApi = new MainApiRepository();
    this.mainFactory = new MainFactory();
  }

  getFormattedData = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
  };
}
