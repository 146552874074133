import { useRootStore } from '../../../../base/hooks/useRootStore';
import Loader from '../../../../components/UI/Loader';
import QAutocomplete from '../../../../components/UI/QAutocomplete';
import QModal from '../../../../components/UI/QModal';
import QTextField from '../../../../components/UI/QTextField';
import { RussianPhoneMaskInput } from '../../../../components/UI/RussianPhoneMaskInput';
import { EmailMaskInput } from '../../../../components/UI/TextMaskedInputs';
import { OfferCategory } from '../../../../modules/role-admin/company/models/OfferCategory';
import { Timezone } from '../../../../modules/role-admin/company/models/Timezone';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { Box, Grid, DialogProps, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

interface ICompanyInfoModalProps extends DialogProps {}

const CompanyInfoModal: React.FC<ICompanyInfoModalProps> = observer(props => {
  const { companyStore, userStore } = useRootStore();
  const { infoModalValues, setInfoModalValues } = companyStore;

  const classes = useStyles();
  const commonStyles = useCommonStyles();

  //Effects
  useEffect(() => {
    if (companyStore.isShowInfoModal) {
      companyStore.getOfferCategories();
      companyStore.getTimezones();
      companyStore.getInfoFromCard();
    }
  }, [companyStore, companyStore.isShowInfoModal]);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfoModalValues({ ...infoModalValues, [name]: value });
  };

  const handleChangeCategory = (e: React.ChangeEvent<{}>, value: OfferCategory) => {
    setInfoModalValues({ ...infoModalValues, offerCategory: value });
  };

  const handleChangeTimezone = (e: React.ChangeEvent<{}>, value: Timezone) => {
    setInfoModalValues({ ...infoModalValues, timezone: value.id });
  };

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }
    companyStore.setIsShowInfoModal(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    companyStore.updateCompanyInfo(userStore.userInfo?.companyId);
  };

  // Renders
  return (
    <QModal modalClassName={classes.modal} onClose={handleClose} {...props}>
      {(companyStore.timezonesLoading || companyStore.offerCategoriesLoading || companyStore.infoModalLoading) && (
        <Loader isAbsolute color="secondary" />
      )}
      <form onSubmit={handleSubmit}>
        <Box mb={3.5} maxWidth="90%">
          <Typography variant="h3">Карточка компании</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant={'body1'}>Основное</Typography>
        </Box>
        <Box mb={3}>
          <QTextField
            name="title"
            value={infoModalValues.title}
            label="Название компании"
            placeholder=""
            onChange={handleChange}
            error={companyStore.infoModalError?.title?.length}
            helperText={companyStore.infoModalError?.title?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QAutocomplete
            label="Категория услуг"
            value={companyStore.findCategoryById(infoModalValues?.offerCategory?.id || null)}
            onChange={handleChangeCategory}
            disablePortal
            disableClearable
            className={classes.autoComplete}
            options={companyStore.offerCategories}
            getOptionLabel={option => option?.title}
            getOptionSelected={(option, value) => option?.id === value?.id}
            classes={{ tag: commonStyles.tag }}
            noOptionsText="Нет подходящих вариантов"
            renderInput={params => {
              return <QTextField name="mainCategoryTitle" {...params} />;
            }}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            name="address"
            value={infoModalValues.address}
            label="Адрес"
            placeholder=""
            onChange={handleChange}
            error={companyStore.infoModalError?.address?.length}
            helperText={companyStore.infoModalError?.address?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            name="description"
            value={infoModalValues.description}
            label="Описание"
            multiline
            rows={5}
            placeholder="Введите текст"
            onChange={handleChange}
            error={companyStore.infoModalError?.description?.length}
            helperText={companyStore.infoModalError?.description?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <Typography variant={'body1'}>Контакты</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant={'body2'}>Контактное лицо</Typography>
        </Box>
        <Box mb={3}>
          <QTextField
            name="contactPerson"
            value={infoModalValues.contactPerson}
            label="ФИО"
            placeholder=""
            onChange={handleChange}
            error={companyStore.infoModalError?.contactPerson?.length}
            helperText={companyStore.infoModalError?.contactPerson?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="contactPersonPhone"
                value={infoModalValues.contactPersonPhone}
                label="Телефон"
                InputProps={{ inputComponent: RussianPhoneMaskInput }}
                placeholder=""
                onChange={handleChange}
                error={companyStore.infoModalError?.contactPersonPhone?.length}
                helperText={companyStore.infoModalError?.contactPersonPhone?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="contactPersonEmail"
                value={infoModalValues.contactPersonEmail}
                label="E-mail"
                InputProps={{ inputComponent: EmailMaskInput }}
                placeholder=""
                onChange={handleChange}
                error={companyStore.infoModalError?.contactPersonEmail?.length}
                helperText={companyStore.infoModalError?.contactPersonEmail?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={3}>
          <Typography variant={'body2'}>Общие контакты компании</Typography>
        </Box>
        <Box pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="phone"
                value={infoModalValues.phone}
                label="Телефон"
                InputProps={{ inputComponent: RussianPhoneMaskInput }}
                placeholder=""
                onChange={handleChange}
                error={companyStore.infoModalError?.phone?.length}
                helperText={companyStore.infoModalError?.phone?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="email"
                value={infoModalValues.email}
                label="E-mail"
                InputProps={{ inputComponent: EmailMaskInput }}
                placeholder=""
                onChange={handleChange}
                error={companyStore.infoModalError?.email?.length}
                helperText={companyStore.infoModalError?.email?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={4}>
          <QAutocomplete
            label="Таймзона"
            value={companyStore.findTimezoneById(infoModalValues.timezone)}
            onChange={handleChangeTimezone}
            disablePortal
            disableClearable
            className={classes.autoComplete}
            options={companyStore.timezoneList || []}
            getOptionLabel={option => option?.title}
            getOptionSelected={(option, value) => option?.id === value?.id}
            classes={{ tag: commonStyles.tag }}
            noOptionsText="Нет подходящих вариантов"
            renderInput={params => {
              return <QTextField name="mainCategoryTitle" {...params} />;
            }}
          />
        </Box>
        <Button fullWidth color="secondary" variant="contained" type="submit">
          Отправить на утверждение
        </Button>
      </form>
    </QModal>
  );
});

const useStyles = makeStyles(() => ({
  autoComplete: {
    '& .MuiInputBase-root': {
      paddingLeft: 16,
    },
  },
  modal: {
    minWidth: 300,
    width: '100%',
    maxWidth: 600,
    position: 'relative',
  },
}));

export default CompanyInfoModal;
