import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { ISMSValidateValues, SMSType } from './types/AuthTypes';

export default class AuthApiRepository extends AbstractApiRepository {
  sendSMS = (type: SMSType, phone: string) => {
    return this.post({ url: `/company/auth/send-sms-code`, data: { phone, type } });
  };

  validateSMSCode = ({ token, code }: ISMSValidateValues) => {
    return this.post({ url: `/company/auth/validation-sms-code`, data: { token, code } });
  };

  logout = () => {
    return this.post({ url: `/company/auth/logout` });
  };
}
