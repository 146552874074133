import clsx from 'clsx';
import { makeStyles, Theme, IconButton, Dialog, DialogProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IQModalProps extends DialogProps {
  modalClassName?: string;
}

const QModal: React.FC<IQModalProps> = props => {
  const classes = useStyles();
  const { children, className, modalClassName, ...rest } = props;

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      PaperProps={{ className: clsx(classes.modal, modalClassName) }}
      scroll="body"
      disableRestoreFocus
      className={clsx(classes.modalWrap, className)}
      {...rest}
    >
      <IconButton
        size="medium"
        onClick={() => handleClose(null, null)}
        className={clsx(classes.closeButton, 'close-button')}
        classes={{ label: classes.label }}
      >
        <CloseIcon />
      </IconButton>

      {children}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalWrap: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  modal: {
    padding: theme.spacing(2, 3, 4),
    maxWidth: 434,
    minHeight: 280,
    width: '100%',
    outline: 'none',
    borderRadius: 8,
    margin: theme.spacing(4),
    background: theme.palette.extra.white,
    border: `1px solid ${theme.palette.extra.stroke}`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
      maxWidth: 'calc(100% - 16px) !important',
      padding: theme.spacing(2, 2, 4),
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    padding: theme.spacing(0),
    color: theme.palette.black.low,
    zIndex: 1,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  label: {
    height: '100%',
  },
}));

export default QModal;
