import clsx from 'clsx';
import { Box, IconButton, Theme, Typography, makeStyles, Avatar } from '@material-ui/core';

import QIcon from '../../../../components/UI/Icons/QIcon';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { useCustomTypography } from '../../../../styles/customTypography';
import { editIcon, trashIcon } from '../../../../components/UI/Icons/SvgIcons';
import { Employee } from '../../../../modules/role-admin/employee/models/Employee';

interface IEmployeeItemProps {
  data: Employee;
  onHandleDelete: (id: number) => void;
  onHandleEdit: (id: number) => void;
}

const EmployeeItem: React.FC<IEmployeeItemProps> = props => {
  const { data, onHandleDelete, onHandleEdit } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box mr={1.5} className={classes.imgWrap}>
          <Avatar className={classes.img} src={data.photo?.fullUrl} />
        </Box>
        <div>
          <Box mb={0.25}>
            <Typography className={clsx(typography.text2, commonClasses.blackHigh)}>{data.name}</Typography>
          </Box>
          <Typography variant="caption" className={commonClasses.blackMedium}>
            {data.specialization}
          </Typography>
        </div>
      </Box>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <IconButton className={clsx(classes.iconBtn, 'edit')} color="secondary" onClick={() => onHandleEdit(data.id)}>
            <QIcon width={18} height={18} src={editIcon} />
          </IconButton>
        </Box>
        <IconButton
          className={clsx(classes.iconBtn, 'delete')}
          color="secondary"
          onClick={() => onHandleDelete(data.id)}
        >
          <QIcon width={18} height={18} src={trashIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    width: 30,
    height: 30,
    '&.edit': {
      color: theme.palette.qsecondary['900'],
    },
    '&.delete': {
      color: theme.palette.secondary.main,
    },
  },
  imgWrap: {
    width: 48,
    height: 48,
    minWidth: 48,
    minHeight: 48,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default EmployeeItem;
