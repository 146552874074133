import { makeAutoObservable, runInAction } from 'mobx';

import { User } from './models/User';
import UserService from './UserService';
// import { INotification } from '../push/types/PushTypes';
import { UnreadNotificationsCountSetType } from './types/UserTypes';

export class UserStore {
  loading = false;
  userGetted = false;

  private userService: UserService;

  userInfo: User | null = null;
  unreadNotificationsCount: number = 0;

  constructor() {
    makeAutoObservable(this);
    this.userService = new UserService();
  }

  getUserInfo = () => {
    this.setLoading(true);
    this.userService
      .getUserInfo()
      .then(userInfo => {
        runInAction(() => {
          this.userInfo = userInfo;
          this.unreadNotificationsCount = userInfo.unreadNotificationsCount;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setTimezone = (timezone: string) => {
    this.userService.setTimezone(timezone);
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setUserGetted = (value: boolean) => {
    this.userGetted = value;
  };

  setUnreadNotificationsCount = (value: number, type: UnreadNotificationsCountSetType) => {
    switch (type) {
      case UnreadNotificationsCountSetType.CHANGE:
        this.unreadNotificationsCount = value;
        break;
      case UnreadNotificationsCountSetType.INCREMENT:
        this.unreadNotificationsCount += value;
        break;
    }
  };

  clearUserInfo = () => {
    this.userInfo = null;
    this.userGetted = false;
  };
}
