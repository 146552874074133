import { makeAutoObservable } from 'mobx';

import LoginService from './LoginService';
import { AuthStore } from '../../AuthStore';
import UserService from '../../../user/UserService';
import { routes } from '../../../../screens/routes';
import { IUpdatePassword } from '../../../user/types/UserTypes';
import { ILoginData, IResetPasswordData } from './types/LoginTypes';
import RouteService from '../../../../base/routes/service/RouteService';
import { rootStore } from '../../../../base/RootStore';
import { addRussianCountryPhoneCode } from '../../../../utils/addRussianCountryPhoneCode';

export class LoginStore {
  loading = false;
  errorMessages: any = {};

  private loginService: LoginService;
  private userService: UserService;
  private authStore: AuthStore;

  constructor(authStore: AuthStore) {
    makeAutoObservable(this);
    this.loginService = new LoginService();
    this.userService = new UserService();
    this.authStore = authStore;
  }

  login = (phone: string, password: string) => {
    this.setLoading(true);

    const data: ILoginData = {
      phone: addRussianCountryPhoneCode(phone),
      password,
      deviceId: 'web',
      deviceType: 'web',
    };

    this.loginService
      .login(data)
      .then(({ accessToken }) => {
        if (accessToken) {
          this.authStore.tokenStore.setToken(accessToken);
          this.authStore.setIsAuth(true);
          RouteService.push(routes.MainScreen.path);
          rootStore.pushStore.register();
        }
        this.resetStore();
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);

          if (errors.hasOwnProperty('code')) {
            this.authStore.setSMSCode('');
          }
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setLoading(false));
  };

  resetPassword = (password: string, passwordConfirm: string) => {
    this.setLoading(true);

    const data: IResetPasswordData = {
      token: this.authStore.smsToken,
      code: this.authStore.smsCode,
      deviceId: 'web',
      deviceType: 'web',
      password,
      passwordConfirm,
    };

    this.loginService
      .resetPassword(data)
      .then(({ accessToken }) => {
        if (accessToken) {
          this.authStore.tokenStore.setToken(accessToken);
          this.authStore.setIsAuth(true);
          RouteService.push(routes.MainScreen.path);
        }
        this.resetStore();
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);

          if (errors.hasOwnProperty('code')) {
            this.authStore.setSMSCode('');
          }
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setLoading(false));
  };

  updatePassword = (password: string, passwordConfirm: string) => {
    this.setLoading(true);

    const data: IUpdatePassword = {
      token: this.authStore.smsToken,
      code: this.authStore.smsCode,
      password,
      passwordConfirm,
    };

    this.userService
      .updatePassword(data)
      .then(() => {
        RouteService.push(routes.MainScreen.path);
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setErrors = (errors: Object) => {
    this.errorMessages = errors;
  };

  removeError = (fieldName: string) => {
    delete this.errorMessages[fieldName];
  };

  resetLogin = () => {
    this.loading = false;
    this.errorMessages = {};

    this.authStore.smsToken = '';
    this.authStore.smsCode = '';
  };

  resetStore = () => {
    this.resetLogin();
  };
}
