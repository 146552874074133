import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

import TextButton from '../../../components/UI/TextButton';
import { useCommonStyles } from '../../../styles/commonStyles';
import { useCustomTypography } from '../../../styles/customTypography';

interface ILinkProps extends LinkProps {
  text: string;
}

interface ILoginCardProps {
  title: string;
  subTitle?: string;
  link?: ILinkProps;
  phone?: string;
}

const LoginCard: React.FC<ILoginCardProps> = props => {
  const { title, subTitle, link, phone, children } = props;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  const renderBackButton = () => {
    if (link) {
      const { text, ...linkRest } = link;

      return (
        <Box mb={{ xxs: 2, sm: 4 }} alignSelf="flex-start">
          <Link {...linkRest} className="router-link">
            <TextButton className={classes.backButton} startIcon={<ArrowBackIcon />}>
              {text}
            </TextButton>
          </Link>
        </Box>
      );
    }

    return <></>;
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.cardWrap}>
          {renderBackButton()}
          <Paper className={classes.card}>
            <Box mb={subTitle ? 1 : 5}>
              <Typography className={commonClasses.blackHigh} variant="h2">
                {title}
              </Typography>
            </Box>
            {subTitle && (
              <Box mb={phone ? 0 : 5}>
                <Typography className={clsx(typography.text2, commonClasses.blackMedium)}>{subTitle}</Typography>
              </Box>
            )}
            {phone && (
              <Box mb={5}>
                <Typography className={classes.phone} color="secondary" variant="body2">
                  {phone}
                </Typography>
              </Box>
            )}
            <Box>{children}</Box>
          </Paper>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'fixed',
    inset: 0,
    zIndex: 2,
    background: theme.palette.extra.bg,
  },
  container: {
    height: '100%',
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::after': {
      content: '""',
      width: '0',
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  cardWrap: {
    maxWidth: 554,
    margin: '32px',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)',
      margin: '32px auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 16px)',
    },
  },
  card: {
    background: theme.palette.extra.fill,
    border: `1px solid ${theme.palette.extra.stroke}`,
    borderRadius: 8,
    padding: theme.spacing(7, 14, 6),
    width: '100%',
    textAlign: 'center',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 8, 6),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2, 4),
    },
  },
  backButton: {
    color: theme.palette.black.medium,
    fontWeight: 500,
  },
  phone: {},
}));

export default LoginCard;
