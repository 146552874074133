import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from '../../base/RootStore';
import { ReqType } from './types/NotificationTypes';
import { NotificationModel } from './models/NotificationModel';
import NotificationsService from './NotificationsService';
import { UnreadNotificationsCountSetType } from '../user/types/UserTypes';

export class NotificationsStore {
  static readonly LIMIT: number = 50;

  loading = false;
  hotLoading = false;

  notificationsList: NotificationModel[] = [];
  unreadNotificationsCount: number = 0;
  notificationAnchorEl: null | HTMLElement = null;

  limit: number = NotificationsStore.LIMIT;
  totalCount: number | null = null;
  offset: number = 0;

  private notificationsService: NotificationsService;

  constructor() {
    makeAutoObservable(this);
    this.notificationsService = new NotificationsService();
  }

  get isEndOfList() {
    const isEnd = this.totalCount && this.notificationsList.length >= this.totalCount;
    return !!isEnd;
  }

  get notificationsWithDate() {
    return this.notificationsService.getNotificationsWithDate(this.notificationsList, this.totalCount);
  }

  getNotificationsList = (type: ReqType, isLoadMore = false, limit?: number) => {
    if (this.isEndOfList && type !== ReqType.TOP) {
      return;
    }

    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
    }

    this.notificationsService
      .getNotificationsList(this.getPaginationData(type, limit))
      .then(({ items, pagination, unreadNotificationsCount }) => {
        runInAction(() => {
          this.totalCount = pagination.meta.totalCount;
          this.setOffset(type, pagination.meta.currentOffset);
          this.setNotifications(type, items);

          this.unreadNotificationsCount = unreadNotificationsCount;
          rootStore.userStore.setUnreadNotificationsCount(
            unreadNotificationsCount,
            UnreadNotificationsCountSetType.CHANGE,
          );
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  updateNotificationsList = () => {
    const isOpen = Boolean(this.notificationAnchorEl);

    if (isOpen) {
      this.getNotificationsList(ReqType.TOP, true, 1);
    } else {
      rootStore.userStore.setUnreadNotificationsCount(1, UnreadNotificationsCountSetType.INCREMENT);
    }

    this.offset += 1;
  };

  getPaginationData = (type: ReqType, limit?: number) => {
    const topLimit = limit ? limit : rootStore.userStore.unreadNotificationsCount;

    switch (type) {
      case ReqType.TOP:
        return { limit: topLimit, offset: 0 };
      case ReqType.INIT:
        return { limit: this.limit, offset: this.offset };
      case ReqType.BOTTOM:
        return { limit: this.limit, offset: this.offset };
    }
  };

  setNotifications = (type: ReqType, notificationsList: NotificationModel[]) => {
    switch (type) {
      case ReqType.TOP:
        if (this.notificationsList.length) {
          this.notificationsList = this.notificationsService.getFilteredItems(
            this.notificationsList,
            notificationsList,
          );
        }
        break;
      case ReqType.INIT:
        this.notificationsList = notificationsList;
        break;
      case ReqType.BOTTOM:
        this.notificationsList = [...this.notificationsList, ...notificationsList];
        break;
    }
  };

  setOffset = (type: ReqType, currentOffset: number | null) => {
    switch (type) {
      case ReqType.INIT:
      case ReqType.BOTTOM:
        if (currentOffset) {
          this.offset = currentOffset;
        }
        break;
    }
  };

  setNotificationAnchorEl = (value: null | HTMLElement) => {
    this.notificationAnchorEl = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setHotLoading = (value: boolean) => {
    this.hotLoading = value;
  };

  clearStore = () => {
    this.loading = false;
    this.hotLoading = false;

    this.notificationsList = [];
    this.unreadNotificationsCount = 0;
    this.notificationAnchorEl = null;

    this.limit = NotificationsStore.LIMIT;
    this.totalCount = null;
    this.offset = 0;
  };
}
