import { rootStore } from '../../base/RootStore';
import { ActionType } from '../../base/types/BaseTypes';
import OfferService from './OfferService';
import { Offer } from './models/Offer';
import { OfferWithCategory } from './models/OfferWithCategory';
import { IOffersGuideWithCategoryTitle, IOfferValues } from './types/OfferTypes';
import { makeAutoObservable, runInAction } from 'mobx';

export const initialValues: IOfferValues = {
  offer: null,
  subtitle: '',
  price: '',
  duration: '',
};

export class OfferStore {
  loading = false;
  deleteLoading = false;
  offersListLoading = false;
  offerModalLoading = false;

  deleteModal = false;
  offerModal = false;

  offset: number = 0;

  errorMessages: any = {};

  employeeOfferList: Offer[] = [];
  offersList: OfferWithCategory[] = [];
  companyOffersList: Offer[] = [];
  companyOffer: Offer | null = null;
  currentOfferId: null | number = null;
  values: IOfferValues = initialValues;
  action: ActionType = null;

  private offerService: OfferService;

  constructor() {
    makeAutoObservable(this);
    this.offerService = new OfferService();
  }

  get mappedEmployeeOfferList() {
    return this.offerService.getListWithConvertedDuration(this.employeeOfferList);
  }

  get mappedCompanyOffersList() {
    return this.offerService.getListWithConvertedDuration(this.companyOffersList);
  }

  get hashMapCompanyOffersList() {
    return this.offerService.getHashMapCompanyOffersList(this.companyOffersList);
  }

  get groupedOfferList(): IOffersGuideWithCategoryTitle[] {
    return this.offersList
      .reduce<any[]>((acc, item) => {
        const items: IOffersGuideWithCategoryTitle[] = item.offers.map(offersGuiede => ({
          ...offersGuiede,
          categoryTitle: item.category?.title ?? '',
        }));

        acc.push(items);
        return acc;
      }, [])
      .flat();
  }

  getEmployeeOfferList = (companyEmployeeId: number[]) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.offerService
      .getEmployeeOfferList(companyId, companyEmployeeId)
      .then(employeeOfferList => {
        runInAction(() => {
          this.employeeOfferList = employeeOfferList;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getOffersList = () => {
    this.setOffersListLoading(true);

    this.offerService
      .getOffersList({ limit: OfferService.LIMIT, offset: this.offset })
      .then(({ items }) => {
        runInAction(() => {
          this.offersList = items;
        });
      })
      .catch(() => {})
      .finally(() => this.setOffersListLoading(false));
  };

  getCompanyOffersList = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.offerService
      .getCompanyOffersList(companyId)
      .then(companyOffersList => {
        runInAction(() => {
          this.companyOffersList = companyOffersList;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getCompanyOffer = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setOfferModalLoading(true);

    this.offerService
      .getCompanyOffer(companyId, this.currentOfferId as number)
      .then(companyOffer => {
        runInAction(() => {
          this.companyOffer = companyOffer;

          const price = companyOffer.price ? String(companyOffer.price) : '';
          const duration = companyOffer.duration ? String(companyOffer.duration) : '';
          const subtitle = companyOffer.subtitle ? String(companyOffer.subtitle) : '';

          this.setValues({ ...this.values, price, duration, subtitle });
        });
      })
      .catch(() => {})
      .finally(() => this.setOfferModalLoading(false));
  };

  addOffer = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setOfferModalLoading(true);

    this.offerService
      .addOffer(companyId, this.values)
      .then(companyOffersList => {
        runInAction(() => {
          this.companyOffersList = [...this.companyOffersList, companyOffersList];
          this.setOfferModal(false);
          this.setValues(initialValues);
          this.setAction(null);
          this.setErrors({});
        });
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setOfferModalLoading(false));
  };

  updateOffer = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setOfferModalLoading(true);

    this.offerService
      .updateOffer(companyId, this.currentOfferId as number, this.values)
      .then(() => {
        runInAction(() => {
          this.getCompanyOffersList();
          this.setOfferModal(false);
          this.setValues(initialValues);
          this.setAction(null);
          this.setErrors({});
        });
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setOfferModalLoading(false));
  };

  deleteOffer = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setDeleteLoading(true);

    this.offerService
      .deleteOffer(companyId, this.currentOfferId as number)
      .then(() => {
        this.setDeleteModal(false);
        this.getCompanyOffersList();
        this.setAction(null);
      })
      .catch(() => {})
      .finally(() => this.setDeleteLoading(false));
  };

  setValues = (values: IOfferValues) => {
    this.values = values;
  };

  setCurrentOfferId = (id: number | null) => {
    this.currentOfferId = id;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setDeleteLoading = (value: boolean) => {
    this.deleteLoading = value;
  };

  setOffersListLoading = (value: boolean) => {
    this.offersListLoading = value;
  };

  setOfferModalLoading = (value: boolean) => {
    this.offerModalLoading = value;
  };

  setDeleteModal = (value: boolean) => {
    this.deleteModal = value;
  };

  setOfferModal = (value: boolean) => {
    this.offerModal = value;
  };

  setOffset = (offset: number) => {
    this.offset += offset;
  };

  setAction = (action: ActionType) => {
    this.action = action;
  };

  setErrors = (errors: Object) => {
    this.errorMessages = errors;
  };

  clearEmployeeOfferList = () => {
    this.employeeOfferList = [];
  };

  clearCompanyOffersList = () => {
    this.companyOffersList = [];
  };

  clearOffersList = () => {
    this.offersList = [];
  };
}
