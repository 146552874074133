import { Link } from 'react-router-dom';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';

import notFoundImg from '../../assets/404.svg';

const NotFoundScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.cardWrap}>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" py={8}>
            <div className={classes.imgWrap}>
              <img src={notFoundImg} alt="" />
            </div>
            <Box mb={7}>
              <Typography align="center" className={classes.title} variant="h2">
                Страница не найдена :(
              </Typography>
            </Box>
            <Box maxWidth={328} width="100%">
              <Link to="/" className="router-link">
                <Button fullWidth variant="contained" color="secondary">
                  На главную
                </Button>
              </Link>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'fixed',
    inset: 0,
    zIndex: 10,
    background: theme.palette.extra.bg,
    fontSize: 16,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  container: {
    height: '100%',
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::after': {
      content: '""',
      width: '0',
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  cardWrap: {
    maxWidth: 500,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '2.5em',
    lineHeight: '120%',
  },
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26em',
    height: '17.25em',
    '& img': {
      width: '100%',
      heigth: '100%',
      objectFit: 'contain',
    },
  },
}));

export default NotFoundScreen;
