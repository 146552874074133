import AbstractModel from '../../../../base/AbstractModel';
import { IImgData } from '../../../../base/types/BaseTypes';
import { RoleKeys } from '../../../../base/routes/types/RouteTypes';
import { Offer } from '../../../offer/models/Offer';

export class Employee extends AbstractModel {
  id: number = 0;
  name: string = '';
  photo: IImgData | null = null;
  role: RoleKeys | null = null;
  employeeOffers: Offer[] = [];
  specialization: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
