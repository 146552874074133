import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import CreateSlotDto from './forms/CreateSlotDto';
import DeleteSlotsDto from './forms/DeleteSlotsDto';
import { Slot } from './models/Slot';
import { IDateString } from './types/SlotTypes';

export default class SlotsApiRepository extends AbstractApiRepository {
  createSlots = (companyId: number, data: CreateSlotDto) => {
    return this.post({ url: `/company/${companyId}/slot/create`, data });
  };

  getSlot = (companyId: number, slotId: number) => {
    return this.get<Slot>({ url: `/company/${companyId}/slot/${slotId}` });
  };

  setBreak = (companyId: number, slotId: number) => {
    return this.post({ url: `/company/${companyId}/slot/${slotId}/break` });
  };

  deleteSlot = (companyId: number, slotId: number) => {
    return this.post({ url: `/company/${companyId}/slot/${slotId}/delete` });
  };

  deleteSlots = (companyId: number, data: DeleteSlotsDto) => {
    return this.post({ url: `/company/${companyId}/slot/deleteList`, data });
  };

  editSlot = (companyId: number, slotId: number, slotData: IDateString) => {
    return this.post({ url: `/company/${companyId}/slot/${slotId}`, data: slotData });
  };
}
