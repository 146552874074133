import { Chat } from './Chat';
import { IUserInfo } from '../types/ChatTypes';

export class ChatWithCreatorUser extends Chat {
  creatorUser: IUserInfo | null = null;

  constructor(props: any) {
    super(props);
    this.load(props);
  }
}
