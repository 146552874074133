import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Box, Button, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core';

import QModal from '../../../../../components/UI/QModal';
import Loader from '../../../../../components/UI/Loader';
import { stringToDate } from '../../../../../utils/stringToDate';
import TextButton from '../../../../../components/UI/TextButton';
import QTimePicker from '../../../../../components/UI/QTimePicker';
import { useRootStore } from '../../../../../base/hooks/useRootStore';
import { IDateString } from '../../../../../modules/role-admin/slots/types/SlotTypes';

interface ISlotTimeEditModalProps extends DialogProps {}

const SlotTimeEditModal: React.FC<ISlotTimeEditModalProps> = observer(props => {
  const classes = useStyles();
  const { slotsStore } = useRootStore();

  const [time, setTime] = useState<{ startDate: Date | null | string; endDate: Date | null | string }>({
    startDate: null,
    endDate: null,
  });

  const startDate = typeof time.startDate === 'string' ? stringToDate(time.startDate) : time.startDate;
  const endDate = time.endDate === 'string' ? stringToDate(time.endDate) : time.endDate;

  // Effects
  useEffect(() => {
    if (slotsStore.modalState.time.currentId && !slotsStore.slot) {
      slotsStore.getSlot(slotsStore.modalState.time.currentId);
    }

    return () => {
      slotsStore.clearSlot();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsStore.modalState.time]);

  useEffect(() => {
    if (slotsStore.slot) {
      const startDate = stringToDate(slotsStore.slot.startDate);
      const endDate = stringToDate(slotsStore.slot.endDate);

      setTime(prevValues => ({ ...prevValues, startDate, endDate }));
    }
  }, [slotsStore.slot]);

  // Handlers
  const handleToggleDeleteModal = () => {
    slotsStore.setModalOpen('deleteConfirmModal', true);
  };

  const handleChangeStartTime = (date: MaterialUiPickersDate) => {
    if (date) {
      const startDate =
        slotsStore.slotFormatedDate && isValid(date)
          ? `${format(slotsStore.slotFormatedDate?.startDate, 'yyyy-MM-dd')} ${format(date, 'HH:mm')}`
          : date;

      setTime({
        ...time,
        startDate,
      });
    }
  };

  const handleChangeEndTime = (date: Date | null) => {
    if (date) {
      const endDate =
        slotsStore.slotFormatedDate && isValid(date)
          ? `${format(slotsStore.slotFormatedDate?.endDate, 'yyyy-MM-dd')} ${format(date, 'HH:mm')}`
          : date;

      setTime({
        ...time,
        endDate,
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (time.startDate && time.endDate && slotsStore.modalState.time.currentId) {
      slotsStore.editSlot(slotsStore.modalState.time.currentId, time as IDateString);
    }
  };

  // Renders
  return (
    <>
      <QModal className={classes.modal} {...props}>
        {slotsStore.loading && <Loader isAbsolute color="secondary" />}
        <form onSubmit={handleSubmit}>
          <Box mb={4} maxWidth="80%">
            <Typography variant="h3">Редактирование слота</Typography>
          </Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box display="flex" mx={-1.25} mb={8}>
              <Box px={1.25}>
                <QTimePicker label="От" value={startDate} onChange={date => handleChangeStartTime(date)} />
              </Box>
              <Box px={1.25}>
                <QTimePicker label="До" value={endDate} onChange={date => handleChangeEndTime(date)} />
              </Box>
            </Box>
          </MuiPickersUtilsProvider>
          <Box mb={1}>
            <Button type="submit" fullWidth variant="contained" color="secondary">
              Сохранить
            </Button>
          </Box>
          <Box>
            <TextButton onClick={handleToggleDeleteModal} gutter fullWidth color="secondary">
              Удалить слот
            </TextButton>
          </Box>
        </form>
      </QModal>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
}));

export default SlotTimeEditModal;
