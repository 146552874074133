import { makeAutoObservable, runInAction } from 'mobx';

import ScheduleService from './ScheduleService';
import { rootStore } from '../../../base/RootStore';
import { Schedule } from './models/Schedule';

export class ScheduleStore {
  loading: boolean = false;
  scheduleLoading: boolean = false;

  scheduleList: Schedule[] = [];

  private scheduleService: ScheduleService;

  constructor() {
    makeAutoObservable(this);
    this.scheduleService = new ScheduleService();
  }

  getScheduleList = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.scheduleService
      .getScheduleList(companyId)
      .then(scheduleList => {
        runInAction(() => {
          this.scheduleList = scheduleList;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setSchedule = (values: Schedule[]) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setScheduleLoading(true);

    this.scheduleService
      .setSchedule(companyId, values)
      .then(scheduleList => {
        runInAction(() => {
          this.scheduleList = scheduleList;
        });
      })
      .catch(() => {})
      .finally(() => this.setScheduleLoading(false));
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setScheduleLoading = (value: boolean) => {
    this.scheduleLoading = value;
  };

  clearScheduleList = () => {
    this.scheduleList = [];
  };
}
