import { makeAutoObservable } from 'mobx';

import TokenService from './TokenService';
import { clearAccessToken, setAccessToken } from '../../base/ApiAdapter';

export class TokenStore {
  accessToken: string | null = null;

  private tokenService: TokenService;

  constructor() {
    this.tokenService = new TokenService();
    makeAutoObservable(this);
  }

  setToken = (token: string) => {
    this.tokenService.saveToken(token);
    setAccessToken(token);
  };

  getToken = () => {
    return this.tokenService.getToken();
  };

  deleteToken = () => {
    clearAccessToken();
    return this.tokenService.deleteToken();
  };

  clean = () => {
    return this.deleteToken();
  };
}
