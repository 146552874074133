import { useRootStore } from '../../base/hooks/useRootStore';
import roles from '../../base/routes/roles';
import { useAllMQ } from '../../hooks/useAllMQ';
import { routes } from '../../screens/routes';
import { useCommonStyles } from '../../styles/commonStyles';
import { useCustomTypography } from '../../styles/customTypography';
import QIcon from '../UI/Icons/QIcon';
import {
  calendarIcon,
  exitIcon,
  fileDownloadIcon,
  filePlusIcon,
  galleryIcon,
  headPhonesIcon,
  keyIcon,
  triangleIcon,
  usersIcon,
  userCheckIcon,
  suitcaseIcon,
} from '../UI/Icons/SvgIcons';
import { PhoneFormat } from '../UI/NumberInputs';
import TextButton from '../UI/TextButton';
import { Box, Divider, Fade, makeStyles, Menu, Theme, Typography } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

interface IMenuProps {
  menuAnchorEl: null | HTMLElement;
  onHandleCloseMenu: () => void;
}

const QMenu: React.FC<IMenuProps> = observer(({ menuAnchorEl, onHandleCloseMenu }) => {
  const history = useHistory();
  const { authStore, userStore } = useRootStore();
  const open = Boolean(menuAnchorEl);

  const classes = useStyles();
  const { isXS } = useAllMQ();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Handlers

  const handleChangeRoute = (e: React.MouseEvent<HTMLButtonElement>, path: string) => {
    onHandleCloseMenu();
    history.push(path);
  };

  const handleLogout = () => {
    authStore.logout();
  };

  // Renders
  if (userStore.userInfo) {
    const { name, phone, email } = userStore.userInfo;
    return (
      <Menu
        PopoverClasses={{ paper: classes.paper }}
        keepMounted
        open={open}
        anchorEl={menuAnchorEl}
        onClose={onHandleCloseMenu}
        TransitionComponent={Fade}
      >
        <div className={classes.topLine}>
          {isXS && (
            <Box mb={1.5}>
              <Typography className={clsx(typography.text2, commonClasses.blackMedium)}>{name}</Typography>
            </Box>
          )}
          {phone && (
            <Box mb={1.5}>
              <Typography className={clsx(typography.text2, commonClasses.blackMedium)}>
                <PhoneFormat value={Number(phone)} />
              </Typography>
            </Box>
          )}
          {email && (
            <Box mb={1}>
              <Typography className={clsx(typography.text2, commonClasses.blackMedium)}>{email}</Typography>
            </Box>
          )}
          <Divider />
        </div>
        {userStore.userInfo.role === roles.administrator.role && (
          <Box mb={1}>
            <Box mb={1}>
              <TextButton
                fullWidth
                className={classes.menuLink}
                startIcon={<QIcon className={commonClasses.blackLow} src={suitcaseIcon} width={20} height={20} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.CompanyScreen.path)}
              >
                <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                  Компания
                </Typography>
              </TextButton>
            </Box>
            <Box mb={1}>
              <TextButton
                fullWidth
                className={classes.menuLink}
                startIcon={<QIcon className={commonClasses.blackLow} src={filePlusIcon} width={14} height={17} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.OfferScreen.path)}
              >
                <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                  Прайс-лист
                </Typography>
              </TextButton>
            </Box>
            <Box mb={1}>
              <TextButton
                fullWidth
                className={classes.menuLink}
                startIcon={<QIcon className={commonClasses.blackLow} src={calendarIcon} width={14} height={17} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.ScheduleScreen.path)}
              >
                <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                  Расписание
                </Typography>
              </TextButton>
            </Box>
            <Box mb={1}>
              <TextButton
                fullWidth
                className={classes.menuLink}
                startIcon={<QIcon className={commonClasses.blackLow} src={usersIcon} width={14} height={17} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.EmployeesScreen.path)}
              >
                <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                  Сотрудники
                </Typography>
              </TextButton>
            </Box>
            {userStore.userInfo.role === roles.administrator.role && (
              <Box mb={1}>
                <TextButton
                  fullWidth
                  className={classes.menuLink}
                  startIcon={<QIcon className={commonClasses.blackLow} src={userCheckIcon} width={14} height={17} />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.ClientsScreen.path)}
                >
                  <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                    Клиенты
                  </Typography>
                </TextButton>
              </Box>
            )}
            <Box mb={1}>
              <TextButton
                fullWidth
                className={classes.menuLink}
                startIcon={<QIcon className={commonClasses.blackLow} src={galleryIcon} width={14} height={17} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.GalleryScreen.path)}
              >
                <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                  Галерея
                </Typography>
              </TextButton>
            </Box>
            <Box px={2}>
              <Divider />
            </Box>
          </Box>
        )}
        <Box mb={1}>
          <TextButton
            fullWidth
            className={classes.menuLink}
            startIcon={<QIcon className={commonClasses.blackLow} src={fileDownloadIcon} width={14} height={17} />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.ReportScreen.path)}
          >
            <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
              Выгрузка отчёта
            </Typography>
          </TextButton>
        </Box>
        <Box mb={1}>
          <TextButton
            fullWidth
            className={classes.menuLink}
            startIcon={<QIcon className={commonClasses.blackLow} src={keyIcon} width={14} height={17} />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.ChangePasswordScreen.path)}
          >
            <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
              Сменить пароль
            </Typography>
          </TextButton>
        </Box>
        <Box mb={1}>
          <TextButton
            fullWidth
            className={classes.menuLink}
            startIcon={<QIcon className={commonClasses.blackLow} src={headPhonesIcon} width={14} height={17} />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.SupportScreen.path)}
          >
            <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
              Поддержка
            </Typography>
          </TextButton>
        </Box>
        {userStore.userInfo.role === roles.administrator.role && (
          <Box mb={1}>
            <TextButton
              fullWidth
              className={classes.menuLink}
              startIcon={<MailOutlineRoundedIcon className={commonClasses.blackLow} />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChangeRoute(e, routes.ChatScreen.path)}
            >
              <Typography variant="body2" component="span" className={commonClasses.blackHigh}>
                Сообщения
              </Typography>
            </TextButton>
          </Box>
        )}
        <Box px={2}>
          <Divider />
        </Box>
        <div>
          <TextButton
            fullWidth
            className={classes.menuLink}
            startIcon={<QIcon className={commonClasses.blackLow} src={exitIcon} width={14} height={17} />}
            onClick={handleLogout}
          >
            <Typography variant="body2" component="span" className={commonClasses.blackMedium}>
              Выйти из аккаунта
            </Typography>
          </TextButton>
        </div>
      </Menu>
    );
  }

  return <></>;
});

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflowY: 'scroll',
    marginTop: 68,
    minWidth: 232,
    background: theme.palette.extra.white,
    borderRadius: 8,
    border: `1px solid ${theme.palette.extra.stroke}`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    overflow: 'visible',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 2,
      right: 0,
      width: 17,
      height: 21,
      transform: 'translate(-100%, -100%)',
      background: `url('${triangleIcon}') no-repeat center center`,
    },
  },
  topLine: {
    padding: theme.spacing(1, 2),
  },
  menuLink: {
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 0,
    transition: transitions.create('background-color'),
    '& .MuiButton-startIcon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 20,
      minWidth: 20,
      minHeight: 20,
      marginRight: 16,
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '&:hover': {
      backgroundColor: theme.palette.qsecondary[50],
    },
  },
}));

export default QMenu;
