import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import TextButton from './TextButton';
import { IBackButtonProps } from '../../base/types/BaseTypes';

const BackButton: React.FC<IBackButtonProps> = props => {
  const { to, text, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Link to={to} className="router-link">
      <TextButton startIcon={<ArrowBackIcon />} className={clsx(classes.backButton, className)} {...rest}>
        <Typography variant="body1">{text}</Typography>
      </TextButton>
    </Link>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    color: theme.palette.black.medium,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      left: 24,
      top: 24,
    },
  },
}));

export default BackButton;
