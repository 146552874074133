import AbstractModel from '../../../base/AbstractModel';
import { Offer } from '../../offer/models/Offer';
import { RecordStatuses } from '../../role-admin/clients/types/ClientsTypes';
import { ICompanyEmployee } from '../types/RecordTypes';

export class CRecord extends AbstractModel {
  id: number | null = null;
  companyEmployee: ICompanyEmployee | null = null;
  additionCompanyOffers: Offer[] = [];
  companyOffer: Offer | null = null;
  startDate: string | null = null;
  status: RecordStatuses | null = null;
  source: string | null = null;
  clientName: string | null = null;
  clientPhone: string | null = null;
  isPrePayPayment: boolean = false;
  unreadMessagesCount: number = 0;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
