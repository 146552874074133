import AbstractModel from '../../../../base/AbstractModel';

export class CompanyAddress extends AbstractModel {
  latitude: number | null = null;
  longitude: number | null = null;
  formatted: string | null = null;
  street: string | null = null;
  house: string | null = null;
  short: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
