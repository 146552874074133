import { IAttachFiles } from '../modules/chat/types/ChatTypes';
import { isImageType } from './fileUtils';
import { getSubArray } from './getSubArray';

interface IFilteredByFormatData {
  files: IAttachFiles[];
  images: IAttachFiles[];
}
export const SPLITTING_SIZE = 3;

export const splitingArray = (array: IAttachFiles[]) => {
  const filteredByFormat = array.reduce<IFilteredByFormatData>(
    (acc, item) => {
      if (item.file?.extension && isImageType(item.file.extension)) {
        acc.images.push(item);
      } else {
        acc.files.push(item);
      }
      return acc;
    },
    { files: [], images: [] },
  );

  return { files: filteredByFormat.files, images: [...getSubArray(filteredByFormat.images)].reverse() };
};
