import { cleanUpPhoneMask } from '../../../components/UI/RussianPhoneMaskInput';
import CompanyApiRepository from './CompanyApiRepository';
import { CompanyFactory } from './CompanyFactory';
import UpdateCompanyAddressDto from './forms/UpdateCompanyAddress';
import UpdateCompanyDetailDto from './forms/UpdateCompanyDetail';
import UpdateCompanyInfoDto from './forms/UpdateCompanyInfo';
import { CompanyDetail } from './models/CompanyDetail';
import { CompanyInfo } from './models/CompanyInfo';
import { OfferCategory } from './models/OfferCategory';
import { Timezone } from './models/Timezone';
import { ICompanyDetail, ICompanyInfo } from './types/CompanyTypes';

export default class CompanyService {
  companyApi: CompanyApiRepository;
  companyFactory: CompanyFactory;

  constructor() {
    this.companyApi = new CompanyApiRepository();
    this.companyFactory = new CompanyFactory();
  }

  getInfo = async (companyId: number) => {
    const { data } = await this.companyApi.getInfo(companyId);
    return this.companyFactory.createInfoWithAddress(data.data);
  };

  getGeo = async (address: string) => {
    const { data: addressData } = await this.companyApi.getGeo(address);
    const firstAddress = (addressData.data as any)?.[0];
    return this.prepareUpdateAddress(firstAddress);
  };

  getDetail = async (companyId: number) => {
    const { data } = await this.companyApi.getDetail(companyId);
    return this.companyFactory.create<CompanyDetail>(CompanyDetail, data.data);
  };

  getOfferCategories = async (): Promise<OfferCategory[]> => {
    const { data } = await this.companyApi.getOfferCategories();
    return this.companyFactory.createList<OfferCategory>(OfferCategory, data.data?.items as any);
  };

  getTimezones = async (): Promise<Timezone[]> => {
    const { data } = await this.companyApi.getTimezones();
    return this.companyFactory.createList<Timezone>(Timezone, data.data);
  };

  getInfoModalFromCard = (card: CompanyInfo): ICompanyInfo => {
    const values: ICompanyInfo = {
      title: card.title || '',
      offerCategory: card.offerCategory || null,
      address: card.address?.formatted || '',
      description: card.description || '',
      contactPerson: card.contactPerson || '',
      //если дать input телефон '79...', то 7 добавляется лишней маской
      contactPersonPhone: card.contactPersonPhone?.slice(1) || '',
      contactPersonEmail: card.contactPersonEmail || '',
      phone: card.phone?.slice(1) || '',
      email: card.email || '',
      timezone: card.timezone || null,
    };
    return values;
  };

  getDetailModalFromCard = (card: CompanyDetail): ICompanyDetail => {
    const values: ICompanyDetail = { ...card };
    return values;
  };

  updateInfo = async (companyId: number, values: ICompanyInfo): Promise<CompanyInfo> => {
    const addressData = await this.getGeo(values.address);
    const updateInfoDto = this.prepareUpdateInfo(values, addressData);
    const { data } = await this.companyApi.setInfo(companyId, updateInfoDto);
    return this.companyFactory.create<CompanyInfo>(CompanyInfo, data.data);
  };

  updateDetail = async (companyId: number, values: ICompanyDetail): Promise<CompanyDetail> => {
    const updateDetailDto = this.prepareUpdateDetail(values);
    const { data } = await this.companyApi.setDetail(companyId, updateDetailDto);
    return this.companyFactory.create<CompanyDetail>(CompanyDetail, data.data);
  };

  private prepareUpdateInfo = (values: ICompanyInfo, address: UpdateCompanyAddressDto): UpdateCompanyInfoDto => {
    const updateInfoPreDto = {
      address: address,
      title: values.title,
      offerCategoryId: values.offerCategory?.id,
      description: values.description,
      contactPerson: values.contactPerson,
      contactPersonPhone: values.contactPersonPhone ? '7' + cleanUpPhoneMask(values.contactPersonPhone) : '',
      contactPersonEmail: values.contactPersonEmail,
      phone: values.phone ? '7' + cleanUpPhoneMask(values.phone) : '',
      email: values.email,
      timezone: values.timezone,
    };
    const updateInfoDto = UpdateCompanyInfoDto.populate(updateInfoPreDto) as UpdateCompanyInfoDto;
    return updateInfoDto;
  };

  private prepareUpdateDetail = (values: ICompanyDetail): UpdateCompanyDetailDto => {
    const updateDetailDto = UpdateCompanyDetailDto.populate({ ...values }) as UpdateCompanyDetailDto;
    return updateDetailDto;
  };

  private prepareUpdateAddress = (firstAddress: any): UpdateCompanyAddressDto => {
    const prepareDto = {
      ...firstAddress,
      cityId: firstAddress.city.id,
    };
    return this.companyFactory.create<UpdateCompanyAddressDto>(UpdateCompanyAddressDto, prepareDto);
  };
}
