import { useCommonStyles } from '../../../../styles/commonStyles';
import { useCustomTypography } from '../../../../styles/customTypography';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface ICompanyBlockInfoProps {
  title: string | null;
  subtitle1?: string | JSX.Element | null;
  subtitle2?: JSX.Element[] | null;
  isSecondary?: boolean;
}

const CompanyBlockInfo: React.FC<ICompanyBlockInfoProps> = props => {
  const { title, subtitle1, subtitle2, isSecondary } = props;

  const classes = useStyles();
  const typography = useCustomTypography();
  const commonStyles = useCommonStyles();

  const subtitle1Color = isSecondary
    ? commonStyles.blackMedium
    : subtitle1
    ? commonStyles.blackHigh
    : commonStyles.blackLow;
  const textColor = isSecondary ? commonStyles.blackMedium : commonStyles.blackHigh;

  //Renders
  return (
    <Box display={'flex'} flexDirection={'column'} className={clsx(classes.companyBlock, textColor)}>
      <Typography variant={'body1'}>{title}</Typography>
      <Typography className={clsx(typography.text, subtitle1Color)}>{subtitle1 || 'Данные не введены'}</Typography>
      {subtitle2 && (
        <Box display={'flex'} className={classes.subtitle2Block}>
          {subtitle2.map(el => (
            <Typography className={typography.text2}>{el}</Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  companyBlock: {
    gap: 8,
  },
  subtitle2Block: {
    gap: 8,
  },
}));

export default CompanyBlockInfo;
