import clsx from 'clsx';
import { Avatar, Box, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import { AutocompleteRenderOptionState } from '@material-ui/lab';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { useCommonStyles } from '../../styles/commonStyles';
import { useCustomTypography } from '../../styles/customTypography';

interface IEmployeeOptionProps {
  option: any;
  state: AutocompleteRenderOptionState;
}

const EmployeeOption: React.FC<IEmployeeOptionProps> = ({ option, state }) => {
  const { selected } = state;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" maxWidth="calc(100% - 32px)">
        <Avatar className={classes.avatar} src={option?.photo?.fullUrl} alt="" />
        <div>
          <Box mb="2px">
            <Typography className={clsx(typography.text2, commonClasses.blackHigh)}>{option?.name}</Typography>
          </Box>
          <div className={classes.specialization}>
            <Typography variant="caption" className={commonClasses.blackMedium}>
              {option.specialization}
            </Typography>
          </div>
        </div>
      </Box>
      {selected && (
        <Box display="flex" justifyContent="center" alignItems="center" ml="auto">
          <Icon color="secondary">
            <CheckRoundedIcon />
          </Icon>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    marginRight: theme.spacing(1.5),
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  specialization: {
    maxWidth: '100%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default EmployeeOption;
