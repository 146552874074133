import AbstractModel from '../../../../base/AbstractModel';
import { Employee } from '../../employee/models/Employee';

export class Slot extends AbstractModel {
  id: number | null = null;
  startDate: string = '';
  endDate: string = '';
  type: string = '';
  companyEmployee: Employee | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
