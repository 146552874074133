import AbstractModel from '../../../AbstractModel';
import { IPaginationMeta } from '../PaginationTypes';

export default class Pagination extends AbstractModel {
  meta: IPaginationMeta = { totalCount: null, limit: null, currentOffset: null };

  constructor(props: any) {
    super();
    this.load(props);
  }
}
