import AbstractModel from '../../../base/AbstractModel';

export class NotificationModel extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  message: string | null = null;
  data: unknown | null = null;
  createdAt: string | null = null;
  date: string | null = null;
  readAt: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
