import { makeAutoObservable } from 'mobx';

import MainService from './MainService';
import { IModalState } from './types/MainTypes';

export class MasterMainStore {
  loading = false;

  private mainService: MainService;

  selectedDate: Date = new Date();
  modalState: IModalState = { currentId: null, modal: false };

  constructor() {
    makeAutoObservable(this);
    this.mainService = new MainService();
  }

  get formattedDate() {
    return this.mainService.getFormattedData(this.selectedDate);
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };

  setModalState = (value: IModalState) => {
    this.modalState = value;
  };
}
