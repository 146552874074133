import { useRootStore } from '../../../../../base/hooks/useRootStore';
import { settingIcon, timeIcon, circleCheck, fileCheck } from '../../../../../components/UI/Icons/SvgIcons';
import Loader from '../../../../../components/UI/Loader';
import QModal from '../../../../../components/UI/QModal';
import QTextField from '../../../../../components/UI/QTextField';
import RecordStatusTag from '../../../../../components/UI/RecordStatusTag';
import { RecordStatuses } from '../../../../../modules/role-admin/clients/types/ClientsTypes';
import { useCommonStyles } from '../../../../../styles/commonStyles';
import { formatRu } from '../../../../../utils/formatRu';
import moneyFormat from '../../../../../utils/moneyFormat';
import { Box, DialogProps, Divider, FormLabel, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

interface IRecordModalProps extends DialogProps {}

const RecordModal: React.FC<IRecordModalProps> = observer(props => {
  const { recordsStore, masterMainStore } = useRootStore();

  const theme = useTheme();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    if (masterMainStore.modalState.currentId) {
      recordsStore.getRecordById(masterMainStore.modalState.currentId);
    }

    return () => {
      if (masterMainStore.modalState.currentId) {
        recordsStore.clearRecordInfo();
      }
    };
  }, [masterMainStore.modalState.currentId, recordsStore]);

  // Renders
  const renderContent = () => {
    if (recordsStore.recordInfo) {
      const { companyOffer, startDate, clientName, isPrePayPayment, status } = recordsStore.recordInfo;

      const fullDate = startDate && formatRu(startDate, 'd MMMM, HH:mm');
      const isCompleted = status === RecordStatuses.COMPLETED;
      const isFullCompleted = isPrePayPayment || isCompleted;

      return (
        <>
          <Box mb={isFullCompleted ? 1.5 : 3} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h3" className={commonClasses.blackHigh}>
              Данные записи
            </Typography>
          </Box>
          {isFullCompleted && (
            <Box mb={2} display="flex" alignItems="center">
              {isPrePayPayment && (
                <Box>
                  <RecordStatusTag title="Оплачено" icon={circleCheck} color={'#0DC268'} />
                </Box>
              )}
              {isCompleted && (
                <Box ml={2}>
                  <RecordStatusTag title="Запись завершена" icon={fileCheck} color={theme.palette.black.medium} />
                </Box>
              )}
            </Box>
          )}
          {companyOffer && (
            <Box mb={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel>Услуга</FormLabel>
              </Box>
              <QTextField
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                value={`${companyOffer.title}, ${moneyFormat(companyOffer.price)} ₽`}
              />
            </Box>
          )}
          {fullDate && (
            <Box mb={3}>
              <QTextField
                label="Дата и время"
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: timeIcon, color: 'secondary' }}
                value={fullDate}
              />
            </Box>
          )}
          {clientName && (
            <>
              <Box mb={3} maxWidth={328} mx="auto">
                <Divider />
              </Box>
              <div>
                <QTextField label="Имя клиента" InputProps={{ readOnly: true }} value={clientName} />
              </div>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <QModal {...props}>
        {recordsStore.recordInfoLoading ? <Loader minHeight={357} color="secondary" /> : renderContent()}
      </QModal>
    </>
  );
});

export default RecordModal;
