import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Box, Button, DialogProps, Theme, Typography } from '@material-ui/core';

import QModal from '../../../../components/UI/QModal';
import Loader from '../../../../components/UI/Loader';
import QTextField from '../../../../components/UI/QTextField';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import QAutocomplete from '../../../../components/UI/QAutocomplete';
import { NumberInput } from '../../../../components/UI/NumberInputs';
import { initialValues } from '../../../../modules/offer/OfferStore';
import {clipboardNotesCircleIcon, moneyIcon, settingIcon, timeIcon} from '../../../../components/UI/Icons/SvgIcons';
import { useCommonStyles } from '../../../../styles/commonStyles';

interface IOfferModalProps extends DialogProps {}

const OfferModal: React.FC<IOfferModalProps> = observer(props => {
  const { offerStore } = useRootStore();
  const { values, action, setValues } = offerStore;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    if (action === 'add') {
      if (!offerStore.offersList.length) {
        offerStore.getOffersList();
      }
    }

    if (action === 'edit') {
      offerStore.getCompanyOffer();
    }
  }, [action, offerStore]);

  useEffect(() => {
    return () => {
      offerStore.setCurrentOfferId(null);
      offerStore.clearOffersList();
    };
  }, [offerStore]);

  // Handlers
  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    offerStore.setOfferModal(false);
    offerStore.setCurrentOfferId(null);
    offerStore.setAction(null);
    offerStore.setValues(initialValues);
    offerStore.setErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleChangeAutocomplete = (e: React.ChangeEvent<{}>, value: any) => {
    setValues({ ...values, offer: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (action) {
      case 'add':
        offerStore.addOffer();
        break;
      case 'edit':
        offerStore.updateOffer();
        break;
    }
  };

  // Renders
  return (
    <QModal onClose={handleClose} {...props}>
      {offerStore.offerModalLoading && <Loader isAbsolute color="secondary" />}
      <form onSubmit={handleSubmit}>
        <Box mb={3} maxWidth="80%">
          <Typography variant="h3">
            {action === 'add' && 'Добавить новую услугу'}
            {action === 'edit' && 'Редактировать услугу'}
          </Typography>
        </Box>
        <Box mb={3}>
          {action === 'edit' && (
            <QTextField
              label="Услуга"
              value={offerStore.companyOffer?.title ?? ''}
              placeholder="Введите стоимость "
              btnProps={{ startIcon: settingIcon, color: 'secondary' }}
              InputProps={{ readOnly: true }}
            />
          )}
          {action === 'add' && (
            <QAutocomplete
              disablePortal
              label="Услуга"
              value={values.offer}
              options={offerStore.groupedOfferList}
              noOptionsText="Нет подходящих вариантов"
              getOptionLabel={option => option?.title}
              groupBy={option => option?.categoryTitle}
              getOptionSelected={(option, value) => option?.id === value?.id}
              classes={{ listbox: classes.listBox, option: classes.option }}
              renderGroup={params => (
                <li key={params.key}>
                  <Box px={1.5} py={0.75}>
                    <Typography variant="subtitle1" className={commonClasses.blackHigh}>
                      {params.group}
                    </Typography>
                  </Box>
                  <ul className={classes.optionWrap}>{params.children}</ul>
                </li>
              )}
              onChange={handleChangeAutocomplete}
              renderInput={params => {
                return (
                  <QTextField
                    name="offer"
                    placeholder="Выбрать услугу"
                    btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                    {...params}
                    error={offerStore.errorMessages?.offer_id?.length}
                    helperText={offerStore.errorMessages?.offer_id?.map((item: string) => (
                      <Box mb={0.5}>{item}</Box>
                    ))}
                  />
                );
              }}
            />
          )}
        </Box>
        <Box mb={3}>
          <QTextField
              name="subtitle"
              value={values.subtitle}
              label="Вид услуги"
              placeholder="Введите вид услуги"
              btnProps={{   color: 'secondary',  startIcon: clipboardNotesCircleIcon }}
              onChange={handleChange}
              error={offerStore.errorMessages?.subtitle?.length}
              helperText={offerStore.errorMessages?.subtitle?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
              ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            name="price"
            value={values.price}
            label="Стоимость"
            placeholder="Введите стоимость "
            InputProps={{ inputComponent: NumberInput }}
            btnProps={{ startIcon: moneyIcon, color: 'secondary' }}
            onChange={handleChange}
            error={offerStore.errorMessages?.price?.length}
            helperText={offerStore.errorMessages?.price?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            name="duration"
            value={values.duration}
            label="Продолжительность в минутах"
            placeholder="Введите продолжительность"
            InputProps={{ inputComponent: NumberInput }}
            btnProps={{ startIcon: timeIcon, color: 'secondary' }}
            onChange={handleChange}
            error={offerStore.errorMessages?.duration?.length}
            helperText={offerStore.errorMessages?.duration?.map((item: string) => (
              <Box mb={0.1}>{item}</Box>
            ))}
          />
        </Box>
        <Button type="submit" fullWidth variant="contained" color="secondary">
          {action === 'add' && 'Добавить'}
          {action === 'edit' && 'Сохранить'}
        </Button>
      </form>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  listBox: {
    marginTop: 5,
    maxHeight: 280,
    padding: theme.spacing(0),
    borderTop: `1px solid ${theme.palette.extra.stroke}`,
  },
  optionWrap: {
    padding: 0,
  },
  option: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 'normal',
    padding: theme.spacing(2, 3),
    '&[aria-selected="true"]': {
      color: theme.palette.extra.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default OfferModal;
