import React, { useCallback, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';

import { routes } from '../../routes';
import ChatHeader from './ChatHeader';
import Loader from '../../../components/UI/Loader';
import { useAllMQ } from '../../../hooks/useAllMQ';
import QIcon from '../../../components/UI/Icons/QIcon';
import BackButton from '../../../components/UI/BackButton';
import QTextField from '../../../components/UI/QTextField';
import { isAllowedFileSize } from '../../../utils/fileUtils';
import NotificationUtil from '../../../utils/NotificationUtil';
import { useRootStore } from '../../../base/hooks/useRootStore';
import ChatMessages from '../../../components/Chat/ChatMessages';
import { IValidFiles } from '../../../modules/chat/types/ChatTypes';
import ChatImageViewer from '../../../components/Chat/ChatImageViewer';
import ChatSelectedFiles from '../../../components/Chat/ChatSelectedFiles';
import { paperclipIcon, plainIcon } from '../../../components/UI/Icons/SvgIcons';

interface IChatContentProps {}

const ChatContent: React.FC<IChatContentProps> = observer(() => {
  const { chatStore, fileStore } = useRootStore();
  const { values, setValues } = chatStore;
  const chatScrollBar = useRef<Scrollbars | null>(null);
  const loading = chatStore.messageHistoryLoading && chatStore.isEndOfMessages && !chatStore.loading;

  const classes = useStyles();
  const { isXS, isSM, isMD } = useAllMQ();

  const getAutoHeightMax = () => {
    if (isMD) {
      if (isSM) {
        if (isXS) {
          return 'calc(100vh - 213px)';
        }
        return 'calc(100vh - 213px)';
      }
      return 'calc(100vh - 222px)';
    }

    return 'calc(100vh - 241px)';
  };

  // Effects
  useEffect(() => {
    chatStore.getSupportChat();
    return () => {
      chatStore.clearSupportChat();
    };
  }, [chatStore]);

  useEffect(() => {
    return () => {
      chatStore.clearStore();
    };
  }, [chatStore]);

  // init scroll
  useEffect(() => {
    if (chatScrollBar.current) {
      const scrollHeight = chatScrollBar.current.getScrollHeight();

      if (chatStore.isScrolled) {
        chatScrollBar.current.scrollTop(scrollHeight);
        chatStore.setScrolled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.files, chatStore.isScrolled]);

  // has new message scroll
  useEffect(() => {
    if (chatScrollBar.current && chatStore.newMessageLength !== 0) {
      const scrollHeight = chatScrollBar.current.getScrollHeight();

      chatScrollBar.current.scrollTop(scrollHeight);
      chatStore.setNewMessageLength(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatStore.newMessageLength]);

  useEffect(() => {
    let timer: any = null;

    const loopRequest = () => {
      timer = setTimeout(() => {
        chatStore.getChatMessageHistory(chatStore.initStatus ? 'bottom' : 'init');
        loopRequest();
        clearTimeout(timer);
      }, 5000);
    };

    loopRequest();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatStore.messages, chatStore.initStatus, chatStore.supportMessage]);

  useEffect(() => {
    const newArr = values.files?.map((item, index) => ({ ...item, id: fileStore.files[index].uuid ?? '' })) ?? [];

    setValues({
      ...values,
      files: newArr,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileStore.files]);

  const getFilesFormData = useCallback((files: Array<IValidFiles>) => {
    const formData = new FormData();
    if (files?.length) {
      files.forEach((file, i) => {
        if (!isAllowedFileSize(file.file.size)) {
          formData.append(`files[${i}]`, file.file);
        }
      });
    }

    return formData;
  }, []);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target.files;
    const validFiles: IValidFiles[] = [];
    const errors: string[] = [];

    if (files?.length) {
      Array.from(files).forEach((file, index) => {
        if (index <= 14) {
          if (isAllowedFileSize(file.size)) {
            errors.push(`Размер файла ${file.name} не должно превышать 10мб.`);
          } else {
            validFiles.push({
              id: uniqueId('fileId-'),
              file,
            });
          }
        }
      });
    }

    if (errors.length) {
      const errorsString = errors.join('\n');
      NotificationUtil.showError(errorsString);
    }

    if (files?.length && files.length >= 14) {
      NotificationUtil.showError('За раз можно прикрепить не более 15 файлов');
    }

    if (validFiles.length) {
      setValues({
        ...values,
        files: [...(values?.files || []), ...validFiles],
      });
      fileStore.uploadFiles(getFilesFormData(validFiles));
    }
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClearFile = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    const filteredFiles = values.files?.filter(file => file.id !== id) ?? [];

    fileStore.deleteFileById(id);
    setValues({
      ...values,
      files: filteredFiles,
    });

    if (filteredFiles.length === 0) {
      chatStore.setFileHeight(0);
    }
  };

  const handleUpdate = ({ scrollTop }: positionValues) => {
    const isLoadMore = scrollTop <= 300;

    if (isLoadMore) {
      if (!chatStore.isScrolled) {
        if (!chatStore.messageHistoryLoading && chatStore.initStatus) {
          chatStore.setScrolled(false);
          if (chatStore.isEndOfMessages) {
            chatScrollBar.current?.scrollTop(301);
            chatStore.getChatMessageHistory('top');
          }
        }
      }
    }
  };

  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (fileStore.filesUuid.length !== 0 || values.message.length !== 0) {
      chatStore.writeSupport({ body: values.message, attachFiles: fileStore.filesUuid }, chatScrollBar);
      setValues({
        files: null,
        message: '',
      });
    }
  };

  // Renders
  return (
    <>
      {chatStore.loading && <Loader isAbsolute minHeight="100vh" color="secondary" />}
      {!isMD && <BackButton text="Вернуться на главную" to={routes.MainScreen.path} />}
      <form onSubmit={handleSumbit}>
        <div className={classes.wrap}>
          <ChatHeader />
          <div className={classes.content}>
            <Scrollbars
              ref={chatScrollBar}
              autoHide
              autoHeight
              onUpdate={handleUpdate}
              autoHeightMax={getAutoHeightMax()}
            >
              <div className={classes.messages}>
                {loading && (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Loader minHeight={60} color="secondary" />
                  </Box>
                )}
                <div style={{ paddingBottom: chatStore.fileHeight }}>
                  <ChatMessages items={chatStore.messagesWithDate} />
                </div>
              </div>
            </Scrollbars>
            <div className={classes.bottomPanel}>
              <Box display="flex" width="100%" mt={'-5px'}>
                <Box position="relative" flex="1 1 auto" mr={2}>
                  <QTextField
                    value={values.message}
                    onChange={handleChangeMessage}
                    name="message"
                    placeholder="Сообщение..."
                    className={classes.messageField}
                  />
                  <input
                    onChange={handleChange}
                    multiple
                    type="file"
                    name="file"
                    id="fileInput"
                    className={classes.input}
                  />
                  <Button htmlFor="fileInput" component="label" className={classes.fileButton}>
                    <QIcon src={paperclipIcon} width={20} height={20} />
                  </Button>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={fileStore.loading}
                  className={classes.submitBtn}
                >
                  <QIcon src={plainIcon} width={24} height={24} />
                </Button>
              </Box>
              {!!values.files?.length && <ChatSelectedFiles files={values.files} onHandleClearFile={handleClearFile} />}
            </div>
          </div>
        </div>
      </form>
      <ChatImageViewer />
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    margin: '0 auto',
    marginTop: -1,
    overflow: 'hidden',
    border: `1px solid ${theme.palette.extra.stroke}`,
    [theme.breakpoints.up('md')]: {
      maxWidth: 552,
    },
  },
  content: {
    position: 'relative',
    margin: '0 auto',
    height: 'calc(100vh - 81px)',
    background: theme.palette.extra.bg,
    padding: theme.spacing(11.5, 0, 0),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 71px)',
      padding: theme.spacing(10.375, 0, 0),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(10.375, 0, 0),
    },
  },
  input: {
    display: 'none',
  },
  fileButton: {
    position: 'absolute',
    top: '50%',
    right: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'auto',
    color: theme.palette.qsecondary[100],
    transform: 'translateY(-50%)',
    padding: 12,
    borderRadius: '50%',
  },
  bottomPanel: {
    position: 'absolute',
    bottom: 24,
    left: '50%',
    width: '100%',
    height: 'auto',
    padding: theme.spacing(0, 3),
    transform: 'translateX(-50%)',
    background: theme.palette.extra.bg,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 16,
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 'auto',
  },
  messages: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  messageField: {
    '& .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(6),
    },
  },
}));

export default ChatContent;
