import { User } from './models/User';
import { UserFactory } from './UserFactory';
import UserApiRepository from './UserApiRepository';
import { IUpdatePassword } from './types/UserTypes';

export default class UserService {
  userApi: UserApiRepository;
  userFactory: UserFactory;

  constructor() {
    this.userApi = new UserApiRepository();
    this.userFactory = new UserFactory();
  }

  getUserInfo = async (): Promise<User> => {
    const { data } = await this.userApi.getUserInfo();
    return this.userFactory.create<User>(User, data.data);
  };

  updatePassword = async (values: IUpdatePassword) => {
    return this.userApi.udpatePassword(values);
  };

  setTimezone = async (timezone: string) => {
    return this.userApi.setTimezone(timezone);
  };
}
