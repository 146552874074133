import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@material-ui/core';

import Loader from '../../../components/UI/Loader';
import EmployeeItem from './components/EmployeeItem';
import EmployeeModal from './components/EmployeeModal';
import QIcon from '../../../components/UI/Icons/QIcon';
import { useCommonStyles } from '../../../styles/commonStyles';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { plusIcon } from '../../../components/UI/Icons/SvgIcons';
import ContentContainer from '../../../components/ContentContainer';
import DeleteEmployeeModal from './components/DeleteEmployeeModal';

interface IEmployeesScreenProps {}

const EmployeesScreen: React.FC<IEmployeesScreenProps> = observer(() => {
  const { employeeStore } = useRootStore();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    employeeStore.getFullEmployeeList();
  }, [employeeStore]);

  // Handlers
  const handleOpenDeleteModal = (id: number) => {
    employeeStore.setCurrentEmployeeId(id);
    employeeStore.setDeleteModal(true);
    employeeStore.setAction('delete');
  };

  const handleOpenEditModal = (id: number) => {
    employeeStore.setCurrentEmployeeId(id);
    employeeStore.setEmployeeModal(true);
    employeeStore.setAction('edit');
    employeeStore.getEmployeeInfo(id);
  };

  const handleOpenEmployeeModal = () => {
    employeeStore.setEmployeeModal(true);
    employeeStore.setAction('add');
  };

  // Renders
  return (
    <ContentContainer title="Сотрудники">
      {employeeStore.loading && <Loader isAbsolute color="secondary" />}
      <Box mb={{ xxs: 4, md: 8 }}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          startIcon={<QIcon src={plusIcon} width={16} height={16} />}
          onClick={handleOpenEmployeeModal}
        >
          Добавить нового сотрудника
        </Button>
      </Box>
      <Box mb={3}>
        <Typography className={commonClasses.blackHigh} variant="body1">
          Все сотрудники
        </Typography>
      </Box>

      {employeeStore.fullEmployeeList.map(item => (
        <Box key={item.id} mb={{ xxs: 2, sm: 3 }}>
          <EmployeeItem data={item} onHandleDelete={handleOpenDeleteModal} onHandleEdit={handleOpenEditModal} />
        </Box>
      ))}

      <EmployeeModal open={employeeStore.employeeModal} disableBackdropClick />
      <DeleteEmployeeModal open={employeeStore.deleteModal} />
    </ContentContainer>
  );
});

export default EmployeesScreen;
