import { IPaginationRes } from '../../../base/modules/pagination/PaginationTypes';

export interface INotificationsPaginationRes extends IPaginationRes {
  unreadNotificationsCount: number;
}

export enum ReqType {
  TOP,
  INIT,
  BOTTOM,
}
