import { makeStyles, Switch, SwitchProps, Theme, Typography } from '@material-ui/core';

interface IQSwitchProps extends SwitchProps {
  label: JSX.Element | string;
}

const QSwitch: React.FC<IQSwitchProps> = ({ label, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.switchContainer}>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
        {...rest}
      />
      <Typography variant="h4" className={classes.swithLabel}>
        {label}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    width: 44,
    height: 24,
    padding: 0,
    margin: theme.spacing(0, 2, 0, 0),
    '& .MuiIconButton-label': {
      borderRadius: '50%',
      overflow: 'hidden',
    },
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(20px)',
      '& + $track': {
        backgroundColor: '#373E4D',
        opacity: 1,
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
    '&$focusVisible $thumb': {
      backgroundColor: '#373E4D',
    },
    '&$disabled': {
      '& + $track': {
        backgroundColor: '#C3C5CA !important',
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    backgroundColor: '#fff',
    boxShadow: '2px 2px 4px rgba(75, 72, 80, 0.5), -1px -1px 3px rgba(235, 227, 227, 0.25)',
  },
  track: {
    background: '#E7E8EA',
    borderRadius: 15,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxShadow: 'inset 0px 0px 2px rgba(0, 0, 0, 0.06), inset 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  focusVisible: {},
  checked: {},
  swithLabel: {
    color: theme.palette.black.high,
  },
  disabled: {},
}));

export default QSwitch;
