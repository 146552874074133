import { useRootStore } from '../../../base/hooks/useRootStore';
import SideBar from '../../../components/SideBar';
import { useAllMQ } from '../../../hooks/useAllMQ';
import MiniHeader from './components/MiniHeader';
import MasterSlotTable from './components/SlotTable';
import { SwipeableDrawer, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

const MasterMainScreen: React.FC = observer(() => {
  const { isMD } = useAllMQ();
  const { headerStore, masterMainStore } = useRootStore();

  // Effects
  useEffect(() => {
    return () => {
      masterMainStore.setSelectedDate(new Date());
    };
  }, [masterMainStore]);

  // Handlers
  const handleToggleSidebar = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    headerStore.setIsShowSidebar();
  };

  return (
    <Box display="flex">
      {!isMD && (
        <SideBar selectedDate={masterMainStore.selectedDate} onSelectedDate={masterMainStore.setSelectedDate} />
      )}
      <div>
        <MiniHeader />
        <MasterSlotTable />
      </div>
      {isMD && (
        <SwipeableDrawer open={headerStore.isShowSidebar} onClose={handleToggleSidebar} onOpen={handleToggleSidebar}>
          <SideBar selectedDate={masterMainStore.selectedDate} onSelectedDate={masterMainStore.setSelectedDate} />
        </SwipeableDrawer>
      )}
    </Box>
  );
});

export default MasterMainScreen;
