import AbstractDto from '../../../../base/AbstractDto';

export default class UpdateCompanyAddressDto extends AbstractDto {
  cityId: number | null = null;
  street: string | null = null;
  house: string | null = null;
  longitude: string | null = null;
  latitude: string | null = null;
  formatted: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}