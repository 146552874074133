import AbstractFactory from '../../../base/AbstractFactory';
import { CompanyAddress } from './models/CompanyAddress';
import { CompanyInfo } from './models/CompanyInfo';

export class CompanyFactory extends AbstractFactory {
  createInfoWithAddress(data: any) {
    const info = this.create<CompanyInfo>(CompanyInfo, data);
    if (info.address) {
      info.address = this.create<CompanyAddress>(CompanyAddress, data.address);
    }
    return info;
  }
}
