import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { makeStyles, Theme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

import { routes } from '../../routes';
import ChatSidebar from './components/ChatSidebar';
import { useRootStore } from '../../../base/hooks/useRootStore';

interface IChatScreenProps {}

const ChatScreen: React.FC<IChatScreenProps> = observer(() => {
  const classes = useStyles();
  const { chatStore } = useRootStore();
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes(routes.ChatScreen.path)) {
        chatStore.clearChatSideBar();
      }
    };
  }, [chatStore, history.location.pathname]);

  return (
    <div className={classes.wrap}>
      <ChatSidebar />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.extra.white,
    zIndex: 4,
  },
  link: {
    '&:hover': {
      color: theme.palette.black.medium,
      transition: transitions.create('color'),
    },
  },
}));

export default ChatScreen;
