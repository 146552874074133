import AbstractModel from '../../../base/AbstractModel';

export class WorkTime extends AbstractModel {
  workTime: string[] = [];
  isWeekend: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
