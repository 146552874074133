export class FormDataHelper {
  static addFileListToFormData = (files: FileList) => {
    const formData = new FormData();

    Array.from(files).forEach(file => {
      formData.append('files[]', file);
    });

    return formData;
  };
}
