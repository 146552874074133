import Loader from '../../../../components/UI/Loader';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import { Client } from '../../../../modules/role-admin/clients/models/Client';
import { ClientRecord } from '../../../../modules/role-admin/clients/models/ClientRecord';
import { useCommonStyles } from '../../../../styles/commonStyles';
import ClientRecordItem from './ClientRecordItem';
import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';

interface IClientItemProps {
  client: Client;
  clientRecords: ClientRecord[];
  onExpand: (client: Client, expanded: boolean) => void;
  recordsLoading: boolean;
  expanded: boolean;
  onOpenModal: (record: ClientRecord) => void;
}

const ClientItem: React.FC<IClientItemProps> = props => {
  const { client, clientRecords, onExpand, recordsLoading, expanded, onOpenModal } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isSM } = useAllMQ();

  const gridFr = isSM ? '1fr 1fr' : '1fr 1fr 1fr 1fr';
  const textAlign = isSM ? 'right' : 'left';

  // Handlers
  const handleExpand = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    onExpand(client, expanded);
  };

  // Renders
  const renderClientRecords = () => {
    return clientRecords.map(record => (
      <ClientRecordItem
        key={record.id}
        record={record}
        onOpenModal={onOpenModal}
        gridFr={gridFr}
        textAlign={textAlign}
      />
    ));
  };

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      onChange={handleExpand}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
      >
        <Box ml={5.5} width="100%" display="grid" gridTemplateColumns="1fr 1fr">
          <Typography className={commonClasses.body3}>{client.name}</Typography>
          <Box textAlign="right">
            <Typography className={commonClasses.body3}>{client.phone}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box width="100%">
          <Box px={2} pt={2} pb={1}>
            <Typography variant="h3" className={clsx(commonClasses.blackHigh, commonClasses.fontWeight400)}>
              Записи клиента
            </Typography>
          </Box>
          <Box p={2} width="100%" display="grid" gridTemplateColumns={gridFr}>
            <Box pr={1}>
              <Typography className={commonClasses.blackLow} variant="body1">
                Вид услуги
              </Typography>
            </Box>
            {!isSM && (
              <Box px={1}>
                <Typography className={commonClasses.blackLow} variant="body1">
                  Сотрудник
                </Typography>
              </Box>
            )}
            <Box px={1}>
              <Typography className={commonClasses.blackLow} variant="body1" align={textAlign}>
                Статус
              </Typography>
            </Box>
            {!isSM && (
              <Typography className={commonClasses.blackLow} variant="body1" align="right">
                Дата и время
              </Typography>
            )}
          </Box>

          {recordsLoading ? <Loader size={30} minHeight={100} /> : renderClientRecords()}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    margin: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    backgroundColor: 'inherit',
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    padding: theme.spacing(0.3, 2, 0.3, 0),
    '&:hover': {
      backgroundColor: theme.palette.extra.fill,
    },
  },
  expandIcon: {
    width: 20,
    height: 20,
    color: theme.palette.qsecondary['500'],
  },
  details: {
    padding: 0,
  },
}));

export default ClientItem;
