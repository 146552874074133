import { useRootStore } from '../../../base/hooks/useRootStore';
import AlertMessage from '../../../components/UI/AlertMessage';
import BackButton from '../../../components/UI/BackButton';
import EmployeeOption from '../../../components/UI/EmployeeOption';
import QIcon from '../../../components/UI/Icons/QIcon';
import { smileIcon, trashIcon } from '../../../components/UI/Icons/SvgIcons';
import Loader from '../../../components/UI/Loader';
import { SlotDurationInput } from '../../../components/UI/NumberInputs';
import QAlert from '../../../components/UI/QAlert';
import QAutocomplete from '../../../components/UI/QAutocomplete';
import QRangePicker from '../../../components/UI/QRangeDatePicker';
import QTextField from '../../../components/UI/QTextField';
import QTimePicker from '../../../components/UI/QTimePicker';
import { useAllMQ } from '../../../hooks/useAllMQ';
import { IDate, ISlotFormValues, ISlotTime } from '../../../modules/role-admin/slots/types/SlotTypes';
import { useCommonStyles } from '../../../styles/commonStyles';
import { formatRu } from '../../../utils/formatRu';
import { isSearchMatch } from '../../../utils/isSearchMatch';
import EmployeeChooseModal from './components/EmployeeChooseModal';
import SlotTimeItem from './components/SlotTimeItem';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, CircularProgress, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isValid, set } from 'date-fns';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

interface ICreateSlotScreenProps {}

const CreateSlotScreen: React.FC<ICreateSlotScreenProps> = observer(() => {
  const { slotsStore, employeeStore } = useRootStore();

  const [employeeAutocomplete, setEmployeeAutocomplete] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [values, setValues] = useState<ISlotFormValues>({
    startSchedule: null,
    endSchedule: null,
    slotDuration: null,
    employee: null,
    slotTimes: [],
    dates: [{ startDate: null, endDate: null }],
  });

  const [errors, setErrors] = useState<{ startSchedule: boolean; slotDuration: boolean }>({
    startSchedule: false,
    slotDuration: false,
  });

  const [currentRange, setCurrentRange] = useState<IDate>({
    startDate: null,
    endDate: null,
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isXS, isMD } = useAllMQ();

  const setSlotTimes = debounce((slotTimes: ISlotTime[]): void => {
    setValues(prevValues => ({ ...prevValues, slotTimes }));
  }, 700);

  const setSlots = useCallback((values: ISlotFormValues) => {
    const isValidTime = isValid(values.startSchedule) && isValid(values.endSchedule);
    const slotDuration = Number(values.slotDuration);

    const startTime = values.startSchedule?.getTime() ?? 0;
    const endTime = values.endSchedule?.getTime() ?? 0;
    const isNotLessStartTime = startTime >= endTime;

    if (isValidTime && isNotLessStartTime) {
      setErrors(prevValues => ({ ...prevValues, startSchedule: isNotLessStartTime }));

      return;
    } else {
      if (isValidTime && !isNotLessStartTime) {
        setErrors(prevValues => ({ ...prevValues, startSchedule: false }));
      }
    }

    if (slotDuration < 10 && values.slotDuration !== null) {
      setErrors(prevValues => ({ ...prevValues, slotDuration: true }));

      return;
    } else {
      setErrors(prevValues => ({ ...prevValues, slotDuration: false }));
    }

    if (isValidTime && slotDuration) {
      const slotTimes = slotsStore.createSlotTimes(
        values.startSchedule as Date,
        values.endSchedule as Date,
        slotDuration,
      );

      setSlotTimes(slotTimes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      if (slotsStore.messagesArray.length) {
        slotsStore.setErrors({});
      }
    }, 10000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsStore.messagesArray]);

  // Handlers
  const handleToggleMasterAutocomplete = (e: React.FocusEvent<HTMLInputElement>) => {
    setEmployeeAutocomplete(!employeeAutocomplete);

    if (!employeeAutocomplete) {
      setSearchText('');
    }

    if (!employeeStore.employeeList.length) {
      employeeStore.getAll();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget.value;

    setSearchText(value);
  };

  const handleChangeAutoComplete = (e: React.ChangeEvent<{}>, value: any, name: keyof typeof values) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeStartTime = (date: MaterialUiPickersDate, id: string) => {
    const changedItems = values.slotTimes.map(item => (item.id === id ? { ...item, startTime: date } : item));

    setValues({
      ...values,
      slotTimes: changedItems,
    });
  };

  const handleChangeEndTime = (date: MaterialUiPickersDate, id: string) => {
    const changedItems = values.slotTimes.map(item => (item.id === id ? { ...item, endTime: date } : item));

    setValues({
      ...values,
      slotTimes: changedItems,
    });
  };

  const handleRangeDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setCurrentRange({ startDate: start, endDate: end });

    const editedDate = { startDate: start, endDate: end };
    const newDates = values.dates.map((date, index) => (index === currentIndex ? editedDate : date));

    setValues(prevValues => ({ ...prevValues, dates: newDates }));
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    const removedItems = values.slotTimes.filter(item => item.id !== id);
    setValues({ ...values, slotTimes: removedItems });
  };

  const handleAddTime = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValues({ ...values, slotTimes: [...values.slotTimes, slotsStore.createNewSlotTimeObject(null, null)] });
  };

  const handleStartSchedule = (date: MaterialUiPickersDate) => {
    const startSchedule = date ? set(date, { seconds: 0, milliseconds: 0 }) : null;
    const newValues = { ...values, startSchedule };

    setValues(newValues);
    setSlots(newValues);
  };

  const handleEndSchedule = (date: MaterialUiPickersDate) => {
    const endSchedule = date ? set(date, { seconds: 0, milliseconds: 0 }) : null;
    const newValues = { ...values, endSchedule };

    setValues(newValues);
    setSlots(newValues);
  };

  const handleChangeSlotDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newValues = { ...values, slotDuration: value };

    setValues(newValues);
    setSlots(newValues);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    slotsStore.createSlots(values);
  };

  const handleCloseAlert = () => {
    slotsStore.setErrors({});
  };

  const handleAddRangeInput = () => {
    setValues(prevValues => ({ ...prevValues, dates: prevValues.dates.concat({ startDate: null, endDate: null }) }));
    setCurrentRange({ startDate: null, endDate: null });
    setCurrentIndex(values.dates.length);
  };

  const handleDeleteRangeInput = (index: number) => {
    if (values.dates.length > 1) {
      setValues(prevValues => ({
        ...prevValues,
        dates: prevValues.dates.filter((item, itemIndex) => itemIndex !== index),
      }));

      if (index === currentIndex) {
        setCurrentRange({ startDate: null, endDate: null });
      }

      if (index < currentIndex) {
        values.dates?.[currentIndex - 2]
          ? setCurrentRange(values.dates[currentIndex - 2])
          : setCurrentRange(values.dates[currentIndex - 1]);
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  const handleChangeCurrentDate = (date: IDate, index: number) => {
    const { startDate, endDate } = date;
    setCurrentRange({ startDate, endDate });
    setCurrentIndex(index);
  };

  //Renders
  const renderRangeList = () => {
    return values.dates.map((date, index) => {
      const startDate = date.startDate ? formatRu(date.startDate, 'eeee, d MMMM') : '';
      const endDate = date.endDate ? formatRu(date.endDate, '- eeee, d MMMM') : '';

      return (
        <Box key={index} display="flex" alignItems="center" mb={1}>
          <Box mr={1} flex={1}>
            <QTextField
              value={`${startDate} ${endDate}`}
              onClick={() => handleChangeCurrentDate(date, index)}
              fullWidth
              focused={index === currentIndex}
            />
          </Box>
          <IconButton onClick={() => handleDeleteRangeInput(index)}>
            <QIcon src={trashIcon} width={24} height={24} />
          </IconButton>
        </Box>
      );
    });
  };

  return (
    <div className={classes.wrap}>
      {!isMD && <BackButton to="/" text="Вернуться на главную" />}
      <div className={classes.card}>
        {isMD && (
          <Box mb={3} width="100%">
            <BackButton to="/" text="Вернуться на главную" />
          </Box>
        )}
        {slotsStore.loading && <Loader color="secondary" isAbsolute />}
        <form className={classes.form} onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box width="100%" maxWidth={384}>
              <Box mb={{ xxs: 4, md: 6 }}>
                <Typography className={commonClasses.blackHigh} align="center" variant="h1">
                  Создать слот
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={commonClasses.blackHigh} variant="body1">
                  Выберите сотрудника
                </Typography>
              </Box>
              <Box mb={{ xxs: 4, md: 6 }}>
                <QAutocomplete
                  label="Сотрудник"
                  open={employeeAutocomplete}
                  disablePortal
                  disablePopper
                  value={values.employee}
                  disabled={employeeStore.loading}
                  options={employeeStore.employeeList}
                  getOptionLabel={option => option?.name || ''}
                  className={classes.autocomplete}
                  ListboxProps={{
                    searchText,
                    value: values.employee,
                    open: employeeAutocomplete,
                    onSearch: handleSearch,
                    onClose: handleToggleMasterAutocomplete,
                  }}
                  loading={employeeStore.loading}
                  onFocus={handleToggleMasterAutocomplete}
                  classes={{ option: commonClasses.options, tag: commonClasses.tag }}
                  ListboxComponent={EmployeeChooseModal as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                  onChange={(e: React.ChangeEvent<{}>, value: any) => handleChangeAutoComplete(e, value, 'employee')}
                  renderOption={(option, state) =>
                    isSearchMatch(option.name, searchText) && <EmployeeOption option={option} state={state} />
                  }
                  renderInput={params => {
                    return (
                      <QTextField
                        placeholder="Введите имя"
                        btnProps={{ startIcon: smileIcon, color: 'secondary' }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {employeeStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Box>
              <Box mb={3}>
                <Typography className={commonClasses.blackHigh} variant="body1">
                  Выберите дату
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mb={2}>
              <QRangePicker
                small={isXS}
                minDate={new Date()}
                startDate={currentRange.startDate}
                endDate={currentRange.endDate}
                onChange={handleRangeDateChange}
              />
            </Box>
            <Box mb={3}>{renderRangeList()}</Box>
            <Box mb={6}>
              <Button fullWidth variant="outlined" color="secondary" onClick={handleAddRangeInput}>
                Добавить еще
              </Button>
            </Box>
            <Box width="100%" maxWidth={384}>
              <Box mb={3}>
                <Typography className={commonClasses.blackHigh} variant="body1">
                  Выберите время
                </Typography>
              </Box>
              <Box mb={3}>
                <Box mb={3}>
                  <QTimePicker
                    fullWidth
                    size="medium"
                    label="Начало смены"
                    placeholder="Укажите время"
                    value={values.startSchedule}
                    onChange={handleStartSchedule}
                    error={errors.startSchedule}
                    helperText={errors.startSchedule && 'Начало смены должно быть раньше конца смены'}
                  />
                </Box>
                <Box mb={3}>
                  <QTimePicker
                    fullWidth
                    size="medium"
                    label="Конец смены"
                    placeholder="Укажите время"
                    value={values.endSchedule}
                    onChange={handleEndSchedule}
                  />
                </Box>
                <Box mb={3}>
                  <QTextField
                    fullWidth
                    label="Длина слота, мин"
                    placeholder="Введите значение в минутах"
                    value={values.slotDuration}
                    InputProps={{
                      inputComponent: SlotDurationInput,
                      inputProps: { min: 10, max: 1440, allowNegative: false },
                    }}
                    onChange={handleChangeSlotDuration}
                    error={errors.slotDuration}
                    helperText={errors.slotDuration ? 'Длина слота не должно быть меньше 10 минут' : undefined}
                  />
                </Box>
              </Box>
              {values.slotTimes.length !== 0 && (
                <>
                  <Box mb={3}>
                    <Typography className={commonClasses.blackHigh} variant="body1">
                      Слоты
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    {values.slotTimes.map(slotTime => (
                      <SlotTimeItem
                        key={slotTime.id}
                        slotItemData={slotTime}
                        onHandleRemove={handleRemove}
                        onHandleChangeStartTime={handleChangeStartTime}
                        onHandleChangeEndTime={handleChangeEndTime}
                      />
                    ))}
                  </Box>
                  <div>
                    <Box mb={3}>
                      <Button fullWidth variant="outlined" color="secondary" onClick={handleAddTime}>
                        Добавить слот
                      </Button>
                    </Box>
                    <Button
                      disabled={!values.slotTimes.every(item => isValid(item.startTime) && isValid(item.endTime))}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                    >
                      Сохранить
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </MuiPickersUtilsProvider>
        </form>
        {slotsStore.messagesArray.length !== 0 && (
          <QAlert type="error" onClose={handleCloseAlert}>
            <AlertMessage staticPos messages={slotsStore.messagesArray} />
          </QAlert>
        )}
      </div>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    background: theme.palette.extra.white,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginTop: -1,
    background: theme.palette.extra.bg,
    padding: theme.spacing(4, 7.5, 10),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 552,
      border: `1px solid ${theme.palette.extra.stroke}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2, 6),
    },
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '11px 12px',
    background: theme.palette.extra.white,
    borderRadius: 8,
    border: `0.5px solid ${theme.palette.extra.stroke}`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    transition: transitions.create(['border-color']),
    '&:hover': {
      background: theme.palette.extra.white,
      border: `0.5px solid ${theme.palette.black.high}`,
    },
  },
  addButtonLabel: {
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: theme.spacing(2),
      width: 28,
      height: 28,
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    width: 24,
    height: 24,
    padding: 0,
  },
  timeRow: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  autocomplete: {
    cursor: 'pointer',
    '& .MuiInputBase-root, & .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  datePicker: {
    background: theme.palette.background.paper,
    top: 0,
  },
}));

export default CreateSlotScreen;
