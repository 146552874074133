import { AxiosRequestConfig } from 'axios';

import { FormDataHelper } from './helpers/FormDataHelper';
import { FileModel } from './models/File';
import { FileFactory } from './FileFactory';
import FileApiRepository from './FileApiRepository';

export default class FileService {
  fileApi: FileApiRepository;
  fileFactory: FileFactory;

  constructor() {
    this.fileApi = new FileApiRepository();
    this.fileFactory = new FileFactory();
  }

  uploadFiles = async (files: any, config?: AxiosRequestConfig): Promise<FileModel[]> => {
    const { data } = await this.fileApi.uploadFiles(files, config);
    return this.fileFactory.createList<FileModel>(FileModel, data.data);
  };

  uploadImages = async (files: FileList, config?: AxiosRequestConfig): Promise<FileModel[]> => {
    const formData = FormDataHelper.addFileListToFormData(files);

    const { data } = await this.fileApi.uploadFiles(formData, config);
    return this.fileFactory.createList<FileModel>(FileModel, data.data);
  };
}
