import clsx from 'clsx';
import { makeStyles, Button, ButtonProps, Theme } from '@material-ui/core';

interface ITextButtonProps extends ButtonProps {
  lowercase?: boolean;
  gutter?: boolean;
}

const TextButton: React.FC<ITextButtonProps> = ({ lowercase, children, className, gutter, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      disableRipple
      {...rest}
      className={clsx(className, { [classes.gutter]: gutter, [classes.lowercase]: lowercase })}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  lowercase: {
    textTransform: 'none',
    textDecoration: 'none',
  },
  gutter: {
    padding: '16px 16px',
  },
}));

export default TextButton;
