import { isSameDay } from 'date-fns';

import { formatRu } from '../../utils/formatRu';
import { stringToDate } from '../../utils/stringToDate';
import { NotificationsFactory } from './NotificationsFactory';
import { NotificationModel } from './models/NotificationModel';
import NotificationsApiRepository from './NotificationsApiRepository';
import Pagination from '../../base/modules/pagination/models/Pagination';
import { IPaginationReq } from '../../base/modules/pagination/PaginationTypes';

export default class NotificationsService {
  api: NotificationsApiRepository;
  factory: NotificationsFactory;

  constructor() {
    this.api = new NotificationsApiRepository();
    this.factory = new NotificationsFactory();
  }

  getNotificationsList = async (
    paginationReq: IPaginationReq,
  ): Promise<{ items: NotificationModel[]; pagination: Pagination; unreadNotificationsCount: number }> => {
    const { data } = await this.api.getNotificationsList(paginationReq);
    const items = this.factory.createList<NotificationModel>(NotificationModel, data.data.items);
    const pagination = this.factory.create<Pagination>(Pagination, data.data.pagination);
    const unreadNotificationsCount = data.data.unreadNotificationsCount;

    return { items, pagination, unreadNotificationsCount };
  };

  getNotificationsWithDate = (
    notificationsList: NotificationModel[],
    totalCount: number | null,
  ): NotificationModel[] => {
    return notificationsList.map((notification, index, arr) => {
      // first element (array left)
      if (totalCount && index === 0 && totalCount === notificationsList.length && notification?.createdAt) {
        return this.factory.create<NotificationModel>(NotificationModel, {
          ...notification,
          date: formatRu(notification.createdAt, 'd MMMM'),
        });
      }

      const prevElement = arr[index - 1]?.createdAt?.length ? arr[index - 1]?.createdAt : null;

      if (prevElement && notification?.createdAt?.length) {
        const prevDate = stringToDate(prevElement);
        const currentDate = stringToDate(notification.createdAt);

        return !isSameDay(prevDate, currentDate)
          ? this.factory.create<NotificationModel>(NotificationModel, {
              ...notification,
              date: formatRu(notification.createdAt, 'd MMMM'),
            })
          : notification;
      }

      return notification;
    });
  };

  getFilteredItems = (currentArray: any[], responseArray: any[]) => {
    const responseMessageIds = currentArray.map(item => String(item.id));
    const filteredResponseNotifications = responseArray.filter(item => {
      return !responseMessageIds.includes(String(item.id));
    });

    return [...filteredResponseNotifications, ...currentArray];
  };

  getUniqItems = (array: NotificationModel[]) => {
    return array.filter((notification, index, self) => self.findIndex(item => item.id === notification.id) === index);
  };
}
