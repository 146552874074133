import { stringify } from 'query-string';

export default class ParamsGenerationHelper {
  static getSerializedParams = (params: any) => {
    const stringParam = stringify(params, { skipEmptyString: true, skipNull: true, encode: true });

    if (stringParam.length) {
      return `?${stringParam}`;
    }

    return '';
  };
}
