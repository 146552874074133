import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { Chat } from './models/Chat';
import { IChatReqBody } from './types/ChatTypes';
import {
  IChatPaginationReq,
  IChatPaginationRes,
  IPaginationRes,
  IPaginationReq,
} from '../../base/modules/pagination/PaginationTypes';

export default class ChatApiRepository extends AbstractApiRepository {
  writeSupport = (data: IChatReqBody) => {
    return this.post({ url: '/company/chat/write-support', data });
  };

  getSupportChat = () => {
    return this.get<Chat>({ url: '/company/chat/get-support' });
  };

  getChatDetails = (chatId: number) => {
    return this.get({ url: `/company/chat/details/${chatId}` });
  };

  addMessage = (chatId: number, data: any) => {
    return this.post({ url: `/company/chat/${chatId}/add-message`, data });
  };

  getChatMessageHistory = (chatId: number, data: IChatPaginationReq) => {
    return this.post<IChatPaginationRes>({ url: `/company/chat/${chatId}/chat-messages`, data });
  };

  getChatList = (data: IPaginationReq) => {
    return this.post<IPaginationRes>({ url: `/company/chat/my-chats`, data });
  };

  getChatWithClientByRecordId = (recordId: number) => {
    return this.get<Chat>({ url: `/company/chat/${recordId}/get-company-chat` });
  };
}
