import { useRootStore } from '../../../base/hooks/useRootStore';
import BackButton from '../../../components/UI/BackButton';
import QIcon from '../../../components/UI/Icons/QIcon';
import { mapMarkerIcon } from '../../../components/UI/Icons/SvgIcons';
import Loader from '../../../components/UI/Loader';
import { PhoneFormat } from '../../../components/UI/NumberInputs';
import { useCustomTypography } from '../../../styles/customTypography';
import { routes } from '../../routes';
import CompanyBlockInfo from './components/CompanyBlockInfo';
import CompanyCard from './components/CompanyCard';
import CompanyDetailModal from './components/CompanyDetailModal';
import CompanyInfoModal from './components/CompanyInfoModal';
import { Box, Button, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

interface ICompanyScreenProps {}

const CompanyScreen: React.FC<ICompanyScreenProps> = observer(() => {
  const { companyStore, userStore } = useRootStore();
  const { infoCard, detailCard } = companyStore;

  const classes = useStyles();
  const typography = useCustomTypography();

  //Effects
  useEffect(() => {
    companyStore.getInfo(userStore.userInfo?.companyId);
    companyStore.getDetail(userStore.userInfo?.companyId);
  }, [companyStore, userStore.userInfo?.companyId]);

  //Handlers
  const handleEditCompanyInfo = () => {
    companyStore.setIsShowInfoModal(true);
  };
  const handleEditCompanyDetails = () => {
    companyStore.setIsShowDetailModal(true);
  };

  //Renders
  const renderCompanyInfo = () => {
    return (
      <CompanyCard>
        {companyStore.infoCardLoading && <Loader isAbsolute color="secondary" />}
        <Box display={'flex'} justifyContent="space-between" alignItems={'center'} mb={1}>
          <Typography variant={'h2'}>{infoCard?.title}</Typography>
          {infoCard?.status === 'moderation' && (
            <Box ml={1} px={1} py={0.5} textAlign={'center'} className={classes.statusLabel}>
              На модерации
            </Box>
          )}
        </Box>
        <Typography className={typography.text2}>{infoCard?.offerCategory?.title}</Typography>
        <Box my={2} display={'flex'} alignItems={'center'}>
          <QIcon src={mapMarkerIcon} width={24} height={24} />
          <Box ml={1}>
            <Typography className={typography.text}>{infoCard?.address?.formatted}</Typography>
          </Box>
        </Box>
        <Box my={4}>
          <CompanyBlockInfo title={'Описание'} subtitle1={infoCard?.description} />
        </Box>
        <Box my={4} display={'flex'} className={classes.companyBlocks}>
          <CompanyBlockInfo
            title={'Контактное лицо'}
            subtitle1={infoCard?.contactPerson}
            subtitle2={[
              <PhoneFormat value={Number(infoCard?.contactPersonPhone)} />,
              <>{infoCard?.contactPersonEmail}</>,
            ]}
          />
          <CompanyBlockInfo title={'Таймзона'} subtitle1={infoCard?.timezone} />
          <CompanyBlockInfo title={'Телефон'} subtitle1={<PhoneFormat value={Number(infoCard?.phone)} />} />
          <CompanyBlockInfo title={'Почта'} subtitle1={infoCard?.email} />
        </Box>
        <Box
          mt={4}
          display={'flex'}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          className={classes.companyBlocks}
        >
          <Box display={'flex'} className={classes.companyBlocks}>
            <CompanyBlockInfo isSecondary={true} title={'Менеджер "Когда удобно"'} subtitle1={infoCard?.manager} />
            <CompanyBlockInfo isSecondary={true} title={'Тариф'} subtitle1={String(infoCard?.fee)} />
          </Box>
          <Button color="secondary" variant="contained" onClick={handleEditCompanyInfo}>
            Редактировать
          </Button>
        </Box>
      </CompanyCard>
    );
  };

  const renderCompanyDetail = () => {
    return (
      <CompanyCard>
        {companyStore.detailCardLoading && <Loader isAbsolute color="secondary" />}
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <CompanyBlockInfo title={'Юридическое название компании'} subtitle1={detailCard?.legalName} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CompanyBlockInfo title={'ИНН'} subtitle1={detailCard?.inn} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CompanyBlockInfo title={'КПП'} subtitle1={detailCard?.kpp} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CompanyBlockInfo title={'ОГРН'} subtitle1={detailCard?.ogrn} />
            </Grid>
          </Grid>
        </Box>
        <Box py={4}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <CompanyBlockInfo title={'Юридический адрес'} subtitle1={detailCard?.legalAddress} />
            </Grid>
            <Grid item sm={12} md={6}>
              <CompanyBlockInfo title={'Фактический адрес'} subtitle1={detailCard?.actualAddress} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <CompanyBlockInfo title={'Генеральный директор'} subtitle1={detailCard?.director} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CompanyBlockInfo title={'БИК'} subtitle1={detailCard?.bik} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CompanyBlockInfo title={'Наименование банка'} subtitle1={detailCard?.bankName} />
            </Grid>
          </Grid>
        </Box>
        <Box pt={4}>
          <Grid container alignItems={'flex-end'} spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <CompanyBlockInfo title={'Корреспондентский счет'} subtitle1={detailCard?.correspondentAccount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CompanyBlockInfo title={'Рассчетный счет'} subtitle1={detailCard?.settlementAccount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                <Button color="secondary" variant="contained" onClick={handleEditCompanyDetails}>
                  Редактировать
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CompanyCard>
    );
  };

  return (
    <Box pb={13.5} position="relative" pt={{ xs: 4, lg: 10 }}>
      <Box mb={{ xs: 4, lg: 2 }} width="100%" px={2}>
        <BackButton to={routes.MainScreen.path} text="Вернуться на главную" />
      </Box>
      <Container maxWidth="xl">
        <Box mb={3}>
          <Typography variant="h1">Карточка компании</Typography>
        </Box>
        {renderCompanyInfo()}
        <Box mt={5} mb={3}>
          <Typography variant="h1">Реквизиты</Typography>
        </Box>
        {renderCompanyDetail()}
      </Container>
      <CompanyInfoModal open={companyStore.isShowInfoModal} />
      <CompanyDetailModal open={companyStore.isShowDetailModal} />
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.extra.stroke}`,
  },
  statusLabel: {
    borderRadius: 8,
    backgroundColor: theme.palette.qsecondary['50'],
  },
  companyBlocks: {
    gap: 40,
    flexWrap: 'wrap',
  },
}));

export default CompanyScreen;
