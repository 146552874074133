import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

interface IQAlertProps {
  onClose?: () => void;
  type: 'error' | 'success';
}

const QAlert: React.FC<IQAlertProps> = ({ type, children, onClose }) => {
  const classes = useStyles();

  return (
    <Alert
      className={clsx(classes.alert, type)}
      icon={false}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {children}
    </Alert>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    position: 'fixed',
    top: 52,
    left: '50%',
    borderRadius: 8,
    transform: 'translateX(-50%)',
    zIndex: 10,
    '&.error': {
      color: theme.palette.extra.white,
      background: 'rgba(232, 87, 72, 0.9)',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    '&.success': {
      background: theme.palette.extra.white,
      border: `1px solid ${theme.palette.extra.stroke}`,
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default QAlert;
