import Scrollbars from 'react-custom-scrollbars';
import { makeAutoObservable, runInAction } from 'mobx';

import { Chat } from './models/Chat';
import ChatService from './ChatService';
import { Message } from './models/Message';
import { routes } from '../../screens/routes';
import { rootStore } from '../../base/RootStore';
import { makePath } from '../../base/routes/utils/makePath';
import RouteService from '../../base/routes/service/RouteService';
import { ChatWithCreatorUser } from './models/ChatWithCreatorUser';
import { IChatPosPagination, IChatReqBody, IChatValues, PaginationPosition } from './types/ChatTypes';

export class ChatStore {
  static readonly LIMIT: number = 20;
  static readonly OFFSET: number = 20;

  loading = false;
  chatListLoading = false;
  messageHistoryLoading = false;
  hotLoading = false;
  initStatus = false;

  values: IChatValues = {
    files: null,
    message: '',
  };

  supportChat: Chat | null = null;
  supportMessage: Chat | null = null;
  newMessage: Message | null = null;
  chatDetails: ChatWithCreatorUser | null = null;
  messages: Message[] = [];

  chatList: ChatWithCreatorUser[] = [];
  currentRecordChat: Chat | null = null;

  isHasNewMessage = false;
  newMessageLength = 0;

  limit: number = ChatStore.LIMIT;
  totalCount: number | null = null;

  private top: IChatPosPagination = {
    offsetId: null,
    addOffset: 0,
  };
  private bottom: IChatPosPagination = {
    offsetId: null,
    addOffset: 0,
  };

  chatListOffset: number = 0;
  chatListLimit: number = ChatStore.LIMIT;
  chatListTotalCount: number | null = null;
  chatId: number = 0;

  isScrolled: boolean = false;

  fileHeight: number = 0;
  currentImageIndex: number = 0;
  isImageViewerOpen: boolean = false;

  private chatService: ChatService;

  constructor() {
    makeAutoObservable(this);
    this.chatService = new ChatService();
  }

  get isEndOfMessages() {
    const isEnd = this.totalCount && this.messages.length < this.totalCount;
    return !!isEnd;
  }

  get isEndOfChatList() {
    const isEnd = this.chatList.length && this.chatListTotalCount && this.chatList.length >= this.chatListTotalCount;
    return !!isEnd;
  }

  get messagesWithDate() {
    return this.chatService.getMessagesWithDate(this.messages, this.totalCount);
  }

  get messagesImages() {
    return this.chatService.getMessagesImages(this.messages);
  }

  get messagesImagesArray() {
    return this.messagesImages.map(item => item.image ?? '');
  }

  get chatRequestStatus() {
    return this.initStatus ? 'bottom' : 'init';
  }

  writeSupport = (values: IChatReqBody, chatScrollBar: React.MutableRefObject<Scrollbars | null>) => {
    this.chatService.writeSupport(values).then(supportMessage => {
      runInAction(() => {
        this.supportMessage = supportMessage;
        rootStore.fileStore.clearFiles();
        this.setFileHeight(0);
        this.getChatMessageHistory(this.chatRequestStatus).then(() => {
          const scrollHeight = chatScrollBar.current?.getScrollHeight() ?? 0;
          chatScrollBar.current?.scrollTop(scrollHeight);
        });
      });
    });
  };

  addMessage = (values: IChatReqBody, chatScrollBar: React.MutableRefObject<Scrollbars | null>) => {
    this.chatService.addMessage(this.chatId, values).then(newMessage => {
      runInAction(() => {
        this.newMessage = newMessage;
        rootStore.fileStore.clearFiles();
        this.setFileHeight(0);
        this.getChatMessageHistory(this.chatRequestStatus).then(() => {
          const scrollHeight = chatScrollBar.current?.getScrollHeight() ?? 0;
          chatScrollBar.current?.scrollTop(scrollHeight);
        });
        this.getChatListWithLoop();
      });
    });
  };

  getSupportChat = () => {
    this.setLoading(true);

    this.chatService
      .getSupportChat()
      .then(supportChat => {
        runInAction(() => {
          this.supportChat = supportChat;
          this.getChatMessageHistory('init').then(() => {
            this.setScrolled(true);
          });
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getChatDetails = () => {
    this.setLoading(true);

    this.chatService
      .getChatDetails(this.chatId)
      .then(chatDetails => {
        runInAction(() => {
          this.chatDetails = chatDetails;
          this.getChatMessageHistory('init').then(() => {
            this.setScrolled(true);
          });
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getChatWithClientByRecordId = (recordId: number) => {
    this.setLoading(true);

    this.chatService
      .getChatWithClientByRecordId(recordId)
      .then(currentRecordChat => {
        runInAction(() => {
          this.currentRecordChat = currentRecordChat;
          rootStore.slotsStore.setModalState({
            ...rootStore.slotsStore.modalState,
            record: { currentId: null, modal: false },
          });
          RouteService.push(makePath(routes.ChatRoomScreen.path, currentRecordChat.id));
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getChatList = (isLoadMore: boolean = false) => {
    if (this.isEndOfChatList) {
      return;
    }

    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setChatListLoading(true);
    }

    this.chatService
      .getChatList({
        limit: ChatStore.LIMIT,
        currentOffset: 0,
        offset: this.chatListOffset,
      })
      .then(({ items, pagination }) => {
        runInAction(() => {
          this.chatList = this.chatService.getFilteredItems(this.chatList, items);
          this.chatListTotalCount = pagination.meta.totalCount;

          if (pagination.meta.currentOffset) {
            this.chatListOffset = pagination.meta.currentOffset;
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setChatListLoading(false);
        this.setHotLoading(false);
      });
  };

  getChatListWithLoop = () => {
    this.chatService
      .getChatList({
        limit: ChatStore.LIMIT,
        currentOffset: 0,
        offset: 0,
      })
      .then(({ items, pagination }) => {
        runInAction(() => {
          const filteredItems = this.chatService.getUniqItems([...items, ...this.chatList]);

          this.chatListTotalCount = pagination.meta.totalCount;
          this.chatList = filteredItems;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getChatMessageHistory = (position: PaginationPosition) => {
    this.setMessageHistoryLoading(true);
    return this.chatService
      .getChatMessageHistory(this.getChatId(this.chatId) as number, this.getPaginationData(position))
      .then(({ messages, pagination }) => {
        runInAction(() => {
          this.totalCount = pagination.meta.totalCount;

          if (messages.length > 0) {
            if (position === 'init') {
              this.setInitStatus(true);
            }

            if (position === 'init' || position === 'top') {
              this.setOffsetTop(messages[0]?.offsetId);
            }

            if (position === 'init' || position === 'bottom') {
              this.setOffsetBottom(messages[messages.length - 1]?.offsetId);
            }

            if (position === 'bottom') {
              this.newMessageLength = messages.length;
            }
          }

          this.setMessages(position, messages);
        });
      })
      .catch(() => {})
      .finally(() => this.setMessageHistoryLoading(false));
  };

  getChatId = (chatId?: number) => {
    if (chatId) {
      return chatId;
    }
    if (this.supportChat?.id) {
      return this.supportChat.id;
    }
  };

  setValues = (values: IChatValues) => {
    this.values = values;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setChatListLoading = (value: boolean) => {
    this.chatListLoading = value;
  };

  setMessageHistoryLoading = (value: boolean) => {
    this.messageHistoryLoading = value;
  };

  // Pagination start
  setOffsetTop = (offsetId: number | null) => {
    this.top.offsetId = offsetId;
    this.top.addOffset = 0;
  };

  setOffsetBottom = (offsetId: number | null) => {
    this.bottom.offsetId = offsetId;
    this.bottom.addOffset = this.limit * -1;
  };

  getInitPos = () => {
    return {
      addOffset: (this.limit / 2) * -1,
      limit: this.limit,
    };
  };

  getTop = () => {
    return {
      offsetId: this.top.offsetId,
      addOffset: this.top.addOffset,
      limit: this.limit,
    };
  };

  getBottom = () => {
    return {
      offsetId: this.bottom.offsetId,
      addOffset: this.bottom.addOffset,
      limit: this.limit,
    };
  };

  getPaginationData = (position: PaginationPosition) => {
    switch (position) {
      case 'init':
        return this.getInitPos();
      case 'top':
        return this.getTop();
      case 'bottom':
        return this.getBottom();
    }
  };
  // Pagination end

  setMessages = (position: PaginationPosition, responseMessages: Message[]) => {
    switch (position) {
      case 'init':
        this.messages = responseMessages;
        break;
      case 'top':
        this.messages = [...responseMessages, ...this.messages];
        break;
      case 'bottom':
        this.messages = [...this.messages, ...responseMessages];
        break;
    }
  };

  setInitStatus = (value: boolean) => {
    this.initStatus = value;
  };

  setScrolled = (value: boolean) => {
    this.isScrolled = value;
  };

  setFileHeight = (height: number) => {
    this.fileHeight = height;
  };

  setCurrentImageIndex = (currentImageIndex: number) => {
    this.currentImageIndex = currentImageIndex;
  };

  setImageViewerOpen = (isImageViewerOpen: boolean) => {
    this.isImageViewerOpen = isImageViewerOpen;
  };

  setChatId = (chatId: number) => {
    this.chatId = chatId;
  };

  setHotLoading = (value: boolean) => {
    this.hotLoading = value;
  };

  setNewMessageLength = (value: number) => {
    this.newMessageLength = value;
  };

  clearSupportChat = () => {
    this.supportChat = null;
  };

  clearChatSideBar = () => {
    this.chatListOffset = 0;
    this.chatList = [];
  };

  clearChatValues = () => {
    this.values = {
      files: null,
      message: '',
    };
  };

  clearStore = () => {
    this.loading = false;
    this.messageHistoryLoading = false;
    this.isHasNewMessage = false;
    this.clearChatValues();
    this.supportChat = null;
    this.supportMessage = null;
    this.newMessage = null;
    this.currentRecordChat = null;
    this.newMessageLength = 0;

    this.chatDetails = null;
    this.messages = [];
    this.limit = ChatStore.LIMIT;
    this.totalCount = null;
    this.isScrolled = false;
    this.initStatus = false;

    this.chatListOffset = 0;
    this.chatListLimit = ChatStore.LIMIT;
    this.chatListTotalCount = null;
    this.chatId = 0;
    this.fileHeight = 0;
    this.currentImageIndex = 0;
    this.isImageViewerOpen = false;
  };
}
