import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';

import { routes } from '../../routes';
import { useAllMQ } from '../../../hooks/useAllMQ';
import ChatContent from './components/ChatContent';
import ChatSidebar from './components/ChatSidebar';
import { useRootStore } from '../../../base/hooks/useRootStore';

interface IChatRoomScreenProps {}

const ChatRoomScreen: React.FC<IChatRoomScreenProps> = observer(() => {
  const classes = useStyles();
  const { isMD } = useAllMQ();
  const history = useHistory();
  const { chatStore } = useRootStore();

  useEffect(() => {
    window.addEventListener('keydown', removeRoute);

    return () => {
      window.removeEventListener('keydown', removeRoute);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.location.pathname !== routes.ChatScreen.path) {
        chatStore.clearChatSideBar();
      }
    };
  }, [chatStore, history.location.pathname]);

  // Handlers
  const removeRoute = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && history.location.pathname !== routes.ChatScreen.path) {
      history.push(routes.ChatScreen.path);
    }
  };

  return (
    <div className={classes.wrap}>
      {!isMD && <ChatSidebar />}
      <ChatContent />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.extra.white,
    zIndex: 4,
  },
}));

export default ChatRoomScreen;
