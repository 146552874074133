import { useRootStore } from '../../../base/hooks/useRootStore';
import SideBar from '../../../components/SideBar';
import { useAllMQ } from '../../../hooks/useAllMQ';
import MiniHeader from './components/MiniHeader';
import AdminSlotTable from './components/SlotTable';
import { SwipeableDrawer, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

const MainScreen: React.FC = observer(() => {
  const { isMD } = useAllMQ();
  const { headerStore, adminMainStore } = useRootStore();

  // Effects
  useEffect(() => {
    return () => {
      adminMainStore.setSelectedDate(new Date());
    };
  }, [adminMainStore]);

  // Handlers
  const handleToggleSidebar = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    headerStore.setIsShowSidebar();
  };

  return (
    <Box display="flex">
      {!isMD && <SideBar selectedDate={adminMainStore.selectedDate} onSelectedDate={adminMainStore.setSelectedDate} />}
      <div>
        <MiniHeader />
        <AdminSlotTable />
      </div>
      {isMD && (
        <SwipeableDrawer open={headerStore.isShowSidebar} onClose={handleToggleSidebar} onOpen={handleToggleSidebar}>
          <SideBar selectedDate={adminMainStore.selectedDate} onSelectedDate={adminMainStore.setSelectedDate} />
        </SwipeableDrawer>
      )}
    </Box>
  );
});

export default MainScreen;
