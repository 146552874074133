import AbstractModel from '../../../base/AbstractModel';
import { IAttachFiles, IMember, IMessageRead } from '../types/ChatTypes';

export class Message extends AbstractModel {
  id: number | null = null;
  offsetId: number | null = null;
  chatId: number | null = null;
  createdAt: string | null = null;
  type: string | null = null;
  status: string | null = null;
  body: string | null = null;
  member: IMember | null = null;
  attachFiles: IAttachFiles[] = [];
  date: string | null = null;
  read: IMessageRead = {
    byCompanion: false,
    byMe: false,
  };

  constructor(props: any) {
    super();
    this.load(props);
  }
}
