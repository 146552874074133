import { PushFactory } from './PushFactory';
import PushApiRepository from './PushApiRepository';

export default class PushService {
  pushApi: PushApiRepository;
  pushFactory: PushFactory;

  constructor() {
    this.pushApi = new PushApiRepository();
    this.pushFactory = new PushFactory();
  }

  saveToken = async (token: string) => {
    return this.pushApi.saveToken(token);
  };
}
