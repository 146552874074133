import AbstractModel from '../../../base/AbstractModel';
import { OffersGuide } from './OffersGuide';

export class OfferWithCategory extends AbstractModel {
  category: { id: number; title: string } | null = null;
  offers: OffersGuide[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
