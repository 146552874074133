import { Box, makeStyles, Theme } from '@material-ui/core';

interface ICompanyCardProps {}

const CompanyCard: React.FC<ICompanyCardProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardWrapper} p={3}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    position: 'relative',
    borderRadius: 16,
    border: `1px solid ${theme.palette.extra.stroke}`,
  },
}));

export default CompanyCard;
