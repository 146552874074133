import AbstractModel from '../../../../base/AbstractModel';
import { IClientUserInfo } from '../types/ClientsTypes';

export class Client extends AbstractModel {
  uuid: string | null = null;
  createdAt: string | null = null;
  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  comment: string | null = null;
  user: IClientUserInfo | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
