import { User } from './models/User';
import { IUpdatePassword } from './types/UserTypes';
import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';

export default class UserApiRepository extends AbstractApiRepository {
  getUserInfo = () => {
    return this.get<User>({ url: '/company/user/info' });
  };

  udpatePassword = (data: IUpdatePassword) => {
    return this.post({ url: '/company/user/update-password', data });
  };

  setTimezone = (timezone: string) => {
    return this.post({ url: '/user/set-timezone', data: { timezone } });
  };
}
