import { makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme: Theme) => ({
  routerLink: {
    textDecoration: 'none',
  },
  blackHigh: {
    color: theme.palette.black.high,
  },
  blackMedium: {
    color: theme.palette.black.medium,
  },
  blackLow: {
    color: theme.palette.black.low,
  },
  extraWhite: {
    color: theme.palette.extra.white,
  },
  extraFill: {
    color: theme.palette.extra.fill,
  },
  options: {
    padding: '0 !important',
    background: 'transparent !important',
    '&:not(:last-child)': {
      '& > div': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  tag: {
    ...theme.typography.caption,
    maxWidth: '200px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '130px !important',
    },
  },
  cursorPointerAutocomplete: {
    cursor: 'pointer',
    '& .MuiInputBase-root, & .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  body3: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  labelSmall: {
    ...theme.typography.caption,
    fontWeight: 500,
    color: theme.palette.black.medium,
  },
}));
