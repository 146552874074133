import AbstractDto from '../../AbstractDto';

export default class PaginationDto extends AbstractDto {
  limit: number = 20;
  offset: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
