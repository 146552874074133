import { min } from 'date-fns/esm';
import { observer } from 'mobx-react-lite';
import { addDays, differenceInDays, subDays } from 'date-fns';
import { Box, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import { useAllMQ } from '../../../../hooks/useAllMQ';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import { formatRu } from '../../../../utils/formatRu';

interface IMiniHeaderProps {}

const MiniHeader: React.FC<IMiniHeaderProps> = observer(() => {
  const { masterMainStore } = useRootStore();
  const { selectedDate, setSelectedDate } = masterMainStore;
  const startDay = formatRu(selectedDate, 'eeee, d MMMM');
  const endDate = formatRu(addDays(selectedDate, 6), 'd MMMM yyyy г.');
  const minDate = min([new Date()]);
  const isNotLessCurrentDay = differenceInDays(selectedDate, minDate) <= 5;

  const classes = useStyles();
  const { isXS } = useAllMQ();

  // Hanlders
  const handleDecrementDay = () => {
    const value = isNotLessCurrentDay ? new Date() : subDays(selectedDate, 7);

    setSelectedDate(value);
  };

  const handleIncrementDay = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  return (
    <div className={classes.root}>
      <Box mr={{ xxs: 1, md: 2 }}>
        <IconButton
          disabled={isNotLessCurrentDay}
          onClick={handleDecrementDay}
          classes={{ label: classes.btnLabel }}
          className={classes.btn}
        >
          <ChevronLeftRoundedIcon className={classes.btnIcon} />
        </IconButton>
      </Box>
      <IconButton onClick={handleIncrementDay} classes={{ label: classes.btnLabel }} className={classes.btn}>
        <ChevronRightRoundedIcon className={classes.btnIcon} />
      </IconButton>
      <Box ml={{ xxs: 1, md: 2.5 }}>
        <Typography variant={isXS ? 'h4' : 'h3'}>
          {startDay} - {endDate}
        </Typography>
      </Box>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: theme.palette.extra.fill,
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    padding: 0,
    borderRadius: 20,
    color: theme.palette.black.high,
    background: theme.palette.extra.white,
    border: `1px solid ${theme.palette.extra.stroke}`,
    [theme.breakpoints.down('xs')]: {
      width: 32,
      height: 32,
    },
  },
  btnLabel: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
    },
  },
  btnIcon: {
    width: '100%',
    height: '100%',
  },
  week: {
    textTransform: 'capitalize',
  },
}));
export default MiniHeader;
