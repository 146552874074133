import { useRootStore } from '../../../../base/hooks/useRootStore';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import { formatRu } from '../../../../utils/formatRu';
import { Box, Button, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { addDays, subDays } from 'date-fns';
import { observer } from 'mobx-react';

interface IMiniHeaderProps {}

const MiniHeader: React.FC<IMiniHeaderProps> = observer(() => {
  const { adminMainStore, slotsStore } = useRootStore();
  const { selectedDate, setSelectedDate } = adminMainStore;
  const { selectedCells } = slotsStore;

  const fullDate = formatRu(selectedDate, 'eeee, d MMMM, yyyy г.');

  const classes = useStyles();
  const { isXS } = useAllMQ();

  const handleDecrementDay = () => {
    setSelectedDate(subDays(selectedDate, 1));
  };

  const handleIncrementDay = () => {
    setSelectedDate(addDays(selectedDate, 1));
  };

  const handleDeleteSelectedSlotsModalOpen = () => {
    slotsStore.setModalOpen('deleteSelectedConfirmModal', true);
  };

  return (
    <div className={classes.root}>
      <Box mr={{ xxs: 1, md: 2 }}>
        <IconButton onClick={handleDecrementDay} classes={{ label: classes.btnLabel }} className={classes.btn}>
          <ChevronLeftRoundedIcon className={classes.btnIcon} />
        </IconButton>
      </Box>
      <IconButton onClick={handleIncrementDay} classes={{ label: classes.btnLabel }} className={classes.btn}>
        <ChevronRightRoundedIcon className={classes.btnIcon} />
      </IconButton>
      <Box ml={{ xxs: 1, md: 2.5 }} mr={2.5}>
        <Typography variant={isXS ? 'h4' : 'h3'}>{fullDate}</Typography>
      </Box>

      {!!selectedCells.size && (
        <Box width={{ xxs: '100%', sm: 'auto' }} mt={{ xxs: 2, sm: 0 }}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.deleteSlotsBtn}
            onClick={handleDeleteSelectedSlotsModalOpen}
          >
            Удалить слоты
          </Button>
        </Box>
      )}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    background: theme.palette.extra.fill,
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    padding: 0,
    borderRadius: 20,
    color: theme.palette.black.high,
    background: theme.palette.extra.white,
    border: `1px solid ${theme.palette.extra.stroke}`,
    [theme.breakpoints.down('xs')]: {
      width: 32,
      height: 32,
    },
  },
  btnLabel: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
    },
  },
  btnIcon: {
    width: '100%',
    height: '100%',
  },
  week: {
    textTransform: 'capitalize',
  },
  deleteSlotsBtn: {
    padding: '9px 16px',
  },
}));
export default MiniHeader;
