import AbstractModel from '../../../../base/AbstractModel';
import { FileModel } from '../../../file/models/File';

export class PhotoModel extends AbstractModel {
  file: FileModel | null = null;
  id: number | null = null;
  main: boolean | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
