import { format } from 'date-fns';

import { Report } from './models/Report';
import { ReportFactory } from './ReportFactory';
import CreateReportdDto from './forms/CreateReportDto';
import ReportApiRepository from './ReportApiRepository';
import { IMasterReportFormValues, IReportFormValues } from './types/ReportTypes';
import { ReportResponse } from './models/ReportResponse';

export default class ReportService {
  reportApi: ReportApiRepository;
  reportFactory: ReportFactory;

  constructor() {
    this.reportApi = new ReportApiRepository();
    this.reportFactory = new ReportFactory();
  }

  getReportById = async (reportId: number) => {
    const { data } = await this.reportApi.getReportById(reportId);
    return this.reportFactory.create<Report>(Report, data.data);
  };

  createReport = async (values: IReportFormValues) => {
    const preDto = {
      companyOffersIds: this.getIds(values.offers),
      companyEmployeesIds: this.getIds(values.employees),
      period: this.getPeriod(values),
    };

    const reportDto = CreateReportdDto.populate({ ...values, ...preDto }) as CreateReportdDto;

    const { data } = await this.reportApi.createReport(reportDto);
    return this.reportFactory.create<ReportResponse>(ReportResponse, data.data);
  };

  createMasterReport = async (values: IMasterReportFormValues) => {
    const preDto = {
      companyOffersIds: this.getIds(values.offers),
      companyEmployeesIds: values.employees,
      period: this.getPeriod(values),
    };

    const reportDto = CreateReportdDto.populate({ ...values, ...preDto }) as CreateReportdDto;

    const { data } = await this.reportApi.createReport(reportDto);
    return this.reportFactory.create<ReportResponse>(ReportResponse, data.data);
  };

  private getIds = (items: any[]): number[] => items.map(item => item?.id);

  private getPeriod = (values: IMasterReportFormValues | IReportFormValues) => {
    const getEndDate = () => {
      if (values.date.startDate && values.date.endDate === null) {
        return format(values.date.startDate, 'yyyy-MM-dd');
      }

      if (values.date.endDate) {
        return format(values.date.endDate, 'yyyy-MM-dd');
      }
    };

    return {
      startDate: values.date.startDate && format(values.date.startDate, 'yyyy-MM-dd'),
      endDate: getEndDate(),
    };
  };
}
