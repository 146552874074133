import clsx from 'clsx';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import BackButton from './UI/BackButton';
import { routes } from '../screens/routes';
import { useAllMQ } from '../hooks/useAllMQ';
import { useCommonStyles } from '../styles/commonStyles';

interface IContentContainerProps {
  title: string;
  wider?: boolean;
}

const ContentContainer: React.FC<IContentContainerProps> = ({ title, wider, children }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isMD } = useAllMQ();

  return (
    <div className={classes.wrap}>
      {!isMD && <BackButton to={routes.MainScreen.path} text="Вернуться на главную" />}
      <div className={clsx(classes.card, { wider })}>
        {isMD && (
          <Box mb={3} width="100%">
            <BackButton to={routes.MainScreen.path} text="Вернуться на главную" />
          </Box>
        )}
        <Box mb={{ xxs: 4, md: 8 }}>
          <Typography align="center" variant="h1" className={commonClasses.blackHigh}>
            {title}
          </Typography>
        </Box>
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    background: theme.palette.extra.white,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    marginTop: -1,
    background: theme.palette.extra.bg,
    padding: theme.spacing(4, 10.5, 10),
    minHeight: 'calc(100vh - 79px)',
    '&.wider': {
      padding: theme.spacing(4, 7.5, 10),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 552,
      border: `1px solid ${theme.palette.extra.stroke}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 3, 6),
    },
  },
}));

export default ContentContainer;
