import AbstractFactory from '../../../base/AbstractFactory';
import { Employee } from '../employee/models/Employee';
import { Slot } from './models/Slot';

export class SlotsFactory extends AbstractFactory {
  createSlot(data: any): Slot {
    const companyEmployee = this.create<Employee>(Employee, data.companyEmployee);
    return this.create<Slot>(Slot, { ...data, companyEmployee });
  }

  createSlotList(data: any[]): Slot[] {
    const slotList = data.map(item => {
      item.companyEmployee = this.create(Employee, item.companyEmployee);
      return item;
    });
    return this.createList<Slot>(Slot, slotList);
  }
}
