import AbstractModel from '../../../base/AbstractModel';
import { Message } from './Message';

export class Chat extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  type: string | null = null;
  createdAt: string | null = null;
  lastMessage: Message | null = null;
  unreadMessagesCount: number = 0;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
