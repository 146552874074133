import { makeAutoObservable, runInAction } from 'mobx';

import PushService from './PushService';
import { rootStore } from '../../base/RootStore';
import { INotification } from './types/PushTypes';
import { _firebase } from '../../firebase/firebase';
import NotificationUtil from '../../utils/NotificationUtil';

export class PushStore {
  loading = false;
  notification: INotification = { data: null, payload: null };
  currentToken: string | null = null;

  private pushService: PushService;

  constructor() {
    makeAutoObservable(this);
    this.pushService = new PushService();
  }

  saveToken = (token: string | null) => {
    if (token) {
      this.pushService.saveToken(token);
      this.setCurrentToken(token);
    }
  };

  register = async () => {
    try {
      const token = await _firebase?.messaging().getToken();

      if (token) {
        this.saveToken(token);
      }
    } catch (error: any) {
      if (error?.code === 'messaging/permission-blocked') {
        NotificationUtil.showError('Разрешите отправлять уведомление!');
        return;
      }

      NotificationUtil.showError('Что-то пошло не так!');
    }
  };

  subscribe = (cb?: () => void) => {
    return _firebase?.messaging().onMessage(remoteMessage => {
      runInAction(() => {
        if (remoteMessage?.notification && remoteMessage?.data?.payload) {
          console.log('push subscribe');

          if (cb) {
            cb();
          }

          const notification: INotification = {
            data: remoteMessage.notification,
            payload: JSON.parse(remoteMessage?.data?.payload),
          };
          this.notification = notification;
          rootStore.notificationsStore.updateNotificationsList();
        }
      });
    });
  };

  unsubscribe = () => {
    return this.subscribe();
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setCurrentToken = (currentToken: string | null) => {
    this.currentToken = currentToken;
  };
}
