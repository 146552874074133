import { makeAutoObservable } from 'mobx';

import AuthService from './AuthService';
import { SMSType } from './types/AuthTypes';
import { authRotes } from '../../screens/routes';
import { rootStore } from '../../base/RootStore';
import { TokenStore } from '../token/TokenStore';
import { LoginStore } from './modules/login/LoginStore';
import ResendCodeTimer from '../../services/ResendCodeTimer';
import RouteService from '../../base/routes/service/RouteService';
import { addRussianCountryPhoneCode } from '../../utils/addRussianCountryPhoneCode';

export const SMS_CODE_LENGTH = 4;
export const RESEND_CODE_TIMEOUT = 25; //sec

export class AuthStore {
  loading = false;
  errorMessages: any = {};

  isAuth = false;
  completeCheckAuth = false;

  phone = '';

  resendCodeTime = 0;
  smsToken = '';
  smsCode = '';

  private authService: AuthService;
  private timer: ResendCodeTimer;

  loginStore: LoginStore;
  tokenStore: TokenStore;

  constructor() {
    makeAutoObservable(this);
    this.authService = new AuthService();
    this.timer = new ResendCodeTimer(this);

    this.loginStore = new LoginStore(this);
    this.tokenStore = new TokenStore();
  }

  sendSMS = (type: SMSType, path?: string) => {
    this.setLoading(true);

    this.authService
      .sendSMS(type, this.phone)
      .then(data => {
        if (data.token) {
          if (path) {
            RouteService.push(path);
          }

          this.setSMSToken(data.token);
          this.restartResendCodeTimer();
        }
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;
        errors && this.setErrors(errors);
      })
      .finally(() => this.setLoading(false));
  };

  validateSMSCode = () => {
    this.setLoading(true);

    const data = {
      token: this.smsToken,
      code: this.smsCode,
    };

    this.authService
      .validateSMSCode(data)
      .then(() => RouteService.push(authRotes.ResetPasswordSetNewScreen.path))
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);

          if (errors.hasOwnProperty('code')) {
            this.setSMSCode('');
          }
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setLoading(false));
  };

  logout = () => {
    this.setLoading(true);
    this.authService
      .logout()
      .then(() => {
        this.tokenStore.deleteToken();
        this.resetStore();
        RouteService.push(authRotes.LoginScreen.path);
        rootStore.userStore.clearUserInfo();
        rootStore.employeeStore.clearStore();
        rootStore.employeeStore.clearEmployeeList();
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setResendCodeTime = (time: number) => {
    this.resendCodeTime = time;
  };

  restartResendCodeTimer = () => {
    this.setResendCodeTime(RESEND_CODE_TIMEOUT);
    this.timer.start();
  };

  setSMSToken = (token: string) => {
    this.smsToken = token;
  };

  setSMSCode = (code: string) => {
    this.smsCode = code;
  };

  setPhone = (phone: string) => {
    this.phone = addRussianCountryPhoneCode(phone);
  };

  checkAuth = () => {
    const accessToken = this.tokenStore.getToken();

    if (accessToken) {
      this.tokenStore.setToken(accessToken);
      this.isAuth = true;
      this.completeCheckAuth = true;
      return;
    }

    this.isAuth = false;
    this.completeCheckAuth = true;
  };

  setCompleteCheckAuth = (value: boolean) => {
    this.completeCheckAuth = value;
  };

  setIsAuth = (state: boolean) => {
    this.isAuth = state;
  };

  setErrors = (errors: Object) => {
    this.errorMessages = errors;
  };

  removeError = (fieldName: string) => {
    delete this.errorMessages[fieldName];
  };

  resetStore = () => {
    this.loading = false;
    this.errorMessages = {};

    this.isAuth = false;
    this.completeCheckAuth = true;

    this.resendCodeTime = 0;
    this.smsToken = '';
    this.smsCode = '';
  };
}
