export const hoursDeclination = ['час', 'часа', 'часов'];
export const minutesDeclination = ['минута', 'минуты', 'минут'];
export const INCLINED_MONTHS = [
  'января',
  'февраля',
  'мартa',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августa',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
