import { useRootStore } from '../base/hooks/useRootStore';
import roles from '../base/routes/roles';
import { IDatePickerCustomHeaderParams } from '../base/types/BaseTypes';
import { useAllMQ } from '../hooks/useAllMQ';
import { routes } from '../screens/routes';
import { useCommonStyles } from '../styles/commonStyles';
import QDatePicker from './UI/QDatePicker';
import { Box, Button, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

interface ISidebarProps {
  selectedDate: Date;
  onSelectedDate: (date: Date) => void;
}

const Sidebar: React.FC<ISidebarProps> = observer(props => {
  const { selectedDate, onSelectedDate } = props;
  const { headerStore, userStore } = useRootStore();
  const history = useHistory();

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isMD } = useAllMQ();

  // Handlers
  const handleToggleSidebar = () => {
    headerStore.setIsShowSidebar();
  };

  const handlePushRoute = () => {
    history.push(routes.CreateSlotScreen.path);
    headerStore.setIsShowSidebar();
  };

  // Renders
  const renderCreateBtn = () => {
    if (userStore.userInfo && userStore.userInfo.role === roles.administrator.role) {
      return (
        <Box maxWidth={188} mb={6}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.createBtn}
            startIcon={<AddRoundedIcon />}
            onClick={handlePushRoute}
          >
            Создать слот
          </Button>
        </Box>
      );
    }
  };

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }: IDatePickerCustomHeaderParams) => {
    const month = date.toLocaleString('ru-RU', { month: 'long' });
    const year = date.getFullYear();

    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <IconButton onClick={decreaseMonth} className={classes.iconBtn} color="secondary">
            <ChevronLeft />
          </IconButton>
          <Box>
            <Typography className={clsx(commonClasses.blackHigh, classes.date)} variant="body2">
              {month}, {year}
            </Typography>
          </Box>
          <IconButton onClick={increaseMonth} className={classes.iconBtn} color="secondary">
            <ChevronRight />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <aside className={classes.sidebar}>
      <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
        {userStore.userInfo?.companyName && (
          <Typography variant="body1" className={commonClasses.blackHigh}>
            {userStore.userInfo.companyName}
          </Typography>
        )}
        {isMD && (
          <IconButton className={classes.iconBtn} onClick={handleToggleSidebar} color="secondary">
            <CloseRoundedIcon />
          </IconButton>
        )}
      </Box>
      {renderCreateBtn()}
      <Box ml={-1} pb={3}>
        <QDatePicker
          small
          inline
          selected={selectedDate}
          onChange={onSelectedDate}
          renderCustomHeader={renderCustomHeader}
        />
      </Box>
    </aside>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    position: 'relative',
    zIndex: 3,
    maxWidth: 320,
    height: '100vh',
    padding: theme.spacing(3),
    background: theme.palette.extra.bg,
    border: `1px solid ${theme.palette.extra.stroke}`,
    borderBottom: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(-10),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  date: {
    textTransform: 'capitalize',
  },
  createBtn: {
    padding: '9px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  iconBtn: {
    padding: 0,
  },
}));

export default Sidebar;
