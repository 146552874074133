import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useRef } from 'react';

import QIcon from '../../../../components/UI/Icons/QIcon';
import { addPhotoIcon } from '../../../../components/UI/Icons/SvgIcons';
import { useCommonStyles } from '../../../../styles/commonStyles';

interface IAddPhotoProps {
  onChange: (file: FileList) => void;
  isMainPhoto?: boolean;
}

const AddPhoto: React.FC<IAddPhotoProps> = props => {
  const { onChange, isMainPhoto } = props;
  const ref = useRef<any>();

  const commonClasses = useCommonStyles();
  const classes = useStyles();

  // Handlers
  const handleClick = () => {
    ref.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      onChange(files);
    }
  };

  // Renders
  return (
    <div onClick={handleClick} className={classes.addPhotoContainer}>
      <Grid container alignItems="center" direction="column">
        <Box mb={1}>
          <QIcon className={commonClasses.blackHigh} src={addPhotoIcon} width={24} height={24} />
        </Box>
        <Typography className={commonClasses.blackHigh} variant="body1">
          Добавить изображение
        </Typography>
        <input onChange={handleChange} ref={ref} className={classes.input} type="file" multiple={!isMainPhoto} />
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addPhotoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '.2s all linear',
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 8,
    height: '100%',
    width: '100%',
  },
  input: {
    display: 'none',
  },
}));

export default AddPhoto;
