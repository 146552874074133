import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import packageJson from '../../package.json';
import { useCommonStyles } from '../styles/commonStyles';

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <footer className={classes.footer}>
      <Box mr={1}>
        <Typography className={commonClasses.blackLow} variant="body2">
          «Когда удобно», 2022
        </Typography>
      </Box>
      <Typography className={commonClasses.blackMedium} variant="caption">
        Version {packageJson.version}
      </Typography>
    </footer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: theme.spacing(3),
    zIndex: 3,
  },
}));

export default Footer;
