import AbstractModel from '../../../../base/AbstractModel';
import { IImgData } from '../../../../base/types/BaseTypes';

export class EmployeeUser extends AbstractModel {
  id: number = 0;
  name: string = '';
  phone: string = '';
  email: string = '';
  status: 'active' | 'blocked' = 'active';
  timezone: string | null = null;
  photo: IImgData | null = null;
  pushNotifications: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
