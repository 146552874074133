import { makeAutoObservable, runInAction } from 'mobx';

import { FileModel } from './models/File';
import FileService from './FileService';
import { rootStore } from '../../base/RootStore';

export class FileStore {
  loading = false;

  files: FileModel[] = [];

  progress: number = 0;

  private fileService: FileService;

  constructor() {
    makeAutoObservable(this);
    this.fileService = new FileService();
  }

  get filesUuid() {
    return this.files.map(item => ({ uuid: item.uuid as string }));
  }

  get filesIdsArray() {
    return this.files.map(item => item.uuid ?? '');
  }

  uploadFiles = (files: any) => {
    this.setLoading(true);

    return this.fileService
      .uploadFiles(files, {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setProgress(percentCompleted);
        },
      })
      .then(files => {
        runInAction(() => {
          this.files = [...this.files, ...files];
        });

        return files;
      })
      .catch(() => {
        rootStore.chatStore.clearChatValues();
      })
      .finally(() => this.setLoading(false));
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setProgress = (progress: number) => {
    this.progress = progress;
  };

  deleteFileById = (id: string) => {
    const filteredFiles = this.files.filter(file => file.uuid !== id);
    this.files = filteredFiles;
  };

  clearFiles = () => {
    this.files = [];
  };
}
