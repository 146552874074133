import clsx from 'clsx';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Box, DialogProps, Typography } from '@material-ui/core';

import QModal from '../../../../components/UI/QModal';
import QIcon from '../../../../components/UI/Icons/QIcon';
import LoaderButton from '../../../../components/UI/LoaderButton';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import { fileCheck } from '../../../../components/UI/Icons/SvgIcons';
import { useCustomTypography } from '../../../../styles/customTypography';
import { IReportFormValues } from '../../../../modules/user/modules/types/ReportTypes';

interface IReportSuccessModalProps extends DialogProps {
  values: Omit<IReportFormValues, 'employees'>;
}

const ReportSuccessModal: React.FC<IReportSuccessModalProps> = observer(props => {
  const { reportStore, userStore } = useRootStore();
  const { values } = props;

  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  const startDate = values.date.startDate && format(values.date.startDate, 'dd.MM.yy');
  const endDate = values.date.endDate ? format(values.date.endDate, 'dd.MM.yy') : startDate;

  const handleDownload = () => {
    if (reportStore.reportId) {
      reportStore.getReportById(reportStore.reportId);
    }
  };

  return (
    <QModal {...props}>
      <Box mb={5}>
        <Typography align="center" className={commonClasses.blackHigh} variant="h2">
          Отчёт сформирован
        </Typography>
      </Box>
      <Box mb={4} className={commonClasses.blackLow} display="flex" alignItems="center" justifyContent="center">
        <QIcon width={75} height={75} src={fileCheck} />
      </Box>
      <Box mb={7.25}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={1.25}>
          <Box mr={1}>
            <Typography className={commonClasses.blackHigh} variant="subtitle1">
              Дата:
            </Typography>
          </Box>
          {startDate && (
            <Typography className={clsx(commonClasses.blackHigh, typography.text)}>
              {startDate}-{endDate}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mr={1}>
            <Typography className={commonClasses.blackHigh} variant="subtitle1">
              Сотрудник:
            </Typography>
          </Box>
          <Typography className={clsx(commonClasses.blackHigh, typography.text)}>{userStore.userInfo?.name}</Typography>
        </Box>
      </Box>

      <LoaderButton
        fullWidth
        type="submit"
        color="secondary"
        variant="contained"
        loading={reportStore.reportLoading}
        onClick={handleDownload}
      >
        Скачать отчёт
      </LoaderButton>
    </QModal>
  );
});

export default ReportSuccessModal;
