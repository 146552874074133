import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { ICompleteRecordValues } from './types/RecordTypes';
import CreateRecordDto from './forms/CreateRecordDto';
import { CRecord } from './models/Record';

export default class RecordsApiRepository extends AbstractApiRepository {
  getRecordById = (companyId: number, recordId: number) => {
    return this.get<CRecord>({ url: `/company/${companyId}/records/${recordId}` });
  };

  getAdminSchedule = (companyId: number, date: string) => {
    return this.post({ url: `/company/${companyId}/schedule`, data: { date } });
  };

  getMasterSchedule = (companyId: number, date: string) => {
    return this.post({ url: `/company/${companyId}/my-schedule`, data: { date } });
  };

  cancelRecordById = (companyId: number, recordId: number) => {
    return this.post({ url: `/company/${companyId}/records/${recordId}/cancel` });
  };

  completeRecordById = (companyId: number, recordId: number, data: ICompleteRecordValues) => {
    return this.post({ url: `/company/${companyId}/records/${recordId}/complete`, data });
  };

  getWorkTime = (companyId: number, date: string) => {
    return this.post({ url: `/company/${companyId}/schedule-work-time`, data: { date } });
  };

  createRecord = (companyId: number, data: CreateRecordDto) => {
    return this.post({ url: `/company/${companyId}/records`, data });
  };
}
