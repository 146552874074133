import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

export const BaseTextMaskInput = (props: any, mask: MaskedInputProps['mask'], otherProps?: MaskedInputProps) => {
  const { ...rest } = props;

  return <MaskedInput {...rest} mask={mask} placeholderChar={otherProps?.placeholderChar || '_'} {...otherProps} />;
};

export const EmailMaskInput = (props: any) => {
  return BaseTextMaskInput(props, emailMask);
};
