import { useRootStore } from '../../../../../base/hooks/useRootStore';
import { settingIcon, smileIcon, timeIcon, circleCheck, fileCheck } from '../../../../../components/UI/Icons/SvgIcons';
import Loader from '../../../../../components/UI/Loader';
import QAutocomplete from '../../../../../components/UI/QAutocomplete';
import QModal from '../../../../../components/UI/QModal';
import QTextField from '../../../../../components/UI/QTextField';
import RecordStatusTag from '../../../../../components/UI/RecordStatusTag';
import TextButton from '../../../../../components/UI/TextButton';
import { RecordStatuses } from '../../../../../modules/role-admin/clients/types/ClientsTypes';
import { useCommonStyles } from '../../../../../styles/commonStyles';
import { useCustomTypography } from '../../../../../styles/customTypography';
import { formatRu } from '../../../../../utils/formatRu';
import moneyFormat from '../../../../../utils/moneyFormat';
import { stringToDate } from '../../../../../utils/stringToDate';
import {
  Box,
  Button,
  DialogProps,
  Divider,
  FormLabel,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

interface IRecordModalProps extends DialogProps {}

const RecordModal: React.FC<IRecordModalProps> = observer(({ ...rest }) => {
  const { slotsStore, recordsStore, chatStore, offerStore } = useRootStore();

  const theme = useTheme();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    if (slotsStore.modalState.record.currentId) {
      recordsStore.getRecordById(slotsStore.modalState.record.currentId);
    }

    return () => {
      if (slotsStore.modalState.record.currentId) {
        recordsStore.clearRecordInfo();
      }
    };
  }, [recordsStore, slotsStore.modalState.record.currentId]);

  useEffect(() => {
    offerStore.getCompanyOffersList();

    return () => {
      offerStore.clearCompanyOffersList();
    };
  }, [offerStore]);

  // Handlers
  const handleOpenCancelModal = () => {
    slotsStore.setModalOpen('confirmModal', true);
  };

  const handleOpenCompleteModal = () => {
    slotsStore.setModalOpen('completeModal', true);
  };

  const handleGoToChat = () => {
    if (slotsStore.modalState.record.currentId) {
      chatStore.getChatWithClientByRecordId(slotsStore.modalState.record.currentId);
    }
  };

  const handleChangeAutocomplete = (e: React.ChangeEvent<{}>, values: any) => {
    recordsStore.setExtraServices(values);
  };

  const handleSelectAll = () => {
    recordsStore.setExtraServices(offerStore.mappedCompanyOffersList);
  };

  // Renders
  const renderContent = () => {
    if (recordsStore.recordInfo) {
      const {
        companyEmployee,
        companyOffer,
        startDate,
        clientName,
        clientPhone,
        isPrePayPayment,
        additionCompanyOffers,
        source,
        status,
        unreadMessagesCount,
      } = recordsStore.recordInfo;

      const date = startDate ? stringToDate(startDate) : null;
      const fullDate = date && formatRu(date, 'd MMMM, HH:mm');
      const isCompleted = status === RecordStatuses.COMPLETED;
      const isFullCompleted = isPrePayPayment || isCompleted;

      return (
        <>
          <Box mb={isFullCompleted ? 1.5 : 3} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h3" className={commonClasses.blackHigh}>
              Данные записи
            </Typography>
            {source !== 'web_app' && (
              <IconButton
                onClick={handleGoToChat}
                classes={{ label: classes.label }}
                className={classes.chatIcon}
                color="secondary"
              >
                {!!unreadMessagesCount && <span className={classes.notification}>{unreadMessagesCount}</span>}
                <ChatBubbleOutlineRoundedIcon />
              </IconButton>
            )}
          </Box>
          {isFullCompleted && (
            <Box mb={2} display="flex" alignItems="center">
              {isPrePayPayment && (
                <Box>
                  <RecordStatusTag title="Оплачено" icon={circleCheck} color={'#0DC268'} />
                </Box>
              )}
              {isCompleted && (
                <Box ml={2}>
                  <RecordStatusTag title="Запись завершена" icon={fileCheck} color={theme.palette.black.medium} />
                </Box>
              )}
            </Box>
          )}
          {companyOffer && (
            <Box mb={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel>Услуга</FormLabel>
              </Box>
              <QTextField
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                value={`${companyOffer.title}, ${moneyFormat(companyOffer.price)} ₽`}
              />
            </Box>
          )}
          {!isCompleted && (
            <Box mb={3}>
              <QAutocomplete
                label="Дополнительные услуги"
                value={recordsStore.extraServices}
                multiple
                limitTags={1}
                disablePortal
                paperSpacing
                selectAllButton
                onChange={handleChangeAutocomplete}
                onHandleSelectAll={handleSelectAll}
                options={offerStore.mappedCompanyOffersList}
                getOptionLabel={option => `${option?.title && option?.title}, ${option?.price && option.price} ₽`}
                getOptionSelected={(option, value) => option?.id === value?.id}
                noOptionsText="Нет подходящих вариантов"
                classes={{ tag: commonClasses.tag }}
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Добавить доп. услуги"
                      name="offer"
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      {...params}
                    />
                  );
                }}
                renderOption={option => (
                  <Box>
                    <Box mb={0.5}>
                      <Typography className={clsx(typography.text2, commonClasses.blackHigh)}>
                        {option?.title}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={commonClasses.blackMedium}>
                      <Box mr={2}>
                        <Typography variant="caption">{option?.price} ₽</Typography>
                      </Box>
                      <div>
                        <Typography variant="caption">{option?.duration}</Typography>
                      </div>
                    </Box>
                  </Box>
                )}
              />
            </Box>
          )}
          {isFullCompleted && !!additionCompanyOffers.length && (
            <Box mb={3}>
              <Box mb={0.5}>
                <FormLabel>Дополнительные услуги</FormLabel>
              </Box>

              {additionCompanyOffers.map((item, index) => (
                <Box key={`${item.id}-${index}`} mb={1}>
                  <QTextField
                    value={`${item.title}, ${moneyFormat(item.price)} ₽`}
                    btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              ))}
            </Box>
          )}
          {fullDate && (
            <Box mb={3}>
              <QTextField
                label="Дата и время"
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: timeIcon, color: 'secondary' }}
                value={fullDate}
              />
            </Box>
          )}
          {companyEmployee && (
            <Box mb={3}>
              <QTextField
                label="Сотрудник"
                InputProps={{ readOnly: true }}
                btnProps={{ startIcon: smileIcon, color: 'secondary' }}
                value={companyEmployee.name}
              />
            </Box>
          )}
          <Box mb={3} maxWidth={328} mx="auto">
            <Divider />
          </Box>
          {clientName && (
            <Box mb={3}>
              <QTextField label="Имя клиента" InputProps={{ readOnly: true }} value={clientName} />
            </Box>
          )}
          {clientPhone && (
            <Box mb={isCompleted ? 0 : 4}>
              <QTextField label="Номер телефона" InputProps={{ readOnly: true }} value={clientPhone} />
            </Box>
          )}
          {!isCompleted && (
            <>
              <Box mb={1}>
                <Button fullWidth type="submit" color="secondary" variant="contained" onClick={handleOpenCompleteModal}>
                  Услуга оказана
                </Button>
              </Box>
              <TextButton onClick={handleOpenCancelModal} gutter fullWidth>
                Отменить запись
              </TextButton>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <QModal {...rest}>
        {recordsStore.recordInfoLoading ? <Loader minHeight={641} color="secondary" /> : renderContent()}
      </QModal>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  confirmModalWrap: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
  chatIcon: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    marginRight: 53,
    padding: 0,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  label: {
    width: '100%',
    height: '100%',
  },
  notification: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.extra.white,
    minWidth: 16,
    width: 'auto',
    height: 16,
    padding: 2,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    transform: 'translate(60%, 60%)',
    pointerEvents: 'none',
  },
}));

export default RecordModal;
