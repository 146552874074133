import { useRootStore } from '../../../../base/hooks/useRootStore';
import Loader from '../../../../components/UI/Loader';
import { NumberInput } from '../../../../components/UI/NumberInputs';
import QModal from '../../../../components/UI/QModal';
import QTextField from '../../../../components/UI/QTextField';
import { Box, Button, DialogProps, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

interface ICompanyDetailModalProps extends DialogProps {}

const CompanyDetailModal: React.FC<ICompanyDetailModalProps> = observer(props => {
  const { companyStore, userStore } = useRootStore();
  const { detailModalValues, setDetailModalValues } = companyStore;

  const classes = useStyles();

  //Effects
  useEffect(() => {
    if (companyStore.isShowDetailModal) {
      companyStore.getDetailFromCard();
    }
  }, [companyStore, companyStore.isShowDetailModal]);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDetailModalValues({ ...detailModalValues, [name]: value });
  };

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }
    companyStore.setIsShowDetailModal(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    companyStore.updateCompanyDetail(userStore.userInfo?.companyId);
  };

  // Renders
  return (
    <QModal modalClassName={classes.modal} onClose={handleClose} {...props}>
      {companyStore.detailModalLoading && <Loader isAbsolute color="secondary" />}
      <form onSubmit={handleSubmit}>
        <Box pb={3.5} maxWidth="90%">
          <Typography variant="h3">Реквизиты</Typography>
        </Box>
        <Box pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <QTextField
                name="legalName"
                value={detailModalValues.legalName}
                label="Юридическое название компании"
                placeholder=""
                onChange={handleChange}
                error={companyStore.detailModalError?.legalName?.length}
                helperText={companyStore.detailModalError?.legalName?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <QTextField
                name="inn"
                value={detailModalValues.inn}
                label="ИНН"
                placeholder=""
                InputProps={{ inputComponent: NumberInput }}
                onChange={handleChange}
                error={companyStore.detailModalError?.inn?.length}
                helperText={companyStore.detailModalError?.inn?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="kpp"
                value={detailModalValues.kpp}
                label="КПП"
                placeholder=""
                onChange={handleChange}
                InputProps={{ inputComponent: NumberInput }}
                error={companyStore.detailModalError?.kpp?.length}
                helperText={companyStore.detailModalError?.kpp?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="ogrn"
                value={detailModalValues.ogrn}
                label="ОГРН / ОГРНИП"
                placeholder=""
                InputProps={{ inputComponent: NumberInput }}
                onChange={handleChange}
                error={companyStore.detailModalError?.ogrn?.length}
                helperText={companyStore.detailModalError?.ogrn?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={3}>
          <QTextField
            name="legalAddress"
            value={detailModalValues.legalAddress}
            label="Юридический адрес"
            placeholder=""
            multiline
            rows={2}
            onChange={handleChange}
            error={companyStore.detailModalError?.legalAddress?.length}
            helperText={companyStore.detailModalError?.legalAddress?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box pb={3}>
          <QTextField
            name="actualAddress"
            value={detailModalValues.actualAddress}
            label="Фактический адрес"
            placeholder=""
            multiline
            rows={2}
            onChange={handleChange}
            error={companyStore.detailModalError?.actualAddress?.length}
            helperText={companyStore.detailModalError?.actualAddress?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box pb={3}>
          <QTextField
            name="director"
            value={detailModalValues.director}
            label="Генеральный директор"
            placeholder=""
            onChange={handleChange}
            error={companyStore.detailModalError?.director?.length}
            helperText={companyStore.detailModalError?.director?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="bik"
                value={detailModalValues.bik}
                label="БИК"
                placeholder=""
                onChange={handleChange}
                InputProps={{ inputComponent: NumberInput }}
                error={companyStore.detailModalError?.bik?.length}
                helperText={companyStore.detailModalError?.bik?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="bankName"
                value={detailModalValues.bankName}
                label="Наименование банка"
                placeholder=""
                onChange={handleChange}
                error={companyStore.detailModalError?.bankName?.length}
                helperText={companyStore.detailModalError?.bankName?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="correspondentAccount"
                value={detailModalValues.correspondentAccount}
                label="Корреспондентский счёт"
                placeholder=""
                onChange={handleChange}
                InputProps={{ inputComponent: NumberInput }}
                error={companyStore.detailModalError?.correspondentAccount?.length}
                helperText={companyStore.detailModalError?.correspondentAccount?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <QTextField
                name="settlementAccount"
                value={detailModalValues.settlementAccount}
                label="Рассчётный счёт"
                placeholder=""
                onChange={handleChange}
                InputProps={{ inputComponent: NumberInput }}
                error={companyStore.detailModalError?.settlementAccount?.length}
                helperText={companyStore.detailModalError?.settlementAccount?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Grid>
          </Grid>
        </Box>
        <Button fullWidth color="secondary" variant="contained" type="submit">
          Сохранить
        </Button>
      </form>
    </QModal>
  );
});

const useStyles = makeStyles(() => ({
  modal: {
    minWidth: 300,
    width: '100%',
    maxWidth: 600,
    position: 'relative',
  },
}));

export default CompanyDetailModal;
