import AbstractDto from '../../../../base/AbstractDto';
import UpdateCompanyAddressDto from './UpdateCompanyAddress';

export default class UpdateCompanyInfoDto extends AbstractDto {
  title: string | null = null;
  offerCategoryId: number | null = null;
  description: string | null = null;
  contactPerson: string | null = null;
  contactPersonPhone: string | null = null;
  contactPersonEmail: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  timezone: string | null = null;
  address: UpdateCompanyAddressDto | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
