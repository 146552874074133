import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { routes } from '../../../routes';
import ChatSideBarItem from './ChatSideBarItem';
import Loader from '../../../../components/UI/Loader';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import TextButton from '../../../../components/UI/TextButton';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { makePath } from '../../../../base/routes/utils/makePath';
import { useRootStore } from '../../../../base/hooks/useRootStore';

interface IChatSidebarProps {}

const ChatSidebar: React.FC<IChatSidebarProps> = observer(() => {
  const history = useHistory();
  const { chatStore, userStore } = useRootStore();
  const chatListScrollBar = useRef<Scrollbars | null>(null);
  const params = useParams<{ chatId: string }>();
  const chatId = Number(params.chatId);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isMD } = useAllMQ();

  // Effects
  useEffect(() => {
    if (!chatStore.chatList.length) {
      chatStore.getChatList();
    }
  }, [chatStore]);

  useEffect(() => {
    chatStore.setChatId(chatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    let timer: any = null;

    const loopRequest = () => {
      timer = setTimeout(() => {
        chatStore.getChatListWithLoop();
        clearTimeout(timer);
        loopRequest();
      }, 5000);
    };

    loopRequest();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatStore.chatList]);

  // Handlers
  const handlePush = (id: number) => {
    history.push(makePath(routes.ChatRoomScreen.path, id));
  };

  const handleUpdate = ({ clientHeight, scrollHeight, scrollTop }: positionValues) => {
    const isLoadMore = scrollHeight !== 0 && scrollHeight <= scrollTop + clientHeight + 30;

    if (isLoadMore && !chatStore.hotLoading) {
      chatStore.getChatList(true);
    }
  };

  // Renders
  return (
    <>
      {chatStore.chatListLoading && <Loader color="secondary" isAbsolute minHeight="100vh" />}
      <div className={classes.chatSidebar}>
        <Box px={3} pt={3} pb={2}>
          <Box mb={5.25}>
            <Link to="/" className="router-link">
              <TextButton startIcon={<ArrowBackIcon />} className={classes.backButton}>
                <Typography variant="body1">Вернуться на главную</Typography>
              </TextButton>
            </Link>
          </Box>
          <Box mb={2}>
            <Typography className={commonClasses.blackHigh} variant="h3">
              Чаты
            </Typography>
          </Box>
        </Box>
        <Scrollbars
          onUpdate={handleUpdate}
          ref={chatListScrollBar}
          autoHide
          autoHeight
          autoHeightMax={isMD ? 'calc(100vh - 224px)' : 'calc(100vh - 234px)'}
        >
          {chatStore.chatList.map(chatListItem => (
            <ChatSideBarItem
              key={chatListItem.id}
              currentId={chatId}
              chatListItem={chatListItem}
              onHandlePush={handlePush}
              currentUserId={userStore.userInfo?.userId as number}
            />
          ))}
        </Scrollbars>
      </div>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  chatSidebar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexBasis: 444,
      maxWidth: 444,
      minWidth: 444,
      marginRight: -1,
      borderRight: `1px solid ${theme.palette.extra.stroke}`,
    },
  },
  backButton: {
    color: theme.palette.black.medium,
  },
}));

export default ChatSidebar;
