import { IconButton, makeStyles, Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { trashIcon } from '../../../../components/UI/Icons/SvgIcons';
import QIcon from '../../../../components/UI/Icons/QIcon';
import Loader from '../../../../components/UI/Loader';

interface IGalleryItemProps {
  img: string;
  onDelete: () => void;
  loading?: boolean;
}

const GalleryItem: React.FC<IGalleryItemProps> = props => {
  const { img, loading, onDelete } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.imageWrapper}>
      <img alt="img" src={img} className={classes.img} />
      <IconButton onClick={onDelete} className={classes.deleteIcon}>
        {loading ? (
          <Loader size={20} />
        ) : (
          <QIcon src={trashIcon} color={theme.palette.qsecondary['500']} width={15} height={16} />
        )}
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    position: 'relative',
    width: 280,
    height: 180,
    borderRadius: 8,
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  deleteIcon: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    width: 36,
    height: 36,
    borderRadius: 8,
    background: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(7.48px)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.5)',
      opacity: 0.9,
    },
    '& .MuiIconButton-label': {
      width: 'auto',
    },
  },
}));

export default GalleryItem;
