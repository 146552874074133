import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import UpdateCompanyDetailDto from './forms/UpdateCompanyDetail';
import UpdateCompanyInfoDto from './forms/UpdateCompanyInfo';

export default class CompanyApiRepository extends AbstractApiRepository {
  getInfo = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/info` });
  };

  setInfo = (companyId: number, data: UpdateCompanyInfoDto) => {
    return this.post({ url: `/company/${companyId}/info`, data });
  };

  getDetail = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/detail` });
  };

  setDetail = (companyId: number, data: UpdateCompanyDetailDto) => {
    return this.post({ url: `/company/${companyId}/detail`, data });
  };

  getOfferCategories = (): any => {
    return this.post({ url: `/offer-categories/list?limit=100&offset=0` });
  };

  getTimezones = () => {
    return this.get({ url: `/dictionary/timezones` });
  };

  getGeo = (address: string) => {
    return this.get({ url: `/geo/search-by-address-name?q=${address}` });
  };
}
