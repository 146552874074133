export const isImageType = (type: string): boolean => {
  return (
    type === 'jpg' ||
    type === 'jpeg' ||
    type === 'JPEG' ||
    type === 'png' ||
    type === 'gif' ||
    type === 'webp' ||
    type === 'bmp'
  );
};

export const isAllowedFileSize = (fileSize: number): boolean => parseInt((fileSize / 1024).toFixed(2)) > 10000;
