import clsx from 'clsx';
import { Box, IconButton, Theme, Typography, makeStyles } from '@material-ui/core';

import QIcon from '../../../../components/UI/Icons/QIcon';
import { Offer } from '../../../../modules/offer/models/Offer';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { useCustomTypography } from '../../../../styles/customTypography';
import { editIcon, trashIcon } from '../../../../components/UI/Icons/SvgIcons';

interface IOfferItemProps {
  data: Offer;
  onHandleDelete: (id: number) => void;
  onHandleEdit: (id: number) => void;
}

const OfferItem: React.FC<IOfferItemProps> = props => {
  const { data, onHandleDelete, onHandleEdit } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <div>
        <Box mb={0.5}>
          <Typography className={clsx(commonClasses.blackHigh, typography.text2)}>{data.title}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="caption" className={commonClasses.blackMedium}>
              {data.price} ₽
            </Typography>
          </Box>
          <Typography variant="caption" className={commonClasses.blackMedium}>
            {data.duration && data.duration}
          </Typography>
        </Box>
      </div>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <IconButton
            className={clsx(classes.iconBtn, 'edit')}
            color="secondary"
            onClick={() => onHandleEdit(data.id as number)}
          >
            <QIcon width={18} height={18} src={editIcon} />
          </IconButton>
        </Box>
        <IconButton
          className={clsx(classes.iconBtn, 'delete')}
          color="secondary"
          onClick={() => onHandleDelete(data.id as number)}
        >
          <QIcon width={18} height={18} src={trashIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    width: 30,
    height: 30,
    '&.edit': {
      color: theme.palette.qsecondary['900'],
    },
    '&.delete': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default OfferItem;
