import { observer } from 'mobx-react-lite';
import { Box, Button, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core';

import QModal from '../../../../../components/UI/QModal';
import Loader from '../../../../../components/UI/Loader';
import { useRootStore } from '../../../../../base/hooks/useRootStore';
import { useCustomTypography } from '../../../../../styles/customTypography';

interface IDeleteConfirmModalProps extends DialogProps {}

const DeleteConfirmModal: React.FC<IDeleteConfirmModalProps> = observer(props => {
  const { slotsStore } = useRootStore();
  const classes = useStyles();
  const typography = useCustomTypography();

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    slotsStore.setModalOpen('deleteConfirmModal', false);
  };

  const handleDelete = () => {
    if (slotsStore.modalState.time.currentId) {
      slotsStore.deleteSlot(slotsStore.modalState.time.currentId);
    }
  };

  return (
    <QModal onClose={handleClose} className={classes.confirmModalWrap} {...props}>
      {slotsStore.loading && <Loader isAbsolute color="secondary" />}
      <Box mb={3} maxWidth="80%">
        <Typography variant="h3">Подтверждение удаления слота</Typography>
      </Box>
      <Box mb={4.75}>
        <Typography className={typography.text}>Вы подтверждаете, что хотите удалить слот?</Typography>
      </Box>
      <Box mb={1}>
        <Button onClick={handleDelete} fullWidth variant="contained" color="secondary">
          Подтверждаю
        </Button>
      </Box>
      <div>
        <Button onClick={() => handleClose(null, null)} fullWidth variant="outlined" color="secondary">
          Отмена
        </Button>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  confirmModalWrap: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
}));

export default DeleteConfirmModal;
