import { IPaginationParams } from '../../base/modules/pagination/PaginationTypes';
import Pagination from '../../base/modules/pagination/models/Pagination';
import { convertDuration } from '../../utils/convertDuration';
import OfferApiRepository from './OfferApiRepository';
import { OfferFactory } from './OfferFactory';
import AddOfferDto from './forms/AddOfferDto';
import UpdateOfferDto from './forms/UpdateOfferDto';
import { Offer } from './models/Offer';
import { OfferWithCategory } from './models/OfferWithCategory';
import { IOffersListReqBody, IOfferValues } from './types/OfferTypes';

export default class OfferService {
  static readonly LIMIT = 100;

  offerApi: OfferApiRepository;
  offerFactory: OfferFactory;

  constructor() {
    this.offerApi = new OfferApiRepository();
    this.offerFactory = new OfferFactory();
  }

  getEmployeeOfferList = async (companyId: number, companyEmployeeIds: number[]): Promise<Offer[]> => {
    const { data } = await this.offerApi.getEmployeeOfferList(companyId, { companyEmployeeIds });
    return this.offerFactory.createList<Offer>(Offer, data.data);
  };

  getOffersList = async (
    params: IPaginationParams,
    values?: IOffersListReqBody,
  ): Promise<{ items: OfferWithCategory[]; pagination: Pagination }> => {
    const { data } = await this.offerApi.getOffersList(params, values);

    const items = this.offerFactory.createOfferWithCategoriesList<OfferWithCategory>(
      OfferWithCategory,
      data.data.items,
    );
    const pagination = this.offerFactory.create<Pagination>(Pagination, data.data.pagination);

    return { items, pagination };
  };

  getCompanyOffersList = async (companyId: number): Promise<Offer[]> => {
    const { data } = await this.offerApi.getCompanyOffersList(companyId);
    return this.offerFactory.createList<Offer>(Offer, data.data);
  };

  getCompanyOffer = async (companyId: number, offerId: number): Promise<Offer> => {
    const { data } = await this.offerApi.getCompanyOffer(companyId, offerId);
    return this.offerFactory.create<Offer>(Offer, data.data);
  };

  addOffer = async (companyId: number, values: IOfferValues): Promise<Offer> => {
    const preDto = { offer_id: values.offer?.id && values.offer.id.toString() };
    const addOfferDto = AddOfferDto.populate({ ...values, ...preDto }) as AddOfferDto;

    const { data } = await this.offerApi.addOffer(companyId, addOfferDto);
    return this.offerFactory.create<Offer>(Offer, data.data);
  };

  updateOffer = async (companyId: number, offerId: number, values: IOfferValues): Promise<Offer> => {
    const updateOfferDto = UpdateOfferDto.populate(values) as UpdateOfferDto;

    const { data } = await this.offerApi.updateOffer(companyId, offerId, updateOfferDto);
    return this.offerFactory.create<Offer>(Offer, data.data);
  };

  deleteOffer = async (companyId: number, offerId: number) => {
    return this.offerApi.deleteOffer(companyId, offerId);
  };

  /**
   * получить хэш-мп списка Вида услуг с группировкой по offerTitle (не категории услуги - а Услуги)
   * @param list
   */
  getHashMapCompanyOffersList = (list: Offer[]) => {
    const listWithConvertedDuration = this.getListWithConvertedDuration(list);
    const hashMap = listWithConvertedDuration.reduce((acc: { [key: string]: Offer[] }, item: Offer) => {
      const categoryId = item.offerTitle;

      if (categoryId === null || categoryId === undefined) {
        return acc;
      }

      return acc[categoryId] ? { ...acc, [categoryId]: [...acc[categoryId], item] } : { ...acc, [categoryId]: [item] };
    }, {});

    return hashMap;
  };

  getListWithConvertedDuration = (list: Offer[]): Offer[] => {
    return list.length
      ? list.map(item =>
          this.offerFactory.create<Offer>(Offer, {
            ...item,
            duration: item?.duration && convertDuration(item.duration),
          }),
        )
      : [];
  };
}
