import FileService from '../../file/FileService';
import GalleryService from './GalleryService';
import { PhotoModel } from './models/Gallery';
import { makeAutoObservable } from 'mobx';

export class GalleryStore {
  loading = false;
  addLoading = false;
  addMainLoading = false;
  deleteLoading = '-1';

  mainPhoto: PhotoModel | null = null;
  mainPhotoUuid: string | null = null;
  photos: PhotoModel[] = [];

  private galleryService: GalleryService;
  private fileService: FileService;

  constructor() {
    makeAutoObservable(this);
    this.galleryService = new GalleryService();
    this.fileService = new FileService();
  }

  getList = (companyId?: number | null) => {
    if (!companyId) {
      return;
    }
    this.setLoading(true);

    this.galleryService
      .getList(companyId)
      .then(images => {
        this.setImages(images);
        const mainPhoto = images.find(item => item.main);

        if (mainPhoto) {
          this.changeMainPhoto(mainPhoto);
        } else {
          this.changeMainPhoto(null);
        }
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setMainPhoto = async (photos: FileList | null, companyId: number | null | undefined) => {
    if (!companyId || !photos) {
      return;
    }

    this.setAddMainLoading(true);

    try {
      await this.addPhotos(photos, companyId, true);
      await this.galleryService.setMainPhoto(companyId, this.mainPhotoUuid);
      this.getList(companyId);
    } catch (error) {
      console.log(error);
    }

    this.setAddMainLoading(false);
  };

  addPhotos = async (photos: FileList | null, companyId: number | null | undefined, isMainPhoto = false) => {
    if (!companyId || !photos) {
      return;
    }

    this.setAddLoading(true);

    try {
      const files = await this.fileService.uploadImages(photos);
      const addFilesRequests = files.map(file => file.uuid && this.galleryService.addPhotos(companyId, file.uuid));

      if (isMainPhoto) {
        this.setMainPhotoUuid(files[0].uuid);
      }

      await Promise.all(addFilesRequests);
      this.getList(companyId);
    } catch (error) {
      console.log(error);
    }

    this.setAddLoading(false);
  };

  deletePhoto = (uuid: string, companyId?: number | null) => {
    if (!companyId) {
      return;
    }

    this.setDeleteLoading(uuid);

    this.galleryService
      .deletePhoto(companyId, uuid)
      .then(() => {
        this.getList(companyId);
      })
      .catch(() => {})
      .finally(() => this.setDeleteLoading('-1'));
  };

  setImages = (images: PhotoModel[]) => {
    this.photos = images;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  changeMainPhoto = (photo: PhotoModel | null) => {
    this.mainPhoto = photo;
  };

  setDeleteLoading = (id: string) => {
    this.deleteLoading = id;
  };

  setAddLoading = (status: boolean) => {
    this.addLoading = status;
  };

  setAddMainLoading = (status: boolean) => {
    this.addMainLoading = status;
  };

  setMainPhotoUuid = (uuid: string | null) => {
    this.mainPhotoUuid = uuid;
  };
}
