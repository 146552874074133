import AbstractModel from '../../../../base/AbstractModel';

export class Report extends AbstractModel {
  uuid: string | null = null;
  status: string | null = null;
  fileName: string | null = null;
  fullUrl: string | null = null;
  extension: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
