import React from 'react';
// import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Box, Theme, makeStyles, Typography } from '@material-ui/core';

import { routes } from '../../routes';
import { useAllMQ } from '../../../hooks/useAllMQ';
import BackButton from '../../../components/UI/BackButton';
import { useCommonStyles } from '../../../styles/commonStyles';
import { useRootStore } from '../../../base/hooks/useRootStore';
// import { useCustomTypography } from '../../../styles/customTypography';

interface IChatHeaderProps {}

const ChatHeader: React.FC<IChatHeaderProps> = observer(() => {
  const { chatStore } = useRootStore();
  const { isMD } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  // const typography = useCustomTypography();

  return (
    <div className={classes.header}>
      {isMD && (
        <Box pt={1.25} mb={1}>
          <BackButton text="Вернуться на главную" to={routes.MainScreen.path} />
        </Box>
      )}
      <Typography variant={isMD ? 'h4' : 'h3'} className={commonClasses.blackHigh}>
        {chatStore.supportChat?.title}
      </Typography>
      {/* <div className={classes.fixedDate}>
        <Typography align="center" className={clsx(typography.text, commonClasses.blackLow)}>
          {chatStore.yesterdayDate}
        </Typography>
      </div> */}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4, 3),
    transform: 'translateX(-50%)',
    background: theme.palette.extra.bg,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      maxWidth: 552,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),
    },
  },
  fixedDate: {
    position: 'absolute',
    bottom: -10,
    left: '50%',
    transform: 'translate(-50%, 100%)',
    width: '100%',
  },
}));

export default ChatHeader;
