import AbstractDto from '../../../../base/AbstractDto';
import { IPeriod } from '../types/SlotTypes';

export default class CreateSlotDto extends AbstractDto {
  companyEmployeeId: number | null = null;
  periods: IPeriod[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
