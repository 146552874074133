import RecordsApiRepository from './RecordsApiRepository';
import { CRecord } from './models/Record';
import { WorkTime } from './models/WorkTime';
import { RecordsFactory } from './RecordsFactory';
import { AdminSchedule } from './models/AdminSchedule';
import CreateRecordDto from './forms/CreateRecordDto';
import { Slot } from '../role-admin/slots/models/Slot';
import { MasterSchedule } from './models/MasterSchedule';
import { ICompleteRecordValues } from './types/RecordTypes';
import { ISlotModalValues } from '../role-admin/slots/types/SlotTypes';
import { Offer } from '../offer/models/Offer';
import { addRussianCountryPhoneCode } from '../../utils/addRussianCountryPhoneCode';

export default class RecordsService {
  userApi: RecordsApiRepository;
  userFactory: RecordsFactory;

  constructor() {
    this.userApi = new RecordsApiRepository();
    this.userFactory = new RecordsFactory();
  }

  getRecordById = async (companyId: number, recordId: number): Promise<CRecord> => {
    const { data } = await this.userApi.getRecordById(companyId, recordId);
    return this.userFactory.create<CRecord>(CRecord, data.data);
  };

  getAdminSchedule = async (companyId: number, date: string): Promise<AdminSchedule[]> => {
    const { data } = await this.userApi.getAdminSchedule(companyId, date);
    return this.userFactory.createList<AdminSchedule>(AdminSchedule, data.data);
  };

  getMasterSchedule = async (companyId: number, date: string): Promise<MasterSchedule[]> => {
    const { data } = await this.userApi.getMasterSchedule(companyId, date);
    return this.userFactory.createList<MasterSchedule>(MasterSchedule, data.data);
  };

  cancelRecordById = async (companyId: number, recordId: number) => {
    return this.userApi.cancelRecordById(companyId, recordId);
  };

  completeRecordById = async (companyId: number, recordId: number, offers: Offer[]) => {
    const values: ICompleteRecordValues = { addOffers: offers.map(item => item.id as number) };
    return this.userApi.completeRecordById(companyId, recordId, values);
  };

  getWorkTime = async (companyId: number, date: string): Promise<WorkTime> => {
    const { data } = await this.userApi.getWorkTime(companyId, date);
    return this.userFactory.create<WorkTime>(WorkTime, data.data);
  };

  createRecord = async (companyId: number, values: ISlotModalValues, slot: Slot) => {
    const preDto = {
      companyEmployeeSlotId: slot.id,
      companyOfferId: values.offer?.id && values.offer?.id,
      companyEmployeeId: slot.companyEmployee?.id && slot.companyEmployee?.id,
      clientPhone: addRussianCountryPhoneCode(values.clientPhone),
    };

    const recordDto = CreateRecordDto.populate({ ...values, ...preDto }) as CreateRecordDto;
    return this.userApi.createRecord(companyId, recordDto);
  };
}
