import { makeAutoObservable, runInAction } from 'mobx';

import { CRecord } from './models/Record';
import { WorkTime } from './models/WorkTime';
import RecordsService from './RecordsService';
import { rootStore } from '../../base/RootStore';
import { Slot } from '../role-admin/slots/models/Slot';
import { AdminSchedule } from './models/AdminSchedule';
import { MasterSchedule } from './models/MasterSchedule';
import NotificationUtil from '../../utils/NotificationUtil';
import { ISlotModalValues } from '../role-admin/slots/types/SlotTypes';
import { Offer } from '../offer/models/Offer';
import roles from '../../base/routes/roles';

export class RecordsStore {
  loading = false;
  workTimeLoading = false;
  recordInfoLoading = false;
  createRecordLoading = false;
  cancelLoading = false;
  completeLoading = false;

  private recordsService: RecordsService;

  recordInfo: CRecord | null = null;
  adminSchedule: AdminSchedule[] = [];
  masterSchedule: MasterSchedule[] = [];
  workTime: WorkTime | null = null;
  extraServices: Offer[] = [];

  constructor() {
    makeAutoObservable(this);
    this.recordsService = new RecordsService();
  }

  getRecordById = async (recordId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.recordInfoLoading = true;

    return this.recordsService
      .getRecordById(companyId, recordId)
      .then(recordInfo => {
        runInAction(() => {
          this.recordInfo = recordInfo;
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.recordInfoLoading = false;
        });
      });
  };

  getAdminSchedule = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    const date = rootStore.adminMainStore.formattedDate;
    this.setLoading(true);

    this.recordsService
      .getAdminSchedule(companyId, date)
      .then(adminSchedule => {
        runInAction(() => {
          this.adminSchedule = adminSchedule;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getMasterSchedule = () => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    const date = rootStore.masterMainStore.formattedDate;
    this.setLoading(true);

    this.recordsService
      .getMasterSchedule(companyId, date)
      .then(masterSchedule => {
        runInAction(() => {
          this.masterSchedule = masterSchedule;
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  cancelRecordById = (recordId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.cancelLoading = true;

    this.recordsService
      .cancelRecordById(companyId, recordId)
      .then(() => {
        runInAction(() => {
          this.getAdminSchedule();
          rootStore.slotsStore.setModalOpen('confirmModal', false);
          rootStore.slotsStore.setModalState({
            ...rootStore.slotsStore.modalState,
            record: { currentId: null, modal: false },
          });
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.cancelLoading = false;
        });
      });
  };

  completeRecordById = (recordId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.completeLoading = true;

    this.recordsService
      .completeRecordById(companyId, recordId, this.extraServices)
      .then(() => {
        runInAction(() => {
          this.getAdminSchedule();
          rootStore.slotsStore.setModalOpen('completeModal', false);
          rootStore.slotsStore.setModalState({
            ...rootStore.slotsStore.modalState,
            record: { currentId: null, modal: false },
          });
          this.setExtraServices([]);
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.completeLoading = false;
        });
      });
  };

  getWorkTime = async (date: string) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setWorkTimeLoading(true);

    return this.recordsService
      .getWorkTime(companyId, date)
      .then(workTime => {
        runInAction(() => {
          if (workTime.isWeekend) {
            NotificationUtil.showWarning('Обратите внимание, данный день является выходным!');
          }

          this.workTime = workTime;
        });
      })
      .catch(() => {})
      .finally(() => this.setWorkTimeLoading(false));
  };

  updateAdminTable = async (date: string, currentRole: string | null) => {
    try {
      if (currentRole !== roles.administrator.role) {
        return false;
      }

      await this.getWorkTime(date);
      this.getAdminSchedule();
      return true;
    } catch (e) {
      return false;
    }
  };

  updateMasterTable = async (date: string, currentRole: string | null) => {
    if (currentRole !== roles.master.role) {
      return false;
    }

    try {
      await this.getWorkTime(date);
      this.getMasterSchedule();
      return true;
    } catch (e) {
      return false;
    }
  };

  updateRecordModal = async (recordId: number | null) => {
    if (!recordId) {
      return;
    }

    try {
      await this.getRecordById(recordId);
      rootStore.slotsStore.setModalState({
        ...rootStore.slotsStore.modalState,
        record: { currentId: null, modal: false },
      });
    } catch (e) {}
  };

  createRecord = (values: ISlotModalValues, slot: Slot) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.createRecordLoading = true;

    this.recordsService
      .createRecord(companyId, values, slot)
      .then(() => {
        runInAction(() => {
          this.getAdminSchedule();
          rootStore.slotsStore.setModalState({
            ...rootStore.slotsStore.modalState,
            slot: { currentId: null, modal: false },
          });
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.createRecordLoading = false;
        });
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setWorkTimeLoading = (value: boolean) => {
    this.workTimeLoading = value;
  };

  setExtraServices = (values: Offer[]) => {
    this.extraServices = values;
  };

  clearRecordInfo = () => {
    this.recordInfo = null;
  };

  clearAdminSchedule = () => {
    this.adminSchedule = [];
  };

  clearMasterSchedule = () => {
    this.masterSchedule = [];
  };

  clearWorkTime = () => {
    this.workTime = null;
  };

  clearAdminTable = () => {
    this.clearAdminSchedule();
    this.clearWorkTime();
  };

  clearMasterTable = () => {
    this.clearAdminSchedule();
    this.clearWorkTime();
  };
}
