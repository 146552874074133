import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { authRotes } from '../routes';
import LoginCard from './components/LoginCard';
import QTextField from '../../components/UI/QTextField';
import { ILinkProps } from '../../base/types/BaseTypes';
import { useCommonStyles } from '../../styles/commonStyles';
import LoaderButton from '../../components/UI/LoaderButton';
import { useRootStore } from '../../base/hooks/useRootStore';

interface IResetPasswordSetNewScreenProps extends Partial<ILinkProps> {
  isChangePassword?: boolean;
}

const ResetPasswordSetNewScreen: React.FC<IResetPasswordSetNewScreenProps> = observer(props => {
  const { to, text, isChangePassword } = props;
  const commonClasses = useCommonStyles();

  const history = useHistory();
  const { authStore } = useRootStore();
  const { loginStore } = authStore;

  const [values, setValues] = useState({
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const isValid = values.password.length && values.passwordConfirm.length;

  // Effects
  useEffect(() => {
    if (!authStore.smsToken.length && !authStore.loading) {
      history.push(to ? to : authRotes.LoginScreen.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.smsToken]);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleToggleShowPassword = (name: 'showPassword' | 'showConfirmPassword') => {
    setValues({ ...values, [name]: !values[name] });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (isValid) {
      if (isChangePassword) {
        loginStore.updatePassword(values.password, values.passwordConfirm);
        return false;
      }

      loginStore.resetPassword(values.password, values.passwordConfirm);
    }
  };

  // Renders
  return (
    <LoginCard
      link={{ to: to ? to : authRotes.LoginScreen.path, text: text ? text : 'Вернуться к авторизации' }}
      title="Придумайте пароль"
    >
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <QTextField
            type={values.showPassword ? 'text' : 'password'}
            name="password"
            onChange={handleChange}
            value={values.password}
            helperText={loginStore.errorMessages?.password?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
            error={loginStore.errorMessages?.password?.length}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      className={commonClasses.blackLow}
                      onClick={() => handleToggleShowPassword('showPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            label="Пароль"
          />
        </Box>
        <Box mb={4}>
          <QTextField
            type={values.showConfirmPassword ? 'text' : 'password'}
            name="passwordConfirm"
            onChange={handleChange}
            value={values.passwordConfirm}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      className={commonClasses.blackLow}
                      onClick={() => handleToggleShowPassword('showConfirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            label="Подтвердите пароль"
          />
        </Box>
        <LoaderButton loading={loginStore.loading} type="submit" fullWidth variant="contained" color="secondary">
          Сохранить
        </LoaderButton>
      </form>
    </LoginCard>
  );
});

export default ResetPasswordSetNewScreen;
