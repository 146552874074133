import { rootStore } from '../../../base/RootStore';
import RouteService from '../../../base/routes/service/RouteService';
import { routes } from '../../../screens/routes';
import NotificationUtil from '../../../utils/NotificationUtil';
import { convertDuration } from '../../../utils/convertDuration';
import { stringToDate } from '../../../utils/stringToDate';
import SlotsService from './SlotsService';
import { Slot } from './models/Slot';
import { ISlotFormValues, IDateString, ModalState, SlotModals, SlotModalKeys, ISlotTime } from './types/SlotTypes';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeAutoObservable } from 'mobx';

export class SlotsStore {
  loading = false;
  breakLoading = false;
  errorMessages: any = {};
  message: string = '';

  private slotsService: SlotsService;

  slot: Slot | null = null;
  slotList: Slot[] | null = null;

  selectedCells = new Set<number>();

  modalState: ModalState = {
    record: { currentId: null, modal: false },
    slot: { currentId: null, modal: false },
    time: { currentId: null, modal: false },
  };

  modalOpen: SlotModals = {
    deleteConfirmModal: false,
    deleteSelectedConfirmModal: false,
    confirmModal: false,
    completeModal: false,
  };

  constructor() {
    makeAutoObservable(this);
    this.slotsService = new SlotsService();
  }

  get messagesArray() {
    const messageArray: string[] = Object.values(this.errorMessages as Object).reduce((acc, item) => {
      acc.push(...item);

      return acc;
    }, []);

    return messageArray;
  }

  get slotFormatedDate() {
    if (this.slot) {
      return {
        ...this.slot,
        startDate: stringToDate(this.slot.startDate),
        endDate: stringToDate(this.slot.endDate),
      };
    }

    return null;
  }

  get mappedOffers() {
    if (this.slot && this.slot.companyEmployee) {
      return this.slot.companyEmployee.employeeOffers.map(item => ({
        ...item,
        duration: item?.duration && convertDuration(item.duration),
      }));
    }

    return [];
  }

  createSlots = (slotValues: ISlotFormValues) => {
    if (!slotValues.employee?.id) {
      NotificationUtil.showError('Выберите сотрудника');
      return;
    }

    if (slotValues.dates.some(date => !date.startDate)) {
      NotificationUtil.showError('Нужно выбрать дату');
      return;
    }

    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.slotsService
      .createSlots(companyId, slotValues)
      .then(slotList => {
        this.setErrors({});
        this.setMessage('Cлоты успешно созданы!');
        this.setSlotList(slotList);
        RouteService.push(routes.MainScreen.path);
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          this.setErrors(errors);
        } else {
          this.setErrors({});
        }
      })
      .finally(() => this.setLoading(false));
  };

  getSlot = (slotId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.slotsService
      .getSlot(companyId, slotId)
      .then(slot => {
        this.setSlot(slot);
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  setBreak = (slotId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setBreakLoading(true);

    this.slotsService
      .setBreak(companyId, slotId)
      .then(slot => {
        this.setSlot(slot);
        rootStore.recordsStore.getAdminSchedule();
      })
      .catch(() => {})
      .finally(() => this.setBreakLoading(false));
  };

  deleteSlot = (slotId: number) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.slotsService
      .deleteSlot(companyId, slotId)
      .then(() => {
        this.setModalOpen('deleteConfirmModal', false);
        this.setModalState({
          ...this.modalState,
          time: { modal: false, currentId: null },
        });
        rootStore.recordsStore.getAdminSchedule();
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  deleteSlots = (selectedCells: Set<number>) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.slotsService
      .deleteSlots(companyId, selectedCells)
      .then(() => {
        rootStore.recordsStore.getAdminSchedule();
        this.clearSelectedCells();

        this.setModalOpen('deleteSelectedConfirmModal', false);
        this.setModalState({
          ...this.modalState,
          time: { modal: false, currentId: null },
        });
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  editSlot = (slotId: number, slotData: IDateString) => {
    const companyId = rootStore.userStore.userInfo?.companyId as number;
    this.setLoading(true);

    this.slotsService
      .editSlot(companyId, slotId, slotData)
      .then(slot => {
        this.setSlot(slot);
        this.setModalState({
          ...this.modalState,
          time: { modal: false, currentId: null },
        });
        rootStore.recordsStore.getAdminSchedule();
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  createSlotTimes = (start: Date, end: Date, step: number): ISlotTime[] => {
    return this.slotsService.createSlotTimes(start, end, step);
  };

  createNewSlotTimeObject = (startTime: MaterialUiPickersDate, endTime: MaterialUiPickersDate) => {
    return this.slotsService.createNewSlotTimeObject(startTime, endTime);
  };

  toggleCellsSelect = (id: number) => {
    if (this.selectedCells.has(id)) {
      this.selectedCells.delete(id);
    } else {
      this.selectedCells.add(id);
    }
  };

  updateSlotModal = (currentId: null | number) => {
    if (currentId) {
      this.getSlot(currentId);
    }
  };

  setSlot = (slot: Slot | null) => {
    this.slot = slot;
  };

  setSlotList = (slotList: Slot[] | null) => {
    this.slotList = slotList;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setBreakLoading = (value: boolean) => {
    this.breakLoading = value;
  };

  setModalState = (state: ModalState) => {
    this.modalState = state;
  };

  setModalOpen = (modal: SlotModalKeys, value: boolean) => {
    this.modalOpen[modal] = value;
  };

  setErrors = (errors: Object) => {
    this.errorMessages = errors;
  };

  setMessage = (value: string) => {
    this.message = value;
  };

  removeError = (fieldName: string) => {
    delete this.errorMessages[fieldName];
  };

  clearSlot = () => {
    this.slot = null;
  };

  clearSelectedCells = () => {
    this.selectedCells.clear();
  };
}
