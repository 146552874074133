import PaginationDto from '../../../base/modules/pagination/PaginationDto';
import Pagination from '../../../base/modules/pagination/models/Pagination';
import ClientsApiRepository from './ClientsApiRepository';
import { ClientsFactory } from './ClientsFactory';
import ParamsGenerationHelper from './helpers/ParamsGenerationHelper';
import { Client } from './models/Client';
import { ClientRecord } from './models/ClientRecord';

export default class ClientsService {
  clientsApi: ClientsApiRepository;
  clientsFactory: ClientsFactory;

  constructor() {
    this.clientsApi = new ClientsApiRepository();
    this.clientsFactory = new ClientsFactory();
  }

  getClients = async (companyId: number, offset: number, searchText?: string) => {
    const paginationDto = PaginationDto.populate({ offset }) as PaginationDto;
    const params = ParamsGenerationHelper.getSerializedParams({ ...paginationDto, searchText });

    const { data } = await this.clientsApi.getClients(companyId, params);

    const clients = this.clientsFactory.createList<Client>(Client, (data.data as any).items);
    const pagination = this.clientsFactory.create<Pagination>(Pagination, (data.data as any).pagination);

    return { clients, pagination };
  };

  getClientRecords = async (companyId: number, clientUuid: string) => {
    const { data } = await this.clientsApi.getClientRecords(companyId, clientUuid);
    return this.clientsFactory.createList<ClientRecord>(ClientRecord, (data.data as any).items);
  };
}
