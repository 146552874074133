import { AuthFactory } from '../../AuthFactory';
import { SuccesAuth } from '../../models/SuccesAuth';
import LoginApiRepository from './LoginApiRepository';
import { ILoginData, IResetPasswordData } from './types/LoginTypes';

export default class LoginService {
  api: LoginApiRepository;
  factory: AuthFactory;

  constructor() {
    this.api = new LoginApiRepository();
    this.factory = new AuthFactory();
  }

  login = async (loginData: ILoginData): Promise<SuccesAuth> => {
    const { data } = await this.api.login(loginData);
    return this.factory.create<SuccesAuth>(SuccesAuth, data.data);
  };

  resetPassword = async (resetPasswordData: IResetPasswordData): Promise<SuccesAuth> => {
    const { data } = await this.api.resetPassword(resetPasswordData);
    return this.factory.create<SuccesAuth>(SuccesAuth, data.data);
  };
}
