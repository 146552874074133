import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';

export default class EmployeeApiRepository extends AbstractApiRepository {
  getAll = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/employees` });
  };

  getFullEmployeeList = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/employees/all` });
  };

  getEmployeeInfo = (companyId: number, employeeId: number) => {
    return this.get({ url: `/company/${companyId}/employees/${employeeId}` });
  };

  addEmployee = (companyId: number, data: FormData) => {
    return this.post({ url: `/company/${companyId}/employees/create`, data });
  };

  updateEmployee = (companyId: number, employeeId: number, data: FormData) => {
    return this.post({ url: `/company/${companyId}/employees/update/${employeeId}`, data });
  };

  deleteEmployee = (companyId: number, employeeId: number) => {
    return this.delete({ url: `/company/${companyId}/employees/${employeeId}` });
  };
}
