import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, DialogProps, Theme, Typography } from '@material-ui/core';

import QModal from '../../../../components/UI/QModal';
import Loader from '../../../../components/UI/Loader';
import { useRootStore } from '../../../../base/hooks/useRootStore';

interface IDeleteEmployeeModalProps extends DialogProps {}

const DeleteEmployeeModal: React.FC<IDeleteEmployeeModalProps> = observer(props => {
  const { employeeStore } = useRootStore();
  const classes = useStyles();

  // Handlers
  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    employeeStore.setDeleteModal(false);
  };

  const handleDelete = () => {
    employeeStore.deleteEmployee();
  };

  // Renders
  return (
    <QModal modalClassName={classes.modal} onClose={handleClose} {...props}>
      {employeeStore.deleteLoading && <Loader isAbsolute color="secondary" />}
      <Box mb={3} maxWidth="80%">
        <Typography variant="h3">Вы действительно хотите удалить сотрудника?</Typography>
      </Box>
      <Box mb={1}>
        <Button onClick={handleDelete} fullWidth variant="contained" color="secondary">
          Да
        </Button>
      </Box>
      <div>
        <Button onClick={() => handleClose(null, null)} fullWidth variant="outlined" color="secondary">
          Отмена
        </Button>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    minHeight: 200,
  },
}));

export default DeleteEmployeeModal;
