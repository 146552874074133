import roles from '../../../base/routes/roles';
import { addRussianCountryPhoneCode } from '../../../utils/addRussianCountryPhoneCode';
import EmployeeApiRepository from './EmployeeApiRepository';
import { EmployeeFactory } from './EmployeeFactory';
import EmployeeDto from './forms/EmployeeDto';
import EmployeeUserDto from './forms/EmployeeUserDto';
import { Employee } from './models/Employee';
import { EmployeeInfo } from './models/EmployeeInfo';
import { IEmployeeValues } from './types/EmployeeTypes';
import { serialize } from 'object-to-formdata';

export default class EmployeeService {
  employeeApi: EmployeeApiRepository;
  employeeFactory: EmployeeFactory;

  constructor() {
    this.employeeApi = new EmployeeApiRepository();
    this.employeeFactory = new EmployeeFactory();
  }

  getAll = async (companyId: number): Promise<Employee[]> => {
    const { data } = await this.employeeApi.getAll(companyId);
    return this.employeeFactory.createList<Employee>(Employee, data.data);
  };

  getFullEmployeeList = async (companyId: number) => {
    const { data } = await this.employeeApi.getFullEmployeeList(companyId);
    return this.employeeFactory.createList<Employee>(Employee, data.data);
  };

  getEmployeeInfo = async (companyId: number, employeeId: number) => {
    const { data } = await this.employeeApi.getEmployeeInfo(companyId, employeeId);
    return this.employeeFactory.create<EmployeeInfo>(EmployeeInfo, data.data);
  };

  addEmployee = async (companyId: number, values: IEmployeeValues) => {
    const { data } = await this.employeeApi.addEmployee(companyId, this.getEmployeeDto(values));
    return this.employeeFactory.create<EmployeeInfo>(EmployeeInfo, data.data);
  };

  updateEmployee = async (companyId: number, employeeId: number, values: any) => {
    const { data } = await this.employeeApi.updateEmployee(companyId, employeeId, this.getEmployeeDto(values));
    return this.employeeFactory.create<EmployeeInfo>(EmployeeInfo, data.data);
  };

  deleteEmployee = async (companyId: number, employeeId: number) => {
    return this.employeeApi.deleteEmployee(companyId, employeeId);
  };

  private getEmployeeDto = (values: IEmployeeValues) => {
    const isAdmin = values.roles?.role === roles.administrator.role;
    const employeeUserPreDto = {
      phone: addRussianCountryPhoneCode(values.phone),
    };
    const employeePreDto = {
      employeeOffers: isAdmin ? null : values.offers.map(item => item.id),
      roles: [values.roles?.role],
      specialization: isAdmin ? null : values.specialization,
    };

    const employeUserDto = EmployeeUserDto.populate({ ...values, ...employeeUserPreDto }) as EmployeeUserDto;
    const employeeDto = EmployeeDto.populate({ ...values, user: employeUserDto, ...employeePreDto }) as EmployeeDto;

    return serialize(employeeDto, { allowEmptyArrays: true, nullsAsUndefineds: true });
  };
}
