import AbstractModel from '../../../base/AbstractModel';
import { IRecord, ISlot } from '../types/RecordTypes';

export class AdminSchedule extends AbstractModel {
  name: string | null = null;
  slots: ISlot[] | null = [];
  records: IRecord[] | null = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
