import { OffersGuide } from './models/OffersGuide';
import AbstractFactory from '../../base/AbstractFactory';

export class OfferFactory extends AbstractFactory {
  createOfferWithCategoriesList<T>(Model: any, data: any): T[] {
    return data.map((json: any) => {
      const offers: OffersGuide[] = this.createList<OffersGuide>(OffersGuide, json?.offers);

      return new Model({ ...json, offers });
    });
  }
}
