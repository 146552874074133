import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Paper, Theme, makeStyles } from '@material-ui/core';

import RecordModal from './modals/RecordModal';
import { useAllMQ } from '../../../../hooks/useAllMQ';
import Loader from '../../../../components/UI/Loader';
import TextButton from '../../../../components/UI/TextButton';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import { IRecord, ISlot } from '../../../../modules/records/types/RecordTypes';
import { getButtonDynamicStyles } from '../../../../utils/getButtonDynamicStyles';

interface ITableScrollPos {
  scrollLeft: number;
  scrollTop: number;
}

interface ISlotTableProps {}

const SlotTable: React.FC<ISlotTableProps> = observer(() => {
  const { recordsStore, masterMainStore } = useRootStore();
  const { modalState, setModalState } = masterMainStore;
  const [tableScrollPos, setTableScrollPos] = useState<ITableScrollPos>({
    scrollLeft: 0,
    scrollTop: 0,
  });

  const classes = useStyles();
  const { isMD } = useAllMQ();

  // Effects
  useEffect(() => {
    recordsStore.getWorkTime(masterMainStore.formattedDate);

    return () => {
      recordsStore.clearWorkTime();
    };
  }, [masterMainStore.formattedDate, recordsStore]);

  useEffect(() => {
    recordsStore.getMasterSchedule();

    return () => {
      recordsStore.clearMasterSchedule();
    };
  }, [masterMainStore.formattedDate, recordsStore]);

  //Handlers
  const handleOpenRecordModal = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e?.preventDefault();

    setModalState({ currentId: id, modal: true });
  };

  const handleCloseRecordModal = () => {
    setModalState({ currentId: null, modal: false });
  };

  // Renders
  const renderRecordButton = (record: IRecord) => {
    const { statusTopPos, slotHeightIndent, statusHeightPercent } = getButtonDynamicStyles(
      record.startTime,
      record.endTime,
    );

    return (
      <TextButton
        key={record.id}
        style={{
          top: `${statusTopPos}%`,
          minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
        }}
        className={clsx(classes.statusBtn, 'full')}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOpenRecordModal(e, record.id)}
      >
        {record.startTime} Запись
      </TextButton>
    );
  };

  const renderSlotButton = (record: ISlot) => {
    const { statusTopPos, slotHeightIndent, statusHeightPercent } = getButtonDynamicStyles(
      record.startTime,
      record.endTime,
    );

    switch (record.status) {
      case 'free':
        return (
          <div
            key={record.id}
            style={{
              top: `${statusTopPos}%`,
              minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
            }}
            className={clsx(classes.statusBtn, { [record.status]: true })}
          >
            Свободно
          </div>
        );
      case 'break':
        return (
          <div
            key={record.id}
            style={{
              top: `${statusTopPos}%`,
              minHeight: `calc(${statusHeightPercent}% + ${slotHeightIndent}px - 2px)`,
            }}
            className={clsx(classes.statusBtn, { [record.status]: true })}
          >
            {record.startTime} Перерыв
          </div>
        );
    }
  };

  const renderCell = (records: IRecord[], slots: ISlot[]) => {
    return recordsStore.workTime?.workTime.map((item, index) => {
      const filteredRecords: IRecord[] = records.filter(
        record => record.startTime.split(':')[0] === item.split(':')[0],
      );
      const filteredSlots: ISlot[] = slots.filter(slot => slot.startTime.split(':')[0] === item.split(':')[0]);

      return (
        <div key={index} className={clsx(classes.tableTd, classes.slotTd)}>
          {filteredRecords.length !== 0 && filteredRecords.map(record => renderRecordButton(record))}
          {filteredSlots.length !== 0 && filteredSlots.map(slot => renderSlotButton(slot))}
        </div>
      );
    });
  };

  return (
    <Paper className={classes.table}>
      {recordsStore.loading && recordsStore.workTimeLoading && <Loader isAbsolute color="secondary" />}
      <Scrollbars
        autoHide
        autoHeight
        autoHeightMin={isMD ? 'calc(100vh - 134px)' : 'calc(100vh - 152px)'}
        onUpdate={values => {
          if (values.scrollLeft !== tableScrollPos.scrollLeft || values.scrollTop !== tableScrollPos.scrollTop) {
            setTableScrollPos({
              ...tableScrollPos,
              scrollLeft: values.scrollLeft,
              scrollTop: values.scrollTop,
            });
          }
        }}
      >
        <div className={classes.tableRow} key={tableScrollPos.scrollLeft}>
          <div
            className={clsx(classes.col, classes.timeCol)}
            style={{ transform: `translateX(${tableScrollPos.scrollLeft}px)` }}
          >
            <div
              className={clsx(classes.tableTd, classes.timeTh)}
              style={{ transform: `translateY(${tableScrollPos.scrollTop}px)` }}
            />
            {recordsStore.workTime?.workTime.map(item => (
              <div key={item} className={clsx(classes.tableTd, classes.timeTd)}>
                {item}
              </div>
            ))}
          </div>
          {recordsStore.masterSchedule.map(({ date, records, slots }, idx) => {
            const formattedDate = date?.split(',');

            return (
              <div key={idx} className={clsx(classes.col, { [classes.firstCol]: idx === 0 })}>
                <div
                  className={clsx(classes.tableTd, classes.slotTh)}
                  style={{ transform: `translateY(${tableScrollPos.scrollTop}px)` }}
                >
                  {formattedDate?.map((item, index) =>
                    index === 0 ? <div key={index}>{item},</div> : <div key={index}>{item?.trim()}</div>,
                  )}
                </div>
                {renderCell(records as IRecord[], slots as ISlot[])}
              </div>
            );
          })}
        </div>
      </Scrollbars>

      <RecordModal open={modalState.modal} onClose={handleCloseRecordModal} />
    </Paper>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    boxShadow: 'none',
    width: 'calc(100vw - 312px)',
    height: 'calc(100vh - 152px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 142px)',
      width: '100vw',
    },
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  col: {
    '&:not(:last-child)': {
      marginRight: '-1px',
    },
    '&:not(:first-child)': {
      position: 'relative',
      paddingTop: 56,
    },
  },
  firstCol: {
    marginLeft: 77,
  },
  timeCol: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    paddingTop: 56,
  },
  tableTd: {
    ...theme.typography.body2,
    color: theme.palette.black.high,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    border: `1px solid ${theme.palette.extra.stroke}`,
    background: theme.palette.extra.white,
    '&:not(:last-child)': {
      marginBottom: '-1px',
    },
  },
  timeTd: {
    width: 78,
    height: 68,
    minWidth: 78,
    minHeight: 68,
  },
  timeTh: {
    width: 78,
    height: 56,
    minWidth: 78,
    minHeight: 56,
    marginBottom: '0 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 4,
  },
  slotTh: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 128,
    height: 56,
    minWidth: 128,
    minHeight: 56,
    marginBottom: '0 !important',
    zIndex: 2,
  },
  slotTd: {
    width: 128,
    height: 68,
    minWidth: 128,
    minHeight: 68,
    padding: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  statusBtn: {
    position: 'absolute',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 2px)',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '0',
    borderRadius: 8,
    transform: 'translateX(-50%)',
    marginTop: 1,
    zIndex: 1,
    '&.full': {
      color: theme.palette.extra.white,
      background: theme.palette.primary.main,
    },
    '&.break': {
      color: theme.palette.black.medium,
      background: theme.palette.qsecondary[50],
    },
    '&.free': {
      color: theme.palette.black.high,
      borderRadius: 8,
      border: `1px solid ${theme.palette.black.medium}`,
      background: '#fff',
    },
  },
  notification: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 2,
    top: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: 15,
    height: 15,
    background: theme.palette.extra.white,
    borderRadius: '50%',
  },
  editIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    padding: 2,
    zIndex: 3,
    color: theme.palette.black.medium,
  },
  editIconLabel: {
    height: '100%',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

export default SlotTable;
