import AbstractDto from '../../../../base/AbstractDto';
import { IDateString } from '../../../role-admin/slots/types/SlotTypes';

export default class CreateReportdDto extends AbstractDto {
  period: IDateString | null = null;
  companyEmployeesIds: number[] = [];
  companyOffersIds: number[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
