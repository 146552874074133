import AbstractModel from '../../../../base/AbstractModel';
import { CompanyAddress } from './CompanyAddress';
import { OfferCategory } from './OfferCategory';

export class CompanyInfo extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  offerCategory: OfferCategory | null = null;
  address: CompanyAddress | null = null;
  description: string | null = null;
  contactPerson: string | null = null;
  contactPersonPhone: string | null = null;
  contactPersonEmail: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  timezone: string | null = null;
  status: string | null = null;
  fee: number | null = null;
  manager: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
