import GalleryApiRepository from './GalleryApiRepository';
import { GalleryFactory } from './GalleryFactory';
import { PhotoModel } from './models/Gallery';

export default class GalleryService {
  galleryApi: GalleryApiRepository;
  galleryFactory: GalleryFactory;

  constructor() {
    this.galleryApi = new GalleryApiRepository();
    this.galleryFactory = new GalleryFactory();
  }

  getList = async (companyId: number): Promise<PhotoModel[]> => {
    const { data } = await this.galleryApi.getList(companyId);
    // @ts-ignore
    return this.galleryFactory.createList<PhotoModel>(PhotoModel, data.data.items);
  };

  setMainPhoto = async (companyId: number, photoUuid: string | null) => {
    if (!photoUuid) {
      return;
    }

    return this.galleryApi.setMainPhoto(companyId, { fileUuid: photoUuid });
  };

  addPhotos = async (companyId: number, uuId: string): Promise<any> => {
    return this.galleryApi.addPhotos(companyId, uuId);
  };

  deletePhoto = async (companyId: number, uuId: string): Promise<any> => {
    return this.galleryApi.deletePhoto(companyId, uuId);
  };
}
