import { useState } from 'react';
import clsx from 'clsx';
import { isValid } from 'date-fns';
import { FormLabel, makeStyles, Theme } from '@material-ui/core';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IQTimePicker extends KeyboardTimePickerProps {
  label?: string;
  size?: 'small' | 'medium';
}

const QTimePicker: React.FC<IQTimePicker> = ({ label, size = 'small', readOnly, ...rest }) => {
  const classes = useStyles();

  const [helperTextValue, setHelperTextValue] = useState<React.ReactNode>();
  const [error, setError] = useState(false);

  const handleError = (error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate) => {
    const helperTextValue = isValid(value) ? rest.helperText : error;
    const errorValue = isValid(value) ? !!rest.error : !!error;

    setHelperTextValue(helperTextValue);
    setError(errorValue);
  };

  // Renders
  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <KeyboardTimePicker
        ampm={false}
        autoOk
        format="HH:mm"
        color="primary"
        minutesStep={5}
        variant="dialog"
        disabled={readOnly}
        inputVariant="outlined"
        views={['hours', 'minutes']}
        keyboardIcon={<CalendarTodayRoundedIcon />}
        invalidDateMessage="Ошибочный формат"
        cancelLabel="Отменить"
        okLabel="Выбрать"
        onError={handleError}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: size === 'small' ? classes.input : undefined,
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
          },
        }}
        className={clsx(classes.textField, { [classes.readOnlyTextField]: readOnly })}
        {...rest}
        helperText={error ? helperTextValue : undefined}
        error={error}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: '11.7px 16px',
  },
  inputRoot: {
    '& .MuiIconButton-root': {
      padding: 4,
      width: 28,
      height: 28,
      '& .MuiIconButton-label': {
        height: '100%',
      },
    },
    paddingRight: 4,
    '&.Mui-focused': {
      '& $notchedOutline': {
        border: `0.5px solid ${theme.palette.extra.stroke}`,
      },
    },
  },
  notchedOutline: {},
  focused: {},
  textField: {
    position: 'relative',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -5,
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, 100%)',
    },
  },
  readOnlyTextField: {
    '& .MuiOutlinedInput-root': {
      boxShadow: 'none',
      color: theme.palette.black.high,
      background: theme.palette.extra.fill,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
    },
  },
}));

export default QTimePicker;
