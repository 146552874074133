import { AuthStore } from '../modules/auth/AuthStore';
import { ChatStore } from '../modules/chat/ChatStore';
import { FileStore } from '../modules/file/FileStore';
import { HeaderStore } from '../modules/header/HeaderStore';
import { NotificationsStore } from '../modules/notfications/NotificationsStore';
import { OfferStore } from '../modules/offer/OfferStore';
import { PushStore } from '../modules/push/PushStore';
import { RecordsStore } from '../modules/records/RecordsStore';
import { ClientsStore } from '../modules/role-admin/clients/ClientsStore';
import { CompanyStore } from '../modules/role-admin/company/CompanyStore';
import { EmployeeStore } from '../modules/role-admin/employee/EmployeeStore';
import { GalleryStore } from '../modules/role-admin/gallery/GalleryStore';
import { AdminMainStore } from '../modules/role-admin/main/MainStore';
import { ScheduleStore } from '../modules/role-admin/schedule/ScheduleStore';
import { SlotsStore } from '../modules/role-admin/slots/SlotsStore';
import { MasterMainStore } from '../modules/role-master/main/MainStore';
import { UserStore } from '../modules/user/UserStore';
import { ReportStore } from '../modules/user/modules/ReportStore';
import React from 'react';

class RootStore {
  authStore: AuthStore;
  userStore: UserStore;
  chatStore: ChatStore;
  fileStore: FileStore;
  galleryStore: GalleryStore;
  pushStore: PushStore;
  slotsStore: SlotsStore;
  offerStore: OfferStore;
  reportStore: ReportStore;
  headerStore: HeaderStore;
  recordsStore: RecordsStore;
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
  adminMainStore: AdminMainStore;
  masterMainStore: MasterMainStore;
  notificationsStore: NotificationsStore;
  clientsStore: ClientsStore;
  companyStore: CompanyStore;

  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.chatStore = new ChatStore();
    this.fileStore = new FileStore();
    this.pushStore = new PushStore();
    this.slotsStore = new SlotsStore();
    this.offerStore = new OfferStore();
    this.reportStore = new ReportStore();
    this.headerStore = new HeaderStore();
    this.recordsStore = new RecordsStore();
    this.scheduleStore = new ScheduleStore();
    this.employeeStore = new EmployeeStore();
    this.adminMainStore = new AdminMainStore();
    this.masterMainStore = new MasterMainStore();
    this.notificationsStore = new NotificationsStore();
    this.galleryStore = new GalleryStore();
    this.clientsStore = new ClientsStore();
    this.companyStore = new CompanyStore();
  }
}

export const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
