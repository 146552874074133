import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';

export default class GalleryApiRepository extends AbstractApiRepository {
  getList = (companyId: number) => {
    return this.get({ url: `/company/${companyId}/gallery/list` });
  };

  setMainPhoto = (companyId: number, data: { fileUuid: string }) => {
    return this.post({ url: `/company/${companyId}/gallery/set-main-photo`, data });
  };

  addPhotos = (companyId: number, uuid: string) => {
    return this.post({ url: `/company/${companyId}/gallery/photos`, data: { uuids: [uuid] } });
  };

  deletePhoto = (companyId: number, uuid: string) => {
    return this.delete({ url: `/company/${companyId}/gallery/photos/${uuid}` });
  };
}
