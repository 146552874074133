import ScheduleFactory from './ScheduleFactory';
import ScheduleApiRepository from './ScheduleApiRepository';
import { Schedule } from './models/Schedule';
import { DayCode, ISerializedSchedule } from './types/ScheduleTypes';

export default class ScheduleService {
  scheduleApi: ScheduleApiRepository;
  scheduleFactory: ScheduleFactory;

  constructor() {
    this.scheduleApi = new ScheduleApiRepository();
    this.scheduleFactory = new ScheduleFactory();
  }

  getScheduleList = async (companyId: number): Promise<Schedule[]> => {
    const { data } = await this.scheduleApi.getScheduleList(companyId);
    return this.scheduleFactory.createList<Schedule>(Schedule, data.data);
  };

  setSchedule = async (companyId: number, values: Schedule[]): Promise<Schedule[]> => {
    const schedule: ISerializedSchedule[] = values.map(item => ({
      day: item.dayCode as DayCode,
      time_start: item.timeStart,
      time_end: item.timeEnd,
    }));

    const { data } = await this.scheduleApi.setSchedule(companyId, { schedule });
    return this.scheduleFactory.createList<Schedule>(Schedule, data.data);
  };
}
