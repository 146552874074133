import { observer } from 'mobx-react-lite';
import ImageViewer from 'react-simple-image-viewer';

import { useRootStore } from '../../base/hooks/useRootStore';

interface IChatImageViewerProps {}

const ChatImageViewer: React.FC<IChatImageViewerProps> = observer(() => {
  const { chatStore } = useRootStore();

  const handleCloseImageViewer = () => {
    chatStore.setCurrentImageIndex(0);
    chatStore.setImageViewerOpen(false);
  };

  if (chatStore.isImageViewerOpen) {
    return (
      <ImageViewer
        src={chatStore.messagesImagesArray}
        currentIndex={chatStore.currentImageIndex}
        onClose={handleCloseImageViewer}
        backgroundStyle={{
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      />
    );
  }

  return <></>;
});

export default ChatImageViewer;
