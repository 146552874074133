import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography, IconButton, DialogProps, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import {
  settingIcon,
  lockCircleIcon,
  phoneCircleIcon,
  envelopeCircleIcon,
  userCardCircleIcon,
  cameraPlusCircleIcon,
  clipboardNotesCircleIcon,
  plusIcon,
} from '../../../../components/UI/Icons/SvgIcons';
import Loader from '../../../../components/UI/Loader';
import QModal from '../../../../components/UI/QModal';
import QTextField from '../../../../components/UI/QTextField';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import QAutocomplete from '../../../../components/UI/QAutocomplete';
import { useCustomTypography } from '../../../../styles/customTypography';
import { EmailMaskInput } from '../../../../components/UI/TextMaskedInputs';
import ImageUploaderButton from '../../../../components/UI/ImageUploaderButton';
import { initialValues } from '../../../../modules/role-admin/employee/EmployeeStore';
import { RussianPhoneMaskInput } from '../../../../components/UI/RussianPhoneMaskInput';
import roles from '../../../../base/routes/roles';

interface IEmployeeModalProps extends DialogProps {}

const EmployeeModal: React.FC<IEmployeeModalProps> = observer(props => {
  const { employeeStore, offerStore } = useRootStore();
  const { values, setValues } = employeeStore;

  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  const roleName = useMemo(() => {
    return values.roles?.label ?? '';
  }, [values.roles]);

  // Effects
  useEffect(() => {
    offerStore.getCompanyOffersList();

    return () => {
      offerStore.clearCompanyOffersList();
    };
  }, [offerStore]);

  useEffect(() => {
    const idsObject: { [key: number]: boolean } = {};
    employeeStore.employeeInfo?.employeeOffers.forEach(id => {
      idsObject[id] = true;
    });

    const offers = offerStore.mappedCompanyOffersList.filter(item => idsObject[item?.id as number]);
    if (employeeStore.employeeInfo) {
      const { role, user, specialization } = employeeStore.employeeInfo;

      if (user) {
        const phone = user.phone ? user.phone.slice(1, user.phone.length) : '';
        setValues({
          ...values,
          specialization,
          roles: role ? roles[role] : null,
          name: user.name,
          email: user.email,
          photo: user.photo,
          phone,
          offers,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeStore.employeeInfo, offerStore.mappedCompanyOffersList]);

  useEffect(() => {
    return () => {
      employeeStore.clearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }

    employeeStore.setEmployeeModal(false);
    employeeStore.setCurrentEmployeeId(null);
    employeeStore.setAction(null);
    employeeStore.setErrors({});
    setValues(initialValues);
  };

  const handleChangeRole = (e: React.ChangeEvent<{}>, value: any) => {
    setValues({
      ...values,
      roles: value,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      setValues({
        ...values,
        uploadPhoto: files[0],
      });
    }
  };

  const handleToggleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChangeAutocomplete = (e: React.ChangeEvent<{}>, value: any) => {
    setValues({ ...values, offers: value });
  };

  const handleSelectAll = () => {
    setValues({ ...values, offers: offerStore.mappedCompanyOffersList });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (employeeStore.action) {
      case 'add':
        employeeStore.addEmployee();
        break;
      case 'edit':
        employeeStore.updateEmployee();
        break;
    }
  };

  // console.log(Object.values(roles));

  // Renders
  return (
    <QModal onClose={handleClose} {...props}>
      {employeeStore.employeeModalLoading && <Loader isAbsolute color="secondary" />}
      <form onSubmit={handleSubmit}>
        <Box mb={3} maxWidth="90%">
          <Typography variant="h3">
            {employeeStore.action === 'add' && 'Добавить нового сотрудника'}
            {employeeStore.action === 'edit' && 'Редактировать сотрудника'}
          </Typography>
        </Box>

        {employeeStore.action === 'edit' && (
          <Box mb={3}>
            <QTextField label="Должность" value={roleName} InputProps={{ readOnly: true }} />
          </Box>
        )}
        {employeeStore.action === 'add' && (
          <Box mb={3}>
            <QAutocomplete
              label="Должность"
              value={values.roles}
              onChange={handleChangeRole}
              disablePortal
              disableClearable
              options={Object.values(roles)}
              getOptionLabel={option => option?.label}
              getOptionSelected={(option, value) => option?.role === value?.role}
              classes={{ tag: commonClasses.tag }}
              noOptionsText="Нет подходящих вариантов"
              renderInput={params => {
                return (
                  <QTextField
                    placeholder="Выберите должность"
                    name="offer"
                    btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                    {...params}
                  />
                );
              }}
            />
          </Box>
        )}
        <Box mb={3}>
          <QTextField
            value={values.name}
            label="Фамилия и Имя"
            name="name"
            placeholder="Введите Фамилию и Имя сотрудника"
            btnProps={{ startIcon: userCardCircleIcon }}
            onChange={handleChange}
            error={employeeStore.errorMessages?.name?.length}
            helperText={employeeStore.errorMessages?.name?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            value={values.phone}
            label="Телефон"
            name="phone"
            placeholder="Введите телефон сотрудника"
            btnProps={{ startIcon: phoneCircleIcon }}
            InputProps={{ inputComponent: RussianPhoneMaskInput }}
            onChange={handleChange}
            error={employeeStore.errorMessages?.phone?.length}
            helperText={employeeStore.errorMessages?.phone?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            value={values.email}
            label="E-mail"
            name="email"
            placeholder="Введите e-mail сотрудника"
            btnProps={{ startIcon: envelopeCircleIcon }}
            InputProps={{ inputComponent: EmailMaskInput }}
            onChange={handleChange}
            error={employeeStore.errorMessages?.email?.length}
            helperText={employeeStore.errorMessages?.email?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        <Box mb={3}>
          <QTextField
            value={values.password}
            type={values.showPassword ? 'text' : 'password'}
            label="Пароль"
            name="password"
            placeholder="Введите пароль"
            btnProps={{ startIcon: lockCircleIcon }}
            onChange={handleChange}
            error={employeeStore.errorMessages?.password?.length}
            helperText={employeeStore.errorMessages?.password?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
            inputProps={{
              form: 'off',
            }}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleShowPassword}
                      className={commonClasses.blackLow}
                      title={values.showPassword ? 'Скрыть' : 'Показать'}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Box>
        <Box mb={3}>
          <ImageUploaderButton
            label="Фотография"
            fileName={values.uploadPhoto ? values.uploadPhoto.name : values.photo?.fileName}
            startIcon={cameraPlusCircleIcon}
            endIcon={plusIcon}
            onHandleChange={handleChangeFile}
            error={employeeStore.errorMessages?.uploadPhoto?.length}
            helperText={employeeStore.errorMessages?.uploadPhoto?.map((item: string) => (
              <Box mb={0.5}>{item}</Box>
            ))}
          />
        </Box>
        {values.roles?.role === 'master' && (
          <>
            <Box mb={3}>
              <QTextField
                value={values.specialization}
                label="Специализация"
                name="specialization"
                placeholder="Введите специализацию"
                btnProps={{ startIcon: clipboardNotesCircleIcon }}
                onChange={handleChange}
                error={employeeStore.errorMessages?.specialization?.length}
                helperText={employeeStore.errorMessages?.specialization?.map((item: string) => (
                  <Box mb={0.5}>{item}</Box>
                ))}
              />
            </Box>
            <Box position="relative" mb={3}>
              <QAutocomplete
                label="Вид услуги"
                value={values.offers}
                multiple
                limitTags={1}
                disablePortal
                selectAllButton
                paperSpacing
                onChange={handleChangeAutocomplete}
                onHandleSelectAll={handleSelectAll}
                options={offerStore.mappedCompanyOffersList}
                groupBy={(option) => option?.offerTitle }
                getOptionLabel={option => `${option?.title && option?.title}, ${option?.price && option.price} ₽`}
                getOptionSelected={(option, value) => option?.id === value?.id}
                noOptionsText="Нет подходящих вариантов"
                classes={{ tag: commonClasses.tag }}
                renderInput={params => {
                  return (
                    <QTextField
                      placeholder="Выберите вид услуги"
                      name="offer"
                      btnProps={{ startIcon: settingIcon, color: 'secondary' }}
                      error={employeeStore.errorMessages?.company_offer_id?.length}
                      helperText={employeeStore.errorMessages?.company_offer_id?.map((item: string) => (
                        <Box mb={0.5}>{item}</Box>
                      ))}
                      {...params}
                    />
                  );
                }}
                renderOption={option => (
                  <Box>
                    <Box mb={0.5}>
                      <Typography className={clsx(typography.text2, commonClasses.blackHigh)}>
                        {option?.title}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={commonClasses.blackMedium}>
                      <Box mr={2}>
                        <Typography variant="caption">{option?.price} ₽</Typography>
                      </Box>
                      <div>
                        <Typography variant="caption">{option?.duration}</Typography>
                      </div>
                    </Box>
                  </Box>
                )}
              />
            </Box>
          </>
        )}
        <Button fullWidth variant="contained" color="secondary" type="submit">
          {employeeStore.action === 'add' && 'Добавить'}
          {employeeStore.action === 'edit' && 'Сохранить'}
        </Button>
      </form>
    </QModal>
  );
});

export default EmployeeModal;
