export interface IUpdatePassword {
  code: string;
  token: string;
  password: string;
  passwordConfirm: string;
}

export enum UnreadNotificationsCountSetType {
  CHANGE,
  INCREMENT,
}
