import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

import { authRotes } from '../routes';
import LoginCard from './components/LoginCard';
import QTextField from '../../components/UI/QTextField';
import TextButton from '../../components/UI/TextButton';
import { ILinkProps } from '../../base/types/BaseTypes';
import LoaderButton from '../../components/UI/LoaderButton';
import { useCommonStyles } from '../../styles/commonStyles';
import { useRootStore } from '../../base/hooks/useRootStore';
import { CodeFormatInput } from '../../components/UI/NumberInputs';

interface IConfirmCodeScreenProps extends Partial<ILinkProps> {}

const ConfirmCodeScreen: React.FC<IConfirmCodeScreenProps> = observer(props => {
  const { to, text } = props;
  const history = useHistory();
  const { authStore } = useRootStore();
  const commonClasses = useCommonStyles();

  const [values, setValues] = useState({
    code: '',
  });

  // Effects
  useEffect(() => {
    if (!authStore.smsToken.length && !authStore.loading) {
      history.push(to ? to : authRotes.LoginScreen.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.smsToken]);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    authStore.setSMSCode(values.code);
    authStore.validateSMSCode();
  };

  const handleRepeatSendSMS = () => {
    authStore.sendSMS('change_password');
  };

  const handleBack = () => {
    history.push(authRotes.ResetPasswordScreen.path);
  };

  // Renders
  return (
    <LoginCard
      link={{ to: to ? to : authRotes.LoginScreen.path, text: text ? text : 'Вернуться к авторизации' }}
      title="Введите код"
      subTitle="Мы отправили его на номер"
      phone={authStore.phone}
    >
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <QTextField
            value={values.code}
            name="code"
            label="Код для восстановления"
            onChange={handleChange}
            InputProps={{ inputComponent: CodeFormatInput }}
          />
        </Box>
        <Box mb={4}>
          {authStore.resendCodeTime !== 0 ? (
            <Typography className={commonClasses.blackHigh} variant="body2">
              Повторить SMS с кодом можно будет
              <br /> через {authStore.resendCodeTime} сек
            </Typography>
          ) : (
            <TextButton onClick={handleRepeatSendSMS}>Отправить код еще раз?</TextButton>
          )}
        </Box>
        <Box mb={2} width="100%">
          <LoaderButton loading={authStore.loading} type="submit" fullWidth variant="contained" color="secondary">
            Продолжить
          </LoaderButton>
        </Box>
        <Button fullWidth color="secondary" variant="outlined" disabled={authStore.loading} onClick={handleBack}>
          Назад
        </Button>
      </form>
    </LoginCard>
  );
});

export default ConfirmCodeScreen;
