import { isIOS } from 'react-device-detect';
import firebase from 'firebase/app';
import 'firebase/messaging'; // for cloud messaging

export const firebaseConfig = {
  apiKey: 'AIzaSyD_-bHeIFN26ZOgHxGsjJk2L40T7Oq7mSI',
  authDomain: 'convenient-729b2.firebaseapp.com',
  projectId: 'convenient-729b2',
  storageBucket: 'convenient-729b2.appspot.com',
  messagingSenderId: '283107117347',
  appId: '1:283107117347:web:ccad7329e7340891e76496',
  measurementId: 'G-GYY68LFSD3',
};
let isSupportedFirebaseMessaging = (firebase.messaging.isSupported());
export const _firebase = isSupportedFirebaseMessaging ? firebase.initializeApp(firebaseConfig) : null;
