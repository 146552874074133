import { makeStyles, Theme } from '@material-ui/core';

export const useCustomTypography = makeStyles((theme: Theme) => ({
  text: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  text2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
  },
  captionMedium: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
  },
  calendar: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '20px',
  },
}));
