import React, { memo } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Theme, makeStyles, Box, Typography, Avatar } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

import TextButton from '../../../../components/UI/TextButton';
import { stringToDate } from '../../../../utils/stringToDate';
import { useCommonStyles } from '../../../../styles/commonStyles';
import { ChatWithCreatorUser } from '../../../../modules/chat/models/ChatWithCreatorUser';

interface IChatSideBarItemProps {
  chatListItem: ChatWithCreatorUser;
  currentId: number;
  currentUserId: number;
  onHandlePush: (id: number) => void;
}

const ChatSideBarItem: React.FC<IChatSideBarItemProps> = memo(
  ({ chatListItem, currentId, currentUserId, onHandlePush }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const time = chatListItem.createdAt && format(stringToDate(chatListItem.createdAt), 'HH:mm');
    const currentUser = chatListItem.lastMessage?.member?.info.id === currentUserId;

    const getLastMessage = () => {
      if (chatListItem.lastMessage?.attachFiles.length) {
        return 'Файл';
      }

      if (chatListItem.lastMessage?.body) {
        return chatListItem.lastMessage?.body;
      }
    };

    return (
      <TextButton
        key={chatListItem.id}
        onClick={() => onHandlePush(chatListItem.id as number)}
        className={clsx(classes.item, { active: currentId === chatListItem.id })}
      >
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Avatar src={chatListItem.creatorUser?.photo?.fullUrl ?? ''} alt="" />
          </Box>
          <Box display="flex" flexDirection="column" flex="1 1 auto">
            <Box display="flex" alignItems="center" mb={1} justifyContent="space-between">
              <Typography className={clsx(commonClasses.blackHigh, classes.itemText)} variant="subtitle1">
                {chatListItem.creatorUser?.name ? chatListItem.creatorUser?.name : chatListItem.creatorUser?.phone}
              </Typography>
              <Typography className={clsx(commonClasses.blackLow, classes.itemText)} variant="body2">
                {time}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" className={clsx(commonClasses.blackMedium, classes.itemText, classes.body)}>
                {currentUser ? `Вы: ${getLastMessage()}` : getLastMessage()}
              </Typography>
              {!!chatListItem.unreadMessagesCount && (
                <Typography variant="body2" className={classes.notification}>
                  {chatListItem.unreadMessagesCount}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </TextButton>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  chatSidebar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexBasis: 444,
      maxWidth: 444,
      minWidth: 444,
      marginRight: -1,
      borderRight: `1px solid ${theme.palette.extra.stroke}`,
    },
  },
  backButton: {
    color: theme.palette.black.medium,
  },
  item: {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    borderRadius: 0,
    cursor: 'pointer',
    padding: theme.spacing(2.5),
    transition: transitions.create('background-color'),
    '&:hover': {
      backgroundColor: theme.palette.qsecondary[300],
      '& $itemText': {
        color: theme.palette.extra.white,
      },
    },
    '&.active': {
      backgroundColor: theme.palette.qsecondary[500],
      '& $itemText': {
        color: theme.palette.extra.white,
      },
    },
  },
  itemText: {
    transition: transitions.create('color'),
  },
  notification: {
    color: theme.palette.extra.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  body: {
    whiteSpace: 'nowrap',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
}));

export default ChatSideBarItem;
