import ClientsService from './ClientsService';
import { Client } from './models/Client';
import { ClientRecord } from './models/ClientRecord';
import { ICurrentClientInfo } from './types/ClientsTypes';
import { makeAutoObservable } from 'mobx';

export class ClientsStore {
  loading = false;
  recordsLoading = false;
  offset = 0;
  haveMoreClients = false;

  clients: Client[] = [];
  clientRecords: ClientRecord[] = [];
  currentClient: ICurrentClientInfo = { phone: '', name: '' };

  searchValue = '';

  private clientsService: ClientsService;

  constructor() {
    makeAutoObservable(this);
    this.clientsService = new ClientsService();
  }

  getClients = (companyId: number | null | undefined) => {
    if (!companyId) {
      return;
    }

    this.setLoading(true);

    this.clientsService
      .getClients(companyId, this.offset, this.searchValue)
      .then(({ clients, pagination }) => {
        if (this.haveMoreClients && this.offset > 0) {
          this.setClients([...this.clients, ...clients]);
        } else {
          this.setClients(clients);
        }

        this.setHaveMoreClients(false);
        const { currentOffset, limit, totalCount } = pagination.meta;

        if (currentOffset && limit && totalCount) {
          const haveMore = totalCount > currentOffset;
          this.setHaveMoreClients(haveMore);
          this.setCurrentOffset(currentOffset);
        }
      })
      .catch(() => {})
      .finally(() => this.setLoading(false));
  };

  getClientRecords = (companyId: number | null | undefined, clientUuid: string | null) => {
    if (!companyId || !clientUuid) {
      return;
    }

    this.setRecordsLoading(true);

    this.clientsService
      .getClientRecords(companyId, clientUuid)
      .then(clientRecords => {
        this.setClientRecords(clientRecords);
      })
      .catch(() => {})
      .finally(() => {
        this.setRecordsLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setClients = (clients: Client[]) => {
    this.clients = clients;
  };

  setClientRecords = (records: ClientRecord[]) => {
    this.clientRecords = records;
  };

  setCurrentOffset = (value: number) => {
    this.offset = value;
  };

  setHaveMoreClients = (status: boolean) => {
    this.haveMoreClients = status;
  };

  setRecordsLoading = (status: boolean) => {
    this.recordsLoading = status;
  };

  changeSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setCurrentClientInfo = (currentClient: ICurrentClientInfo) => {
    this.currentClient = currentClient;
  };
}
