import { memo } from 'react';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import QIcon from '../../../../components/UI/Icons/QIcon';
import trashIcon from '../../../../assets/icons/trash-icon.svg';
import QTimePicker from '../../../../components/UI/QTimePicker';
import { ISlotTime } from '../../../../modules/role-admin/slots/types/SlotTypes';

interface ISlotTimeItemProps {
  slotItemData: ISlotTime;
  onHandleRemove: (e: React.MouseEvent<HTMLButtonElement>, id: string) => void;
  onHandleChangeStartTime: (date: MaterialUiPickersDate, id: string) => void;
  onHandleChangeEndTime: (date: MaterialUiPickersDate, id: string) => void;
}

const SlotTimeItem: React.NamedExoticComponent<ISlotTimeItemProps> = memo(props => {
  const { slotItemData, onHandleRemove, onHandleChangeStartTime, onHandleChangeEndTime } = props;
  const classes = useStyles();

  return (
    <Box className={classes.timeRow} display="flex" alignItems="flex-end">
      <Box width={{ xxs: 'auto', sm: '50%' }} mr={2}>
        <QTimePicker
          label="От"
          value={slotItemData.startTime}
          onChange={date => onHandleChangeStartTime(date, slotItemData.id)}
        />
      </Box>
      <Box width={{ xxs: 'auto', sm: '50%' }} mr={1.75}>
        <QTimePicker
          label="До"
          value={slotItemData.endTime}
          onChange={date => onHandleChangeEndTime(date, slotItemData.id)}
        />
      </Box>
      <Box height={40} display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          className={classes.iconBtn}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => onHandleRemove(e, slotItemData.id)}
        >
          <QIcon src={trashIcon} width={20} height={20} />
        </IconButton>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    width: 24,
    height: 24,
    padding: 0,
    color: theme.palette.secondary.main,
  },
  timeRow: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default SlotTimeItem;
