import { useRootStore } from '../../../base/hooks/useRootStore';
import ContentContainer from '../../../components/ContentContainer';
import QIcon from '../../../components/UI/Icons/QIcon';
import { plusIcon } from '../../../components/UI/Icons/SvgIcons';
import Loader from '../../../components/UI/Loader';
import { useCommonStyles } from '../../../styles/commonStyles';
import DeleteModal from './components/DeleteModal';
import OfferItem from './components/OfferItem';
import OfferModal from './components/OfferModal';
import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

interface IOfferScreenProps {}

const OfferScreen: React.FC<IOfferScreenProps> = observer(() => {
  const { offerStore } = useRootStore();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    offerStore.getCompanyOffersList();

    return () => {
      offerStore.clearCompanyOffersList();
    };
  }, [offerStore]);

  // Handlers
  const handleOpenDeleteModal = (id: number) => {
    offerStore.setCurrentOfferId(id);
    offerStore.setDeleteModal(true);
    offerStore.setAction('delete');
  };

  const handleOpenEditModal = (id: number) => {
    offerStore.setCurrentOfferId(id);
    offerStore.setOfferModal(true);
    offerStore.setAction('edit');
  };

  const handleOpenAddOfferModal = () => {
    offerStore.setOfferModal(true);
    offerStore.setAction('add');
  };

  // Renders
  return (
    <ContentContainer title="Прайс-лист">
      {offerStore.loading && <Loader isAbsolute color="secondary" />}
      <Box mb={{ xxs: 4, md: 8 }}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          startIcon={<QIcon src={plusIcon} width={16} height={16} />}
          onClick={handleOpenAddOfferModal}
        >
          Добавить новую услугу
        </Button>
      </Box>

      <div>
        {Object.keys(offerStore.hashMapCompanyOffersList)?.map(item => (
          <Box mb={2} key={item}>
            <Box mb={2}>
              <Typography className={commonClasses.blackHigh} variant="body1">
                {offerStore.hashMapCompanyOffersList[item][0].offerTitle}
              </Typography>
            </Box>
            {offerStore.hashMapCompanyOffersList[item].map(item => (
              <Box key={item.id} mb={{ xxs: 2, sm: 3 }}>
                <OfferItem data={item} onHandleDelete={handleOpenDeleteModal} onHandleEdit={handleOpenEditModal} />
              </Box>
            ))}
          </Box>
        ))}
      </div>

      <OfferModal open={offerStore.offerModal} />
      <DeleteModal open={offerStore.deleteModal} />
    </ContentContainer>
  );
});

export default OfferScreen;
