import AbstractDto from '../../../../base/AbstractDto';

export default class EmployeeUserDto extends AbstractDto {
  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  password: string | null = null;
  uploadPhoto: File | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
