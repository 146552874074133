import EmployeeUserDto from './EmployeeUserDto';
import AbstractDto from '../../../../base/AbstractDto';
import { RoleKeys } from '../../../../base/routes/types/RouteTypes';

export default class EmployeeDto extends AbstractDto {
  employeeStatus: 'active' | 'blocked' = 'active';
  roles: RoleKeys | null = null;
  specialization: string | null = null;
  employeeOffers: number[] | null = null;
  user: EmployeeUserDto | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
