import roles from '../base/routes/roles';
import { RoutesType } from '../base/routes/types/RouteTypes';
import ChangePasswordScreen from './auth/ChangePasswordScreen';
import ConfirmCodeScreen from './auth/ConfirmCodeScreen';
//Auth screens
import LoginScreen from './auth/LoginScreen';
import ResetPasswordScreen from './auth/ResetPasswordScreen';
import ResetPasswordSetNewScreen from './auth/ResetPasswordSetNewScreen';
import NotFoundScreen from './not-found/NotFoundScreen';
import ChatRoomScreen from './role-admin/chat/ChatRoomScreen';
import ChatScreen from './role-admin/chat/ChatScreen';
import ClientsScreen from './role-admin/clients/ClientsScreen';
import CompanyScreen from './role-admin/company/CompanyScreen';
import CreateSlotScreen from './role-admin/create-slot/CreateSlotScreen';
import EmployeesScreen from './role-admin/employees/EmployeesScreen';
import GalleryScreen from './role-admin/gallery/GalleryScreen';
// role admin
import MainScreen from './role-admin/main/MainScreen';
import OfferScreen from './role-admin/offer/OfferScreen';
import ReportScreen from './role-admin/report/ReportScreen';
import ScheduleScreen from './role-admin/schedule/ScheduleScreen';
// role master
import MasterMainScreen from './role-master/main/MasterMainScreen';
import MasterReportScreen from './role-master/report/MasterReportScreen';
// common screens
import SupportScreen from './support/SupportScreen';

const screens = {
  MainScreen,
  MasterMainScreen,
  CreateSlotScreen,
  ReportScreen,
  MasterReportScreen,
  ChatScreen,
  OfferScreen,
  ScheduleScreen,
  EmployeesScreen,
  ChatRoomScreen,
  SupportScreen,
  NotFoundScreen,
  ConfirmCodeScreen,
  ResetPasswordSetNewScreen,
  ChangePasswordScreen,
  ClientsScreen,
  GalleryScreen,
  CompanyScreen,
};
// Auth screens
const authScreens = { LoginScreen, ResetPasswordScreen, ConfirmCodeScreen, ResetPasswordSetNewScreen };

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  MainScreen: {
    path: '/',
    exact: true,
    title: 'Главная',
    component: screens.MainScreen,
    credentials: [roles.administrator],
  },
  OfferScreen: {
    path: '/offer',
    exact: true,
    title: 'Прайс-лист',
    component: screens.OfferScreen,
    credentials: [roles.administrator],
  },
  ClientsScreen: {
    path: '/clients',
    exact: true,
    title: 'Клиенты',
    component: screens.ClientsScreen,
    credentials: [roles.administrator],
  },
  ScheduleScreen: {
    path: '/schedule',
    exact: true,
    title: 'Расписание',
    component: screens.ScheduleScreen,
    credentials: [roles.administrator],
  },
  EmployeesScreen: {
    path: '/employees',
    exact: true,
    title: 'Сотрудники',
    component: screens.EmployeesScreen,
    credentials: [roles.administrator],
  },
  CreateSlotScreen: {
    path: '/create-slot',
    exact: true,
    title: 'Создать слот',
    component: screens.CreateSlotScreen,
    credentials: [roles.administrator],
  },
  ReportScreen: {
    path: '/report',
    exact: true,
    title: 'Выгрузка отчёта',
    component: screens.ReportScreen,
    credentials: [roles.administrator],
  },
  GalleryScreen: {
    path: '/gallery',
    exact: true,
    title: 'Галерея',
    component: screens.GalleryScreen,
    credentials: [roles.administrator],
  },
  ChatScreen: {
    path: '/chat',
    exact: true,
    title: 'Чат',
    component: screens.ChatScreen,
    credentials: [roles.administrator],
  },
  ChatRoomScreen: {
    path: '/chat/:chatId',
    exact: true,
    title: 'Чат комната',
    component: screens.ChatRoomScreen,
    credentials: [roles.administrator],
  },
  MasterMainScreen: {
    path: '/',
    exact: true,
    title: 'Главная Сотрудник',
    component: screens.MasterMainScreen,
    credentials: [roles.master],
  },
  MasterReportScreen: {
    path: '/report',
    exact: true,
    title: 'Выгрузка отчёта',
    component: screens.MasterReportScreen,
    credentials: [roles.master],
  },
  SupportScreen: {
    path: '/support',
    exact: true,
    title: 'Поддержка',
    component: screens.SupportScreen,
  },
  CompanyScreen: {
    path: '/company',
    title: 'Карточка компании',
    component: screens.CompanyScreen,
  },
  // change password screens
  ChangePasswordScreen: {
    path: '/reset-password',
    exact: true,
    title: 'Смена пароля',
    component: screens.ChangePasswordScreen,
  },
  ConfirmCodeScreen: {
    path: '/confirm-code',
    exact: true,
    title: 'Смена пароля',
    component: screens.ConfirmCodeScreen,
    props: {
      to: '/reset-password',
      text: 'Вернуться к смене пароля',
    },
  },
  ResetPasswordSetNewScreen: {
    path: '/reset-password/new',
    exact: true,
    title: 'Смена пароля',
    component: screens.ResetPasswordSetNewScreen,
    props: {
      to: '/reset-password',
      text: 'Вернуться к смене пароля',
      isChangePassword: true,
    },
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    component: screens.NotFoundScreen,
  },
};

// Auth Routes
type AuthRouteKeys = keyof typeof authScreens;
export const authRotes: RoutesType<AuthRouteKeys> = {
  LoginScreen: {
    path: '/login',
    exact: true,
    title: 'Авторизация',
    component: authScreens.LoginScreen,
  },
  ResetPasswordScreen: {
    path: '/reset-password',
    exact: true,
    title: 'Авторизация',
    component: authScreens.ResetPasswordScreen,
  },
  ConfirmCodeScreen: {
    path: '/confirm-code',
    exact: true,
    title: 'Авторизация',
    component: authScreens.ConfirmCodeScreen,
  },
  ResetPasswordSetNewScreen: {
    path: '/reset-password/new',
    exact: true,
    title: 'Авторизация',
    component: authScreens.ResetPasswordSetNewScreen,
  },
};
