export interface IClientUserInfo {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface ICurrentClientInfo {
  name: string | null;
  phone: string | null;
}

export enum RecordStatuses {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  DELETED = 'deleted',
  IN_PROGRESS = 'in_progress',
}
