import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { Box, FormLabel, makeStyles, Theme } from '@material-ui/core';

import TextButton from './TextButton';

interface IQAutocompleteProps extends AutocompleteProps<any, true | undefined, true, false> {
  label?: string;
  selectAllButton?: boolean;
  disablePopper?: boolean;
  paperSpacing?: boolean;
  onHandleSelectAll?: () => void;
}

const QAutocomplete: React.FC<IQAutocompleteProps> = props => {
  const { label, paperSpacing, classes, className, disablePopper, selectAllButton, onHandleSelectAll, ...rest } = props;
  const classNames = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {label && <FormLabel>{label}</FormLabel>}
        {selectAllButton && (
          <TextButton onClick={onHandleSelectAll} size="small" color="secondary">
            Выбрать все
          </TextButton>
        )}
      </Box>
      <Autocomplete
        classes={{
          popper: clsx(classNames.popper, { disable: disablePopper }),
          paper: clsx(classNames.popperPaper, { [classNames.paperSpacing]: paperSpacing }),
          listbox: classNames.listbox,
          focused: classNames.focused,
          option: classNames.option,
          inputRoot: classNames.input,
          ...classes,
        }}
        className={clsx(classNames.autoComplete, className)}
        {...rest}
        forcePopupIcon
        popupIcon={<ExpandMoreIcon />}
      />
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  autoComplete: {
    '& .MuiInputBase-root': {
      paddingLeft: 50,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  popper: {
    maxWidth: '100%',
    '&.disable': {
      display: 'none',
    },
  },
  popperPaper: {
    padding: 0,
    margin: '-5px 0 0 0',
    borderRadius: 8,
    border: `0.5px solid ${theme.palette.extra.stroke}`,
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0,
  },
  listbox: {
    padding: theme.spacing(2, 0, 3),
    maxHeight: 280,
  },
  option: {
    padding: theme.spacing(0.5, 2),
    '& $focused': {
      background: theme.palette.qsecondary[50],
    },
  },
  focused: {
    '& $input': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.5px solid ${theme.palette.extra.stroke} !important`,
      },
    },
  },
  input: {
    boxShadow: 'none',
    borderBottom: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.extra.stroke} !important`,
    },
  },
  paperSpacing: {
    marginBottom: theme.spacing(5),
  },
}));

export default QAutocomplete;
