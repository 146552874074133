import { makeAutoObservable } from 'mobx';

export class HeaderStore {
  isShowSidebar = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsShowSidebar = () => {
    this.isShowSidebar = !this.isShowSidebar;
  };
}
